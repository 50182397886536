import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import { 
  Box,
  Grid,
  Button
} from '@material-ui/core';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import { NavLink } from 'react-router-dom';
import apiUtil from "../../api/apiUtil.jsx";
import authApi from "../../api/api.jsx";

import axios from 'axios';
import Modal from 'react-awesome-modal';
import HtmlParser from 'react-html-parser';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import Icon  from '@material-ui/core/Icon';
import appleLogo from '../../assets/img/icons/apple-brands.svg'


class Device extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    

    this.state = {
      activeInactiveItem : 0,
      data :  [],
      visible: false,
      visible_active: false,
      visible_bulk: false,
      visible_quota: false,
      data_active :  [],
      data_disable :  [],
      company_id: 0,
      page : 1,
      selectedRow: [],
      total_active : 0,
      bulk_delete_id: '',
      company_id: '',
      total_disable : 0,
      limit : 20,
      total: 0,
      request_device: 0,
      billing: [],
      billing_date: [],      
      isLoadingStatus: false,
      pageSize : 10,      // status: [],
      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,
      price_per_device:null,
      billing_devices: [],
      total_price: 0,
    }

   
  }

  openModal(id) {
    this.setState({
      visible: true,
      delete_id: id
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }

  openModalBulk() {
    this.setState({
      visible_bulk: true,
    });
  }

  closeModalBulk() {
    this.setState({
      visible_bulk: false
    });
  }

  openModalActive() {
    this.setState({
      visible_active: true,
    });
  }

  closeModalActive() {
    this.setState({
      visible_active: false
    });
  }

  openModalQuota() {
    this.setState({
      visible_quota: true,
    });
  }

  closeModalQuota() {
    this.setState({
      visible_quota: false
    });
  }

  componentDidMount() { 
    this.callReadProfileApi();
    
    this.callReadApiDisabled(()=> {
      this.callReadApiActive();
    });
    this.callReadRequestDevice();
  }

  callReadBillingApi(){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "application_subscription_billing";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
   
    let param = {
     start_date: '',
     end_date: '',
    is_paid: '',
    is_current: true, 
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
       { 
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          }
      })
      .catch( error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {
        if(response && response.data.result.length > 0) {
          var json_data = response.data.result[0].billing_items;
          var result = [];
          for (var i in json_data) {
            result[i] = json_data[i];
          }
          result.shift();

          let billingData = this.getBillingData(response.data.result[0])
          let totalPrice = 0
          billingData.forEach( b => {
            totalPrice += parseFloat(b.used_cost)
          })

          // if(response != null){
          
          this.setState({
            billing: result,
            billing_date: response.data.result,
            billing_devices: billingData,
            total_price: totalPrice.toFixed(2),
            isLoading: false,
          });
        }
       
      });
  }

  callReadProfileApi = ( callback = null ) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      

      authApi.read('profile/admins', null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.callReadBillingApi();
            let price_per_device = data.result.application_company.application_company_license.application_company_plan.pricings
            price_per_device = price_per_device.filter(data => data.rule_name == "device");
            this.setState({
              isLoading: false,
              price_per_device: price_per_device[0].price_per_item
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
}
  getBillingData = (data) => {
    let billing_date = new Date(data.billing_date);
    let billing_items = data.billing_items
    let device_usage = []
    billing_items.forEach( item => {
      let single_usage = new Object();
      single_usage.used_cost = 100
      if(item.device_management) {
        var is_add = true;
        if( device_usage.length > 0) {
          device_usage.forEach ( d => {
            if(d.id === item.device_management.device_id) {
              d.used_day += this.getUsedDay(billing_date, item.device_management.number_of_days_used)
              d.used_cost = this.getUsedCost(d.used_day).toFixed(2)
              is_add = false
            }
          });
          if(is_add) {
            single_usage.id = item.device_management.device_id
            single_usage.device_manufacturer = item.device_management.device_manufacturer
            single_usage.used_day = this.getUsedDay(billing_date, item.device_management.number_of_days_used);
            single_usage.used_cost = this.getUsedCost(single_usage.used_day).toFixed(2)
            device_usage.push(single_usage)
          }

        } else {
          single_usage.id = item.device_management.device_id
          single_usage.device_manufacturer = item.device_management.device_manufacturer
          single_usage.used_day = this.getUsedDay(billing_date, item.device_management.number_of_days_used);
          single_usage.used_cost = this.getUsedCost(single_usage.used_day).toFixed(2)
          device_usage.push(single_usage)
        }
        
      }
    })

    return device_usage
    
  }

  daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  } 
  getUsedCost = (used_day) =>{
    let d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    
    let price_per_device = parseFloat(this.state.price_per_device)

    let used_cost = (used_day/(this.daysInMonth(month,year)) * price_per_device)
    return used_cost


  }

  getUsedDay = (bill_date, num_used_day) => {
    let d = new Date()

    // var timeDiff = Math.abs(d.getTime() - last_month_date.getTime());

    let end_date = new Date(bill_date);
    let timeDiff = Math.abs(end_date.getTime() - d.getTime());
    let duration_date = Math.ceil(timeDiff / (1000 * 3600 * 24));


    let used_day = num_used_day;
    if (num_used_day > duration_date) {
      used_day = num_used_day - duration_date;
    }
    return used_day

  }

  callReadApiActive(){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;

    this.setState({
      isLoading: true
    });

    const PATH = 'admin/api/';
    const api = "device_managements";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    const { accessToken } = this.context;
   
    let param = {
      is_banned : false,
      is_removed: false,
      search: "",
      page: this.state.page,
      take: this.state.limit,
      // start_date: startDate,
      // end_date: endDate,
      sorting_array: ['created_at DESC'],
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
       { 
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
          }
      })
      .catch( error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {
        
        if(response != null){
        this.setState({
          data_active: response.data.result,
          total_active: response.data.total,
          isLoading: false,
          total: response.data.total,
          company_id: response.data.result.application_company_id,
        });

        }
      });
  }

  callReadApiDisabled(callback = null){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;

    this.setState({
      isLoading: true
    });

    const PATH = 'admin/api/';
    const api = "device_managements";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    const { accessToken } = this.context;
   
    let param = {
      page: this.state.page,
      take: this.state.limit,
      is_banned : true,
      is_removed: false,
      search: "",
      // start_date: startDate,
      // end_date: endDate,
      sorting_array: ['created_at DESC'],
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
       { 
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
          }
      })
      .catch( error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {
        
        if(response != null){
        this.setState({
          data_disable: response.data.result,
          total_disable: response.data.total,
          isLoading: false,
        }, () => {
          if(callback){
            callback();
          }
        });
        }
      });
  }

  callReadRequestDevice(){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = `application_companies/`;
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    const { accessToken } = this.context;

   
    // let param = {
    // };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
       { 
          // params: param,
          // data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
          }
      })
      .catch( error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {
        
        if(response != null){
        this.setState({
          request_device: response.data.result[0].application_company_license.requested_devices,
          company_id:response.data.result[0].id,
          isLoading: false,
        });
        }
      });
  }

  callDeleteApi(id, unbanned_id) {

    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = `delete/devices`;
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    let param = {
      id: id,
      unbanned_id: unbanned_id,
    };
    axios.delete(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
        params: param,
        data: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
        if(id == -99){
        apiUtil.toast('Successfully Activated !!', 'error');

        } else {
        apiUtil.toast('Successfully Deleted !!', 'error');
        }
        if(this.state.activeInactiveItem == 0) {
          this.callReadApiActive();
        } else {
          this.callReadApiDisabled();   
        }
      });
  }

  bulkActive = () => {
    //  bulk_delete_id 
    if (this.state.selectedRow.length > 0) {
      let data = this.state.selectedRow;
      var delete_ids = '';
      for (var i in data) {
        if (delete_ids != "")
          delete_ids += ",";
        delete_ids += data[i].id;
      }
      this.callDeleteApi(-99, delete_ids);
    } else {
      apiUtil.toast('please select device(s) to activate!!', 'error');
    }
  }

  bulkDelete = () => {
    //  bulk_delete_id 
    if (this.state.selectedRow.length > 0) {
      let data = this.state.selectedRow;
      var delete_ids = '';
      for (var i in data) {
        if (delete_ids != "")
          delete_ids += ",";
        delete_ids += data[i].id;
      }
      this.callDeleteApi(delete_ids, '');
    } else {
      apiUtil.toast('please select device(s) to delete!!', 'error');

    }
  }

  usage = () => {

    // var last_month_date = new Date(this.state.billing_date[0].billing_date);
    // last_month_date.setMonth(last_month_date.getMonth());
    // var d = new Date(),
    // month = '' + (d.getMonth() + 1),
    // day = '' + d.getDate(),
    // year = d.getFullYear();

    // var timeDiff = Math.abs(d.getTime() - last_month_date.getTime());
    // var duration_date = Math.ceil(timeDiff / (1000 * 3600 * 24));

    // var end_date = new Date(this.state.billing[0].billing_date); 

    // console.log("last_month_date", last_month_date);
    // console.log("aa", this.state.data_active[2].last_billed_date);
    // console.log("d", d);
    // console.log("timeDiff", timeDiff);
    // console.log("duration_date", duration_date);

    // var timeDiff2 = Math.abs(end_date.getTime() - d.getTime());
    // var before_day = Math.ceil(timeDiff2 / (1000 * 3600 * 24));
    // var use_day = [];
    // console.log("before_day", before_day);
    // var bill_data = this.state.billing[0].billing_items;
    // // var bill= [];
    // for (var i in bill_data) {
    //     if(bill_data[i].hasOwnProperty("device_management")) {
    //     use_day[i] = bill_data[i].device_management.number_of_days_used;
    //     }
    // }
    // console.log("use_day", use_day);
    return <><Table 
    ref={this.refTable}
    className={'job-table-list' }  
    isLoading={this.state.isLoading}
    grouping={this.state.grouping}
    limit={this.state.limit}
    pageSize= {this.state.pageSize}
    // pageSizeOptions ={this.state.pageSizeOptions}
    page={this.state.page}
    total={this.state.total}
    data={this.state.billing_devices}

    // defaultExpanded={true}
    // selection={true}
    onPageChange={(page,size) => {
      
      this.state.limit = size;
      this.setState({
        page: page,
      }, () => { 
        this.callReadApiActive();
        // this.callReadApi(this.refTableSearch.current.state.value);
      });
    }}
    onSelectionChange={(rows)=>{
      this.setState({selectedRow:rows});
    }}
    // Toolbar={this.customToolbar}
    // Row={this.customRow}
      columns={[
     
        { 
          title: "Device ID", 
          field: "Device ID",
          cellStyle: {
            minWidth: 280,
            maxWidth: 280             
          },
          render:(row, type) => {
            if(type === 'row'){
              return (row.hasOwnProperty("id")) ? HtmlParser(row.id) : '';

            } else {
              return row;
            }
          }
        },
        { 
          title: "Device Type", 
          field: "Device Type",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240            
          },

          render: (row, type) => {
            if(type === 'row'){
              return (row.hasOwnProperty("device_manufacturer")) ? row.device_manufacturer : '';

            } else {
              return row;
            }
          }
        },
        // { 
        //   title: "Last User", 
        //   field: "Last User",
        //   cellStyle: {
        //     minWidth: 180,
        //     maxWidth: 180
        //   },
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       return (row.hasOwnProperty("device_management")) ? HtmlParser(row.device_management.device_manufacturer) : '';

        //      } else {
        //       return row;
        //     }
        //   }
        // },
        {
          title: "Usage Duration",
          field: "Usage Duration",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          render: (row, type) => {
            if (type === 'row') {
              // if (row.hasOwnProperty("device_management")) {
              //   var last_month_date = new Date(this.state.billing_date[0].billing_date);
              //   last_month_date.setMonth(last_month_date.getMonth());

              //   var d = new Date(),
              //   month = '' + (d.getMonth() + 1),
              //   day = '' + d.getDate(),
              //   year = d.getFullYear();

              //   var timeDiff = Math.abs(d.getTime() - last_month_date.getTime());

              //   var end_date = new Date(this.state.billing_date[0].billing_date);
              //   timeDiff = Math.abs(end_date.getTime() - d.getTime());
              //   var duration_date = Math.ceil(timeDiff / (1000 * 3600 * 24));

              //   console.log("duration_date", duration_date);
              //   console.log("last_month_date", last_month_date);
              //   console.log("d", d);
              //   var use_day = row.device_management.number_of_days_used;
              //   if (row.device_management.number_of_days_used > duration_date) {
              //     use_day = row.device_management.number_of_days_used - duration_date;
              //   }
              //   return use_day + ' day(s)';
              return (row.hasOwnProperty("used_day")) ? row.used_day + ' day(s)' : '';
              

            } else {
              return row;
            }
          }
        },
        {
            title: "Usage Cost($)",
            field: "action",
            cellStyle: {
                minWidth: 80,
                maxWidth: 80
            },
           render: (row, type) => {
            if (type === 'row') {
              return (row.hasOwnProperty("used_cost")) ? row.used_cost  : '';

            } else {
              return row;
            }
          }
        },
      ]}
    />
    <Box style={{marginRight:"10em"}}>
    <Grid container justify="flex-end" >
        <Grid item  ><h3>Total :</h3></Grid>
        <Grid item style={{marginTop:'15px', marginLeft:'10px'}} ><b>$ {this.state.total_price}</b></Grid>
    </Grid></Box>
     
    </>

  }

  callUpdateDeviceApi = () => {
    let errorMessage = "";
    let status = 0;
    const {
        REACT_APP_AUTH_API_URL
    } = process.env;
    let id = this.state.company_id;
    const PATH = 'admin/api/';
    const api = `application_companies/${id}`;
  
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    const data = {
      requested_devices: this.state.request_device,
  };
    axios.put(
        // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
        URL,
        data,
        {
            // params: param,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'app-name': 'AUTH',
            }
        })
        .catch(error => {
            try {
                status = error.response.status;
  
                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            } catch (err) {
                errorMessage = 'Service Unavailable!'
            }
        }).then((response) => {
            this.callReadApiActive();
        });
  }

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }
 
  customTableActive = () => {

    return <Table 
    ref={this.refTable}
    className={'job-table-list' }  
    isLoading={this.state.isLoading}
    grouping={this.state.grouping}
    limit={this.state.limit}
    pageSize= {this.state.pageSize}
    // pageSizeOptions ={this.state.pageSizeOptions}
    page={this.state.page}
    total={this.state.total_active}
    data={this.state.data_active}
    // defaultExpanded={true}
    selection={true}
    onPageChange={(page,size) => {
      
      this.state.limit = size;
      this.setState({
        page: page,
      }, () => { 
        this.callReadApiActive();
        // this.callReadApi(this.refTableSearch.current.state.value);
      });
    }}
    onSelectionChange={(rows)=>{
      this.setState({selectedRow:rows});
    }}
    // Toolbar={this.customToolbar}
    // Row={this.customRow}
      columns={[
     
        { 
          title: "Device ID", 
          field: "Device ID",
          cellStyle: {
            minWidth: 280,
            maxWidth: 280             
          },
          render:(row, type) => {
            if(type === 'row'){
              return (row.device_id   != ' ') ? HtmlParser(row.device_id) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Device Type", 
          field: "Device Type",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240            
          },

          render: (row, type) => {
            if(type === 'row'){

                return <> 
                {(row.device_platform   == 'Android') ?  <Icon style={{color:"#3A3B3C", fontSize:"14px"}}>android</Icon> : <img src={appleLogo} alt="apple logo" width="14px" style={{color:"#3A3B3C !important"}}/>}&nbsp;
                {(row.device_manufacturer  != ' ') ? HtmlParser(row.device_manufacturer) : ''}&nbsp;
                {(row.device_model  != ' ') ? HtmlParser(row.device_model) : ''}
                </>
            } else {
              return row;
            }
          }
        },
        { 
          title: "Current User", 
          field: "Current User",
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          render: (row, type) => {
            if(type === 'row'){
              return <>
                {(row.current_worker.first_name   != ' ') ? HtmlParser(row.current_worker.first_name) : ''}&nbsp;
                {(row.current_worker.last_name   != ' ') ? HtmlParser(row.current_worker.last_name) : ''}
                   </>
             } else {
              return row;
            }
          }
        },
        { 
          title: "Last Logged", 
          field: "Last Logged",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          render: (row, type) => {
               if(type === 'row'){
                 if(row.current_worker.last_seen   != ' ') {
                   var date = new Date(row.current_worker.last_seen);
                   return  date.toLocaleDateString()
                 }
            } else {
              return row;
            }
          }
        },
        {
            title: "Action",
            field: "action",
            cellStyle: {
                minWidth: 80,
                maxWidth: 80
            },
          render: (row, type) => {
            if (type === 'row') {
              return <Box>
                <span className="circle_delete_btn"
                  onClick={() => {
                    this.openModal(row.id);
                  }}
                ><Icon className="circle_delete_icon">delete_forever</Icon></span>
              </Box>
            } else {
              return row;
            }
          }
        },
      ]}
    />
  }

  customTableDisable = () => {

    return <Table 
    ref={this.refTable}
    className={'job-table-list' }  
    isLoading={this.state.isLoading}
    grouping={this.state.grouping}
    limit={this.state.limit}
    pageSize= {this.state.pageSize}
    // pageSizeOptions ={this.state.pageSizeOptions}
    page={this.state.page}
    total={this.state.total_disable}
    data={this.state.data_disable}
    // defaultExpanded={true}
    selection={true}
    onPageChange={(page,size) => {
      
      this.state.limit = size;
      this.setState({
        page: page,
      }, () => { 
        this.callReadApiDisabled();
        // this.callReadApi(this.refTableSearch.current.state.value);
      });
    }}
    onSelectionChange={(rows)=>{
      this.setState({selectedRow:rows});
    }}
    // Toolbar={this.customToolbar}
    // Row={this.customRow}
      columns={[
     
        { 
          title: "Device ID", 
          field: "Device ID",
          cellStyle: {
            minWidth: 250,
            maxWidth: 250             
          },
          render:(row, type) => {
            if(type === 'row'){
              return (row.device_id   != ' ') ? HtmlParser(row.device_id) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Device Type", 
          field: "Device Type",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240            
          },

          render: (row, type) => {
            if(type === 'row'){

                return <> 
                {(row.device_platform   == 'Android') ?  <Icon style={{color:"#3A3B3C", fontSize:"14px"}}>android</Icon> : <img src={appleLogo} alt="apple logo" width="14px" style={{color:"#3A3B3C !important"}}/>}&nbsp;
                {(row.device_manufacturer  != ' ') ? HtmlParser(row.device_manufacturer) : ''}&nbsp;
                {(row.device_model  != ' ') ? HtmlParser(row.device_model) : ''}
                </>
            } else {
              return row;
            }
          }
        },
        { 
          title: "Current User", 
          field: "Current User",
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          render: (row, type) => {
            if(type === 'row'){
              return <>
                {(row.current_worker.first_name   != ' ') ? HtmlParser(row.current_worker.first_name) : ''}&nbsp;
                {(row.current_worker.last_name   != ' ') ? HtmlParser(row.current_worker.last_name) : ''}
                   </>
             } else {
              return row;
            }
          }
        },
        { 
          title: "Last Logged", 
          field: "Last Logged",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150            
          },
          render: (row, type) => {
               if(type === 'row'){
                 if(row.current_worker.last_seen   != ' ') {
                   var date = new Date(row.current_worker.last_seen);
                   return  date.toLocaleDateString()
                 }
            } else {
              return row;
            }
          }
        },
        {
            title: "Action",
            field: "action",
            cellStyle: {
                minWidth: 120,
                maxWidth: 120
            },
          render: (row, type) => {
            if (type === 'row') {
              return <Box>
                <span>
                <span className="circle_active_btn"
                onClick={() => {
                  this.callDeleteApi(-99, row.id);
                }}
                ><Icon className="circle_active_icon">power_settings_new</Icon></span>
                <span className="circle_delete_btn"
                  onClick={() => {
                    this.openModal(row.id);
                  }}
                ><Icon className="circle_delete_icon">delete_forever</Icon></span>
                </span>
              </Box>
            } else {
              return row;
            }
            }
        },
      ]}
    />
  }

  render() {
    return <Box className="dashboard-page">
      <Card className={'job-listing-step'}>
      <Box clone pb={{ xs: 2, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            <GroupButton
              color={'secondary'}
              selected={this.state.activeInactiveItem}
              buttons={[ `Active (${this.state.total_active})`, `Disabled (${this.state.total_disable})`, "Usage"]}
              onClick={(selected, btn) => {
                this.setState({
                    page: 1,
                    activeInactiveItem : selected
                },() => {
                  if(selected == 0) {
                    this.callReadApiActive();
                  } else {
                    this.callReadApiDisabled();
                  }                      
                });
              }}
            />
          </Grid>
        </Box>
        {(this.state.activeInactiveItem == 0) ? <>
          <Box>
            <Grid>
              <h3>List of available devices</h3>
              <p>Your worker's devices that are logged in to Evfy Tracker mobile app are listed here. Manage your quota to avoid excessing devices logged in.</p>
              <p>Quota Usage: <strong>({this.state.total_active}/{this.state.request_device})</strong> devices used.
                <span className="change-btn"
                  onClick={() => {
                    this.openModalQuota();
                  }}
                >Change</span>
              </p>
              <div className="button-gp">
                <a className="button-delete" href="javascript:void(0);"
                  onClick={() => {
                    this.openModalBulk();
                    // this.callDeleteApi(this.state.delete_id, '');
                  }}>Bulk Delete</a>
              </div>
            </Grid>
          </Box>
          <Box>
            {this.customTableActive()}
          </Box>
        </> : ''}
        {this.state.activeInactiveItem == 1 ? <>
        <h3>List of disabled devices</h3>
        <p>Devices that exceeded your quota limit are listed here. You wont be charged unless these devices becomes Active.</p>
        <div className="button-gp">
                <a className="button-delete" href="javascript:void(0);"
                  onClick={() => {
                    this.openModalBulk();
                    // this.callDeleteApi(this.state.delete_id, '');
                  }}>Bulk Delete</a>
                <a className="button-activate" href="javascript:void(0);"
                  onClick={() => {
                    this.openModalActive();
                    // this.callDeleteApi(this.state.delete_id, '');
                  }}>Bulk Activate</a>
              </div>
        {this.customTableDisable()}
        </> : ''}

        {this.state.activeInactiveItem == 2 ? <>
        <h3>List of devices usage in this month</h3>
        {this.usage()}
        </> : ''}

      </Card>

      <Modal visible={this.state.visible} width="400" height="150" effect="fadeInDown" onClickAway={() => this.closeModal()}>
        <div className="modal-group">
        <h1 className="modal-header">Delete!!</h1>
          <p className="modal-title">Are you sure, you want to delete this device?</p>
          <div className="modal-button">
            <a className="modal-button-close" href="javascript:void(0);" onClick={() => this.closeModal()}>Close</a>
            <a className="modal-button-delete" href="javascript:void(0);"
              onClick={() => {
                this.callDeleteApi(this.state.delete_id, '');
                this.closeModal();
              }}>Delete</a>
          </div>
        </div>
      </Modal>

      <Modal visible={this.state.visible_bulk} width="400" height="150" effect="fadeInDown" onClickAway={() => this.closeModalBulk()}>
        <div className="modal-group">
        <h1 className="modal-header">Bulk Delete!!</h1>
          <p className="modal-title">Are you sure, you want to delete {this.state.selectedRow.length} device(s)?</p>
          <div className="modal-button">
            <a className="modal-button-close" href="javascript:void(0);" onClick={() => this.closeModalBulk()}>Close</a>
            <a className="modal-button-delete" href="javascript:void(0);"
              onClick={() => {
                this.bulkDelete();
                this.closeModalBulk();
              }}>Delete</a>
          </div>
        </div>
      </Modal>

      <Modal visible={this.state.visible_active} width="400" height="150" effect="fadeInDown" onClickAway={() => this.closeModalActive()}>
        <div className="modal-group-active">
        <h1 className="modal-header-active">Bulk Activate!!</h1>
          <p className="modal-title-active">Activate {this.state.selectedRow.length} device(s)</p>
          <div className="modal-button-active">
            <a className="modal-button-close" href="javascript:void(0);" onClick={() => this.closeModalActive()}>Close</a>
            <a className="modal-button-active" href="javascript:void(0);"
              onClick={() => {
                this.bulkActive();
                this.closeModalActive();
              }}>Activate</a>
          </div>
        </div>
      </Modal>

      <Modal visible={this.state.visible_quota} width="400" height="300" effect="fadeInDown" onClickAway={() => this.closeModalQuota()}>
        <div className="Quota-group">
          <h1 className="Quota-head">Quota Usage</h1>
          <p className="Quota-title">Set your maximum device to be used. Any more devices that attempted to login exceeding your quota will be sent to ban list.</p>
          <input type="number" value={this.state.request_device} className="Quota-input"
          onChange={e => {
            this.setState({
              request_device:e.target.value
          })
          }} />
          <p className="Quota-title">You currently have <strong>{this.state.total_active} devices</strong> in use.</p>
          <div className="Quota-button">
            <a className="Quota-button-close" href="javascript:void(0);" onClick={() => this.closeModalQuota()}>Close</a>
            <a className="Quota-button-create" href="javascript:void(0);"
              onClick={() => {
                this.callUpdateDeviceApi(this.state.request_device);
                this.closeModalQuota();
              }}>Set Quota</a>
          </div>
        </div>
      </Modal>
    </Box>;
  }
}

export default Device;
