import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import { 
  Box,
  Grid,
} from '@material-ui/core';
import apiUtil from "../../api/apiUtil.jsx";
import authApi from "../../api/api.jsx";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import DeviceSubScribe from '../../views/device/deviceSubscriptionStepper';
class ShowAlert extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    

    this.state = {
      data :  [],
      isLoadingStatus: false,
      appcom_data:[],
      openDialog: false,

     }
    }
  componentDidMount() { 
    this.callReadApi();
    this.callReadCompanyApi();
  }
  subscribeService = () => {
      this.setState({
        openDialog: false
      })
  }
 

  callReadApi = ( callback = null) => {
    const { accessToken } = this.context;
    let param = {
        is_paid: false,
    }

    this.setState({
      isLoading: true
    }, () => {
      authApi.read('application_subscription_billing' , param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            
            this.setState({
              isLoading: false,
              data:data.result,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });
  }
  callReadCompanyApi = ( callback = null) => {
    const { accessToken } = this.context;
    let param = {
    }

    this.setState({
      isLoading: true
    }, () => {
      authApi.read('application_companies' , param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            
            this.setState({
              isLoading: false,
              appcom_data:data.result,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });
  }

  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Subscribe Evfy Tracker'}
      hideButtons={true}
      fullWidth={true}
      maxWidth={'xl'}
      onClose={() => {
        this.setState({
          openDialog: false,
        },()=> {
          window.location.replace("/device");
        });
      }}
    >
      <DeviceSubScribe  
      onClose={() => {
          this.setState({
            openDialog: false,
          }); }} />
    </CustomDialog>
  }
  // goToInvoicePage = () => {
  //   this.props.history.push({
  //     pathname: '/invoice',
  //     state: {
  //       pathname: this.props.location.pathname
  //     }
  //   });
  // }

 alertShow = () => {
     let billDate = this.state.data[0] ? this.state.data[0].billing_date : new Date();
     let suspendedDate = new Date(billDate)
     let currnetDate = new Date()
     let appcom_status_id = this.state.appcom_data[0] ? this.state.appcom_data[0].application_company_status_id : null;
     let appcom_is_trial  = this.state.appcom_data[0] ? this.state.appcom_data[0].application_company_license.is_trial : false;
     let date_subscription_end = this.state.appcom_data[0] ? new Date(this.state.appcom_data[0].application_company_license.date_subscription_end) :new Date();
     suspendedDate.setDate(suspendedDate.getDate() + 14);
     if(suspendedDate < currnetDate ) {
      let timeDiff = Math.abs(suspendedDate.getTime() - currnetDate.getTime());
      let total_days =  Math.ceil(timeDiff / (1000 * 3600 * 24));
         return <div style={{textAlign:'center', backgroundColor:'#f8d7da', padding:'10px'}}>
           Your Evfy Tracker services are suspended. 
           <NavLink to={{pathname: '/invoice'}} > Please pay </NavLink> within {total_days} days to avoid account removal.</div>
     } else if(appcom_status_id == null && appcom_is_trial) {
      let timeDiff = Math.abs(date_subscription_end.getTime() - currnetDate.getTime());
      let total_days =  Math.ceil(timeDiff / (1000 * 3600 * 24));
         return <div style={{textAlign:'center', backgroundColor:'#f8d7da', padding:'10px'}}>
           You have {total_days} days left in your trial. Subscribe to evfy.sg by
           <span 
           style={{cursor:"pointer"}}
           onClick={()=>{
             this.setState({
               openDialog:true,
             })
            }} 
           
           ><u> setting your device quota</u></span> now.</div>
     }
     return <div></div>
 }

  render() {
    return <Box >
        {this.alertShow()}
        {this.customDialog()}
    </Box>;
  }
}

export default ShowAlert;


