import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import { 
  Box,
  Grid,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Card from '../../components/Card/Card.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import authApi from "../../api/api.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class Payment extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    

    this.state = {
      data :  [],
      isLoadingStatus: false,
      id: this.props.location.state.id,
      payment_method:''
    }

   
  }
  componentDidMount() { 
    this.callReadApi();
  }

  // callReadApi(){
    
  // }

  callReadApi = ( callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      authApi.read('application_subscription_billing?start_date=&end_date=&is_paid=' , null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let filteredData = data.result.filter(d => d.id === this.state.id)
            this.setState({
              isLoading: false,
              data:filteredData
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });
  }

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }
 
  handleChange = (method) => {
     if(method) {
       this.setState({
        payment_method: method,
       })
     }
  }

  makePayment = (callback = null) => {
    const accessToken  = window.localStorage.getItem('access_token');

    let param = {
      payment_method: this.state.payment_method,
      response_type: '',
    };

    this.setState({
      isLoading: true
    }, () => {
      authApi.create('paypal_payment/make_payment' , param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.props.history.push({
              pathname: '/invoice'
            });
            // window.location.replace(data.result);
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null);
              }
            });
          }, (error, type) => {
            this.props.history.push({
              pathname: '/invoice'
            });
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, []);
              }
            });
          });
      });
    });

}
  render() {
    return <Box className="payment-page">
      
      <Box mt={5}>
      <Grid 
      container   
      direction="column"
      alignItems="center"
      justify="center"
      >
      <Grid item>
        <Card >
          <div style={{paddingBottom:"10px", borderBottom:"2px solid #ada7a71a"}}>
          <Box >
            <Typography variant="h5" style={{marginTop:'3px', marginBottom:'3px'}}>{this.state.data[0] ? this.state.data[0]['invoice_number'] : ''}</Typography>
          
          </Box>
          
          <Typography variant="subtitle1"><span style={{marginRight:'20em',paddingRight:'10px'}}>SUBTOTAL</span> <span >{this.state.data[0] ? '$' + this.state.data[0]['total_price'] : ''}</span></Typography>
          <Typography variant="subtitle1" style={{borderBottom:"1px solid #10182061"}}><span style={{marginRight:'19em'}}>SALE TAX (0%)</span> <span >$00.00</span></Typography>
          <div style={{marginTop:'15px', marginBottom:'10px'}}> <span style={{marginRight:'21em', fontSize:'16px'}}>TOTAL</span> <span >{this.state.data[0] ? '$' + this.state.data[0]['total_price'] : ''}</span></div>
          </div>
          
          <Typography variant="h6" style={{marginTop:'10px'}}>Payment Method</Typography>
          <RadioGroup 
          name="radio-buttons-group"
          value={this.state.payment_method}
          onChange={(e) => this.handleChange(e.target.value)}
          >
            <FormControlLabel value="credit_card" control={<Radio  />} label="Debit or Credit Card" />
            <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
          </RadioGroup>

          <Typography variant="subtitle2" style={{marginTop:'10px'}}>Your transaction will not be charged until after you have confirmed your order.</Typography>
        </Card>
      </Grid>
      <Grid item xs={8} md={8}>
      <CustomButton 
          className={'create-role-step'}
          type={'button'}
          color={'primary'}
          disabled={this.state.payment_method ==''?true:false}
          isLoading={this.state.isLoading}
          onClick={() => {
            this.makePayment()
          }}
        >
         <Box>Pay Now</Box> 
          
        </CustomButton>
      </Grid>
      
      </Grid>
      </Box>
    </Box>;
  }
}

export default Payment;
