import React from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'


const breadcrumbNameMap = {
    '/job-list': 'Job List',
    '/job-vendor-list': 'Job List',
    '/job-schedule': 'Job Schedule',
    '/job-schedule-list': 'Job Schedule List',
    '/live-tracking': 'Live Tracking',
    '/customers': 'Customers',
    '/report-transport-operation': 'Report',
    '/report-transport-operation-details': 'Report',
    '/report-customers': 'Report',
    '/report-customers-details': 'Report',
    '/vendor': 'Vendors',
    '/resources' : 'Resources',
    '/resources/transport-operator' : 'Transport Operator',
    '/resources/logistic-assistance' : 'Logistic Assistant',
    '/resources/vehicle' : 'Vehicle',
    '/jobs': 'Job List',
    '/jobs/route-optimize': 'Route Optimize',
    '/zones': 'Zone List',
};

const titleNameMap = {
    'dashboard' : 'Dashboard Overview',
    'job-list': 'Jobs Overview',
    'job-vendor-list': 'Jobs Overview',
    'job-schedule': 'Jobs Overview',
    'job-schedule-list': 'Jobs Overview',
    'live-tracking': 'Live Tracking',
    'customers': 'Customers',
    'report-transport-operation': 'Reports',
    'report-transport-operation-details': '',
    'report-customers': 'Reports',
    'report-customers-details': '',
    'vendor': 'Vendors',
    'resources' : 'Resources',
    'transport-operator' : 'Transport Operator',
    'logistic-assistance' : 'Logistic Assistant',
    'vehicle' : 'Vehicle',
    'zones' : 'Zones'
}

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;


const BreadcrumbContainer = (props) => {
  return <Box clone className="breadcrumb-container">
            <Route>
            {({ location }) => {
            const pathnames = location.pathname.split('/').filter((x) => x);
            return <>
                <Grid item xs={(props && props.xs) ? props.xs : true}>
                <Breadcrumbs aria-label="breadcrumb">
                <LinkRouter color="inherit" to="/">
                    Home
                </LinkRouter>
                {pathnames.map((value, index) => {
                    const last = index === pathnames.length - 1;
                    let to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    if (to === '/jobs') to = '/job-list';

                    return last ? (
                    <Typography color="textPrimary" key={to}>
                        {breadcrumbNameMap[to]}
                    </Typography>
                    ) : (
                    <LinkRouter color="inherit" to={to} key={to}>
                        {breadcrumbNameMap[to]}
                    </LinkRouter>
                    );
                })}
                </Breadcrumbs>
                <CustomLabel
                    className={'no-margin'}
                    label={titleNameMap[pathnames.slice(-1)[0]]}
                    weight={'bold'}
                    size={'xl'}
                />
                </Grid>
            </>
            }}
    </Route>
  </Box>;
};


export default BreadcrumbContainer;
