import React, { Component } from 'react';
import { AuthContext } from "../components/Auth/AuthDataProvider.jsx";

import { 
  Grid,
  IconButton,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';

import ProfileDropdown from "../components/Header/ProfileDropdown.jsx";
import NotificationDropdown from "../components/Header/NotificationDropdown.jsx";


const useStyles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
});

class Header extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let showHeader = ((props.showHeader === true || props.showHeader === false) ? props.showHeader : true);
    let showMenuIcon = ((props.showMenuIcon === true || props.showMenuIcon === false) ? props.showMenuIcon : true);

    this.state = {
      showHeader: showHeader,
      showMenuIcon: showMenuIcon,
      isLogedUser: false,
      isDrawerOpen: false,
      profileMenu: null,
    };
  }


  componentDidMount() {
    this.checkIsUserLogedIn();
  }


  checkIsUserLogedIn = () => {
    const { authenticated } = this.context;
    this.setState({ 
      isLogedUser: authenticated
    });
  }

  logout = ()  => {
    const { clearStorage } = this.context;

    this.setState({
      profileMenu: null,
      isDrawerOpen: false
    });

    clearStorage();

    window.location = "/";
  }


  getHeader = () => {
    return <Grid container alignItems={'center'}>
      <Grid item xs={'auto'}>
        {this.state.showMenuIcon && <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => {
            if(this.props.onDrawerToggle){
              this.props.onDrawerToggle();
            }
          }}
          className={this.props.classes.menuButton}
        >
          <MenuIcon style={{
            color: 'white'
          }} />
        </IconButton>}
      </Grid>
      <Grid item xs={true}>
      {window.location.pathname == "/accounts/all-account" ? <span style={{color:"#fff", fontSize: '22px', fontWeight:"400"}}>All Accounts</span> : ''}
      {window.location.pathname == "/dashboard" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Dashboard</span> : ''}
      {window.location.pathname == "/accounts/add-account" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Add Account</span> : ''}
      {window.location.pathname == "/accounts/all-role" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>All Roles</span> : ''}
      {window.location.pathname == "/accounts/add-role" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Add Role</span> : ''}
      {window.location.pathname == "/invoice" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Invoice Lists</span> : ''}
      {window.location.pathname == "/device" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Device Management</span> : ''}
      {window.location.pathname == "/setting-profile" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>User Profile Settings</span> : ''}
      {window.location.pathname == "/company-profile" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Company Profile Settings</span> : ''}
      {window.location.pathname == "/payment-history" ? <span style={{color:"#FFF", fontSize: '22px', fontWeight:"400"}}>Payment History</span> : ''}
      </Grid>
      {/* <Grid item xs={'auto'}>
        <NotificationDropdown 
          profileMenu={this.state.profileMenu}
          onLogout={this.logout}
        />
      </Grid> */}
      <Grid item xs={'auto'}>
        <ProfileDropdown 
          profileMenu={this.state.profileMenu}
          onLogout={this.logout}
        />
      </Grid>
    </Grid>
  }

  
  render() {
    if(this.state.showHeader){
      return this.getHeader()
    }
  }
}

export default withStyles(useStyles)(Header);
