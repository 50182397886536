import React, { Component } from 'react';
import moment from "moment";

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import authApi from "../../api/Authentication.jsx";
import BreadCrumb from '../../components/Navs/Breadcrumb';
import CustomList from '../../components/List/CustomList.jsx';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomListZoneItem from '../../components/List/CustomListZoneItem.jsx';
import { 
  Box,
  Grid,
  Hidden,
  Drawer,
  IconButton,
  Divider,
  CircularProgress,
} from '@material-ui/core';


import Icon from '@material-ui/core/Icon';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import CustomPolygonMap from '../../components/GoogleMap/CustomPolygonMap.jsx';

import socketIOClient from "socket.io-client";
import { Observable } from 'rxjs/Observable';


const { 
  REACT_APP_LIVE_TRACKING_SOCKET_URL,
} = process.env;


export class Zones extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props); 
    this.state = {
      center: null,
      zoom: 12,
      polygons: [],
      contentHeight: 396,
      drawingMode:false,
      drawingModeTitle:'New Zone',
      drawingModeIcon:'add',
      drawingModeShape:'polygon',
      openMenu: false,
      workers:[]
    }
  }  


  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.callWorkersReadApi();
    this.callPolygonReadApi();
  };
  

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  
  /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */

  /* MAP */
  setMap = () => {
    return <Box
      width={'100%'}
      height={'100%'}
    >
      <CustomPolygonMap
        center={this.state.center}
        zoom={this.state.zoom}
        polygons={ this.state.polygons}
        workers = {this.state.workers}
        drawingMode={this.state.drawingMode}
        drawingModeShape={this.state.drawingModeShape}
        onClick={(item, data, i) => {
            let polygons = this.state.polygons;
            if(data && data.isDeleted){
              if(data.id){ this.callDeleteApi(data, () => {                  
                apiUtil.toast('Successfully Deleted', 'check_circle');
                let index = polygons.map(x => {return x.id; }).indexOf(data.id);            
                polygons.splice(index, 1);   
                this.setState({ polygons:polygons });            
              }); }else{
                polygons.splice(-1, 1);
                this.setState({ polygons:polygons });
              }

              
            }  

            if(data && data.isSave) { 
              let preparingData = {data:[] };
              preparingData.data.push({polygons:[{polygon_name:data.polygon_name, polygon_desc:data.polygon_desc, coordinates:data.worker_polygon_coordinates}]});
              this.callCreateApi(preparingData, () => {                  
                apiUtil.toast('Successfully Created', 'check_circle');
                polygons.map((pin, p) => { pin.loadingSave = false; pin.isOpen = false; pin.isSave=false;});
                this.setState({polygons:polygons});

              });
              
            }

            if(data && data.isAssigned) { 
              polygons.map((pin, p) => {
                  if(p === i) {
                    pin.worker_names = data.worker_names;
                    pin.workerIds = data.workerIds;
                    return pin;
                  }                  
                });

              this.setState({polygons:polygons});
            }
            
            if(data && (data.isAssignedSubmit || data.isUpdate))
            {
              let preparingData = {data:[] };
              data.worker_ids = data.workerIds.join(","); 
              preparingData.data.push({polygons:[{id:data.id, polygon_name:data.polygon_name, polygon_desc:data.polygon_desc, coordinates:data.worker_polygon_coordinates, worker_ids:data.workerIds.join(","), workerIds:data.workerIds}]});
              this.callUpdateApi(data.id, preparingData, () => {                  
                apiUtil.toast('Successfully Updated', 'check_circle');
                polygons.map((pin, p) => { pin.loadingAssign=false; pin.loadingSave = false; pin.isOpen = false; pin.isAssignedSubmit=false;; pin.isUpdate=false; pin.isEdit=false;});
                this.setState({polygons:polygons});
              });
              
            }

            if(!data && !i && item){
              
              let polygon = [];

              item.map((v,k)=>{ polygon.push({latitude:v.lat, longitude:v.lng}); });

              let result = {worker_polygon_coordinates:polygon, polygon_name:'',polygon_desc:'', isOpen:true, worker_names:[], workerIds:[], isNew:true, polygon_color_hash:this.generateColor(this.state.polygons.length)};
              
              this.setState({drawingMode:false, drawingModeTitle:'New Zone', drawingModeIcon:'add', drawingModeShape:null});
              polygons.push(result);
              this.setState({polygons:polygons});
            } 

            if(data && !data.isOpen && !data.hasClosed && polygons && polygons.length > 0){
                polygons.map((pin, p) => {
                  pin.isOpen = (p === i) ? true : false;
                  return pin;
                });

              this.setState({polygons:polygons});
            }

            if(data && data.isOpen && data.isEdit && polygons && polygons.length > 0){
                polygons.map((pin, p) => {
                  pin.isOpen = (p === i) ? true : false;
                  pin.isEdit = true;
                  return pin;
                });

              this.setState({polygons:polygons});
            }

            if(data && data.hasClosed){this.callPolygonReadApi(); }

          }               
        }
       
      />
    </Box>
  }

  /*END MAP*/

  handleResize = () => {
    if(this.state.clickedHistoeryItem !== null){
      let header = document.body.getElementsByTagName('header')[0];
      let headerPosition = header.getBoundingClientRect();
      let headerHeight = headerPosition.height;
  
      let stickyPageHeader = document.body.getElementsByClassName('custom-sticky-page-header')[0];
      let stickyPageHeaderPosition = stickyPageHeader.getBoundingClientRect();
      let stickyPageHeaderHeight = stickyPageHeaderPosition.height;
  
      let stickyListHeaderHeight = 0;
      try {
        let stickyListHeader = document.body.getElementsByClassName('MuiListSubheader-sticky')[0];
        let stickyListHeaderPosition = stickyListHeader.getBoundingClientRect();
        stickyListHeaderHeight = stickyListHeaderPosition.height;
      } catch(e){}
      
      let histoeryItemHeaderHeight = 0;
      try {
        let histoeryItemHeader = document.body.getElementsByClassName('histoery-head-item')[0];
        let histoeryItemHeaderPosition = histoeryItemHeader.getBoundingClientRect();
        histoeryItemHeaderHeight = histoeryItemHeaderPosition.height;
      } catch(e){}
  
      let contentHeight = headerHeight + stickyPageHeaderHeight + stickyListHeaderHeight + histoeryItemHeaderHeight;
  
      this.setState({
        contentHeight: contentHeight
      });
    }
  }

  /* POLYGON */
   callPolygonReadApi = (callback = null) => {
    const { accessToken } = this.context;
    let param = {
      is_active: true,
      // require_worker_only: true,
      // is_truck_driver: true,
    };
    this.setState({
    }, () => {
      api.read('worker_polygons', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];
          
          if(result && result.length > 0){
            result = result.map((item, i) => {
              item.isOpen = false;
              item.polygon_color_hash = this.generateColor(i); 
              item.worker_names = [];
              item.workerIds = [];
              item.workers.map((p,k)=>{
                item.worker_names.push(p.first_name);
                item.workerIds.push(p.id);
                item.value = item.first_name;
              });
              return item;
            });

            this.setState({
              polygons: result,
            }, () => {
              if(callback){
                callback(result);
              }
            });
          } else {
            this.setState({
              polygons: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          }
        }, (error, type) => {
          this.setState({
            polygons: [],
          }, () => {
            if(callback){
              callback([]);
            }
          });
        });
      });
    });
  }
  /*END POLYGON*/

  /*WORKERS*/
  callWorkersReadApi = (callback = null) => {
    const { accessToken } = this.context;
    let param = {
      is_active: true,
      // require_worker_only: true,
      // is_truck_driver: true,
    };
    this.setState({
      //isLoading: true,
      //clickedWorkerItem: null,
      //clickedHistoeryItem: null,
    }, () => {
      ldsApi.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data && data.length > 0) ? data : [];
          
          if(result && result.length > 0){
            result = result.map((item, i) => {
              item.isOpen = false
              item.value = item.first_name
              return item;
            });

            this.setState({
              workers: result,
            }, () => {
              if(callback){
                callback(result);
              }
            });
          } else {
            this.setState({
              workers: [],
            }, () => {
              if(callback){
                callback([]);
              }
            });
          }
        }, (error, type) => {
          this.setState({
            workers: [],
          }, () => {
            if(callback){
              callback([]);
            }
          });
        });
      });
    });
  }
  /*END WORKERS */

  /*EDIT ZONE */
  callUpdateApi = (id, row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      //isLoading: true
    }, () => {
      api.update('worker_polygons/' + id, row, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                //isLoading: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            }, (error, type) => {
              this.setState({
                //isLoading: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            });
      });
    });
  }
  /*END EDIT ZONE*/ 

  /*ADD ZONE */
  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      //isLoading: true
    }, () => {
      api.create('worker_polygons', row, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                //isLoading: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            }, (error, type) => {
              this.setState({
                //isLoading: false,
              }, () => {
                if(callback){
                  callback();
                }
              });
            });
      });
    });
  }
  /*END ADD ZONE*/ 

  /*DELETE ZONE*/
  callDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      //isLoading: true
    }, () => {
      let param = {
        //destroy_all: true,
      };

      api.delete('worker_polygons/' + row.id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let polygons = this.state.polygons;
            this.setState({
              //isLoading: false,
              //polygons:polygons
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              //isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }
  /*END DELETE ZONE*/

  /*COLOR GENERATOR*/
  generateColor = (i) =>{
    let initiate = i + 1;
    let arrayColor = ["rgb(243, 101, 185)","rgb(153, 139, 228)","rgb(246, 166, 28)","rgb(34, 150, 243)","rgb(76, 175, 79)","rgb(72, 195, 202)","rgb(158, 158, 158)","rgb(253, 84, 72)"]; 
    if(initiate >= 9){   let iterate = Math.floor(initiate / 8); for(let x = 0; x < iterate; x++){  initiate = initiate - 8;  } }
    return arrayColor[initiate-1];
  }
  /*END COLOR*/

  leftMenu = () => {
    return <Box width={'100%'}>

      <CustomList
        header={<Box pt={1} pb={1}>
          <CustomButton
            className={'no-padding'}
            color={'primary'}
            href={'/'}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.setState({
                drawingMode: !this.state.drawingMode
              })
              if(!this.state.drawingMode){ 
                  this.callPolygonReadApi();
                  apiUtil.toast("Entering Drawing Mode",null,"info"); 
                  this.setState({'drawingModeTitle':'Cancel Zone','drawingModeIcon':'close'});
                }else{
                  apiUtil.toast("Leaving Drawing Mode",null,"success");
                  this.setState({'drawingModeTitle':'New Zone', 'drawingModeIcon':'add'});
              }
              
            }}
          >
            <Icon component={'i'} style={{ position: 'relative', top: '1px', left: '-5px' }}>{this.state.drawingModeIcon}</Icon>
            <span>{this.state.drawingModeTitle}</span>
          </CustomButton>
          {this.state.polygons.map((item, i) => {
            let name = ((item.polygon_name && item.polygon_name !== '') ? item.polygon_name : '');
            let description = (item.polygon_desc && item.polygon_desc !== '') ? item.polygon_desc : '';
            
            return <CustomListZoneItem
              key={i}
              text={name}
              data={item}
              desc={description}
              color={item.polygon_color_hash}   
              onClick={(onClickItem) => {
                  let itemData = (onClickItem && onClickItem.state && onClickItem.state.data) ? onClickItem.state.data : null;
                  if(itemData && itemData.worker_polygon_coordinates && itemData.worker_polygon_coordinates.length > 0){
                    
                    if(itemData.worker_polygon_coordinates[0].latitude && itemData.worker_polygon_coordinates[0].longitude){
                      this.setState({
                        center: { lat: itemData.worker_polygon_coordinates[0].latitude, lng: itemData.worker_polygon_coordinates[0].longitude }
                      });
                      
                      this.state.polygons.map((p, k) =>{ p.isOpen =false});
                      itemData.isOpen = true;
                      this.setState({polygons:this.state.polygons});
                    }
                  }
                }}           
            />
          })}

        </Box>}
      >
      </CustomList>
    </Box>
  }

  toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.toggleMenu();
  }

  toggleMenu = () => {
    this.setState({ 
      openMenu: !this.state.openMenu
    });
  }

  setDrawer = () => {
    return <Drawer className={'right-drawer'} anchor={'right'} open={this.state.openMenu} onClose={this.toggleDrawer()}>
      <Box minWidth={this.state.minWidth}>
        {this.leftMenu(false)}
      </Box>
    </Drawer>
  }

  render() {
    return <Box className="zone-page" style={{ height: 'calc(100% - 155px)' }}>
      <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader>
      <Box height={'100%'}>
        <Grid container style={{ height: '100vh' }}>
          <Hidden smDown>
            <Box clone style={{ overflow: 'auto', height: 'calc(100% - 0px)' }}>
              <Grid item xs={12} md={3} lg={3} xl={2}>
                {this.leftMenu()}
              </Grid>
            </Box>
          </Hidden>
          <Box clone position={'relative'}>
            <Grid item xs={12} md={9} lg={9} xl={10}>

              <Hidden mdUp>
                <Box
                  style={{
                    position: 'absolute',
                    right: '10px',
                    zIndex: '1',
                  }}
                >
                  <IconButton onClick={() => {
                    this.toggleMenu();
                  }}>
                      <Icon component={'i'}>menu_open</Icon>
                  </IconButton>
                </Box>
              </Hidden>
              {this.setMap()}
            </Grid>
          </Box>
        </Grid>
      </Box>
      {this.setDrawer()}
      
    </Box>
  }
}