import React, { useCallback, useContext, useEffect} from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';

import './assets/scss/main.scss';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "./components/Auth/AuthDataProvider.jsx";
import AuthDataProvider from "./components/Auth/AuthDataProvider.jsx";
import RouteLayout from "./layout/Main/RouteLayout.jsx";
import CreatePageLayout from './layout/Main/CreatePageLayout.jsx';
import NotificationList from "./components/Header/NotificationList.jsx";
import PageNotFound from "./views/PageNotFound/PageNotFound.jsx";
import SignIn from "./views/Account/Signin.jsx";
import SignUp from "./views/Account/SignUp.jsx";
// import Reset from "./views/Account/reset.jsx";

import Dashboard from "./views/Dashboard/Dashboard.jsx";
import {RoleAccessService} from "./data/role-access" 
import AllAccount from './views/accounts/allaccount';
import AddAccount from './views/accounts/addaccount';
import AllRole from './views/accounts/allrole';
import AddRole from './views/accounts/addrole';
import Device from './views/device/device';
import Invoice from './views/invoice/invoice';
import apiUtil from "./api/apiUtil.jsx";
import { 
  Settings,
  SettingProfile,
} from "./views/index";
import CompanySetting from './views/Settings/settingCompany';
import PaymentHistory from './views/invoice/paymentHistory';
import Payment from './views/invoice/payment';
import InvoiceDetail from './views/invoice/invoiceDetail';
import { ChangePassword } from './views/Settings/ChangePassword';

var isClicked = false;

const { 
  REACT_APP_COOKIE_ACCESS_TOKEN,
  REACT_APP_COOKIE_REFRESH_TOKEN,
  REACT_APP_COOKIE_TOKEN_TYPE,
  REACT_APP_COOKIE_TOKEN,
  REACT_APP_COOKIE_USER
} = process.env;

function App() {

  const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split(';')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

let url = getQueryStringParams(window.location.pathname);
// const { clearStorage } = useContext(AuthContext);
  const show = url.show;
  const email = url.email;
  const resetToken = url.token;
  const setPasswordId = url.id;

  var path = window.localStorage.getItem('access_token');
  if(path == null){
    if(show == null && email == null) {
    if( ! (window.location.pathname == "/sign-in"  || window.location.pathname == "/sign-up" ) ) {
      // window.location.href ="sign-in";
    }
  }
  }

  useEffect(() => {
    apiUtil.deleteCookie(REACT_APP_COOKIE_REFRESH_TOKEN);
    window.localStorage.clear();
      // if(show == "setpassword") {
        // window.localStorage.setItem('access_token', null);
        // window.localStorage.setItem('authenticatedAuth', false);
        // apiUtil.deleteCookie(REACT_APP_COOKIE_REFRESH_TOKEN);
        // clearStorage();
      // }
     
  }, []); 

  const beforeinstallpromptHandler = useCallback(
    (deferredPrompt) => {
      if(!isClicked){
        toast(
          <button 
            onClick={() => {
              deferredPrompt.prompt();
              deferredPrompt.userChoice
                .then((choiceResult) => {
                  isClicked = true;
                  // if (choiceResult.outcome === 'accepted') {
                  //   console.log('User accepted the A2HS prompt');
                  // } else {
                  //   console.log('User dismissed the A2HS prompt');
                  // }
              });
            }}  
          >Install</button>
        );
      }
    }, []
  );
  window.addEventListener('beforeinstallprompt', beforeinstallpromptHandler);

  
  const getUserConfirmation = (dialogKey, callback) => {
    const dialogTrigger = window[Symbol.for(dialogKey)]; 
    if (dialogTrigger) {
      return dialogTrigger(callback);
    }
    callback(true);
  };

  // let grantedMenu = new RoleAccessService;
  // let currentRole = window.localStorage.getItem('current_role');

  // if(!grantedMenu.admin_roles_with_customer.includes(currentRole)){
  //   var root_url = "/live-tracking";
  //   console.log(currentRole, "Current Role")
  // } else {
  //   var root_url="/dashboard";
  // }

  return (
    <AuthDataProvider>
      <div className="App">
        <BrowserRouter basename="/" getUserConfirmation={getUserConfirmation}>
          <Switch>
            <Route exact path="/">              
                <Redirect to="/dashboard" />           
              </Route>
            <RouteLayout path="/dashboard" component={Dashboard} menu_name={"dashboard"} />
            <RouteLayout path="/settings" component={Settings} layout={CreatePageLayout} menu_name={"settings"} />
            <RouteLayout path="/setting-profile" component={SettingProfile} layout={CreatePageLayout} menu_name={"user_profile"} />
            <RouteLayout path="/accounts/all-account" component={AllAccount} menu_name={"all-accounts"}/>
            <RouteLayout path="/accounts/add-account" component={AddAccount} menu_name={"add-accounts"}/>
            <RouteLayout path="/accounts/all-role" component={AllRole} menu_name={"all-roles"}/>
            <RouteLayout path="/accounts/add-role" component={AddRole} menu_name={"add-roles"}/>
            <RouteLayout path="/device" component={Device} menu_name={"device-management"}/>
            <RouteLayout path="/invoice" component={Invoice} menu_name={"invoice"}/>
            <RouteLayout path="/company-profile" component={CompanySetting} menu_name={"invoice"}/>
            <RouteLayout path="/invoice" component={Invoice} menu_name={"company-profile"}/>
            <RouteLayout path="/payment-history" component={PaymentHistory} menu_name={"payment-history"}/>
            <RouteLayout path="/payment" component={Payment} menu_name={"payment"}/>
            <RouteLayout path="/invoice-detail" component={InvoiceDetail} menu_name={"invoice-datail"}/>    
            <RouteLayout path="/password" component={ChangePassword} menu_name = {"password"} />          
            {/* <Route path="/onboarding" component={Onboarding} menu_name={"onboarding"}/>
            <Route path="/onboarding-complete" component={OnboardingComplete} menu_name={"onboarding_complete"}/> */}
            <Route path="/sign-in" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />
            <Route component={PageNotFound} />
          </Switch>
        </BrowserRouter>
        <ToastContainer className="custom-toast" autoClose={false} />
      </div>
    </AuthDataProvider>
  );
}


export default App;
