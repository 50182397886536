export class RoleAccessService {

    constructor() { }
    
    getCustomerTemplateRoleMenuList(){
      return this.customer_template_role_menu_list;
    }
    getCustomerTemplateRoleJobAccessList(){
      return this.customer_template_role_menu_list;
    }
    
    //  Get Uqique role permission  list
    getUniqueRolePermissionList(type) {
      let uniqueRolePermissionsRaw = window.localStorage.getItem('uniqueRolePermissions');
      uniqueRolePermissionsRaw = JSON.parse(uniqueRolePermissionsRaw)
      // [...new Set(data.owner_info.roles[0].permissions.map(item => item.group))]      
      let uniqueRolePermissionList = uniqueRolePermissionsRaw ? [...new Set(uniqueRolePermissionsRaw.map(item => item[type]))] : [];
      return uniqueRolePermissionList;
    }


    //Action of permission for New role 
    getUniqueRolePermissionAction() {
      let uniqueRolePermissionsAction = this.getUniqueRolePermissionList('name')
      return uniqueRolePermissionsAction;
    }
    
    //
    isPermissionActionAllowed(role, action) {
      const permissionAction = this.getUniqueRolePermissionAction();
      if(!this.admin_roles_with_customer.includes(role) && permissionAction.includes(action)) {
        return true;
      } else if (this.admin_roles_with_customer.includes(role)) {
        return true;
      }
      return false;
    }

    // Permissions enchancement for new role
    getUniqueRolePermission() {
            
      let uniqueRolePermissions = this.getUniqueRolePermissionList('group');
      if(uniqueRolePermissions) {
        uniqueRolePermissions = uniqueRolePermissions.map(x=> {          
          switch (x) {
            case 'Dashboard':
              return 'dashboard';
              break;
            case 'Jobs':
              return 'jobs';
              break;
            case 'Customers':
              return 'customers';
              break;
            case 'Reports':
              return 'report_transport_operation';
              break;
            case 'Vendors':
              return 'vendor';
              break;
            case 'Vehicles':
              return 'resources_vehicle';
              break;
            case 'Zone':
              return 'zones';
              break;
            case 'Driver & Manpower':
              return 'transport_operator';
              break;
            default:
              return x;
          }
        });

      }     

      uniqueRolePermissions.push("user_profile");
      uniqueRolePermissions.push("on_boarding");
      uniqueRolePermissions.push("live_tracking");
      uniqueRolePermissions.push("job_schedule");
      uniqueRolePermissions.push("job_schedule_list");

      
      if( uniqueRolePermissions.includes('customers') && this.getUniqueRolePermissionAction().includes('Customer Add')) {
        uniqueRolePermissions.push("customer_form");
      }
      
      if( uniqueRolePermissions.includes('jobs') && this.getUniqueRolePermissionAction().includes('Job Add')) {
        uniqueRolePermissions.push("job_form");
        uniqueRolePermissions.push("vendor_list");
      }

      if( uniqueRolePermissions.includes('transport_operator')) {
        uniqueRolePermissions.push('resources_logistic_assistance');
        if(this.getUniqueRolePermissionAction().includes('Driver & Manpower Add')) {
          uniqueRolePermissions.push('resources_logistic_assistance_form');
        }
        
      }

      // 'Transport operator', 'Logistic assistance', 'Vehicle'
      if(uniqueRolePermissions.includes('transport_operator') || uniqueRolePermissions.includes('resources_vehicle')) {
        uniqueRolePermissions.push('Resources');

        if(uniqueRolePermissions.includes('transport_operator') && this.getUniqueRolePermissionAction().includes('Driver & Manpower Add')) {
          uniqueRolePermissions.push('transport_operator_form');
        }
        if(uniqueRolePermissions.includes('resources_vehicle') && this.getUniqueRolePermissionAction().includes('Create Vehicle')) {
          uniqueRolePermissions.push('resources_vehicle_form');
        }       
      }

      return uniqueRolePermissions;

      
    }

    customer_template_role_menu_list = ["jobs", "user_profile", "job_form","job_schedule","job_schedule_list" ];
    customer_template_role_job_denied_list = ["job_status", "job_assign", "dashboard", "job_date", "job_time", "step_status", "step_address", "add_more_job_step" ];
    admin_roles = ['Admin Template Roles', 'LDS Template Role', 'Super Admin Role'];    
    admin_roles_with_customer = ['Admin Template Roles', 'LDS Template Role', 'Super Admin Role','Customer Template Role'];    
    
    isShouldShowMenu(currentRole, menu_name) { 
      const uniqueRolePermissions = this.getUniqueRolePermission();
      
      if(currentRole === 'Customer Template Role' && this.customer_template_role_menu_list.includes(menu_name)) {
        
        return true;
      }
      if(!this.admin_roles_with_customer.includes(currentRole) && uniqueRolePermissions.includes(menu_name)) {
        return true;
      // } else if(currentRole !== 'Customer Template Role') {
      } else if(this.admin_roles.includes(currentRole)) {
        return true;
      }
  
      return false;
    }  
    isShouldShowJobAccess (currentRole, section_name) {
      // console.log("currentRole", currentRole);
      // console.log("section_name", section_name);
      if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name)) {
        return false;
      } else if(currentRole !== 'Customer Template Role') {
        return true;
      }
      return true;
    }
    isShouldReadyOnlyAccess (currentRole, section_name) {
      // console.log("currentRole", currentRole);
      // console.log("section_name", section_name);
      if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name)) {
        return true;
      } else if(currentRole !== 'Customer Template Role') {
        return false;
      }
      return true;
    }
    isShouldShowEditButton (currentRole, section_name, worker_id) {
      // console.log("currentRole", currentRole);
      // console.log(section_name);
      // console.log(worker_id+ "worker_id");
      // console.log(typeof worker_id);
      // console.log("section_name");
      if(currentRole === 'Customer Template Role' && this.customer_template_role_job_denied_list.includes(section_name) &&  worker_id != null) {
        return false;
      } else if(currentRole !== 'Customer Template Role') {
        return true;
      }
      return true;
    }
}
