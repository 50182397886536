import React from 'react';

import {
    Box,
    Checkbox
} from '@material-ui/core';


const CustomCheckbox = props => {
    const { className, onChange } = props;

    const [checked, setChecked] = React.useState(((props.checked === true || props.checked === false) ? props.checked : false));
    const [label, setLabel] = React.useState(props.label ? props.label : null);
    const [disabled] = React.useState(((props.disabled === true || props.disabled === false) ? props.disabled : false));
    const [color] = React.useState(props.color ? props.color : "primary");
    const [bold] = React.useState(((props.bold === true || props.bold === false) ? props.bold : false));


    React.useEffect(() => {
        setChecked(((props.checked === true || props.checked === false) ? props.checked : false));
        setLabel(props.label);
    }, [props.checked, props.label]);


    return (
        <Box className={"custom-checkbox-component " + className}>
            <Box>
                <Checkbox 
                    checked={checked}
                    disabled={disabled}
                    color={color}
                    onClick={() => {
                        setChecked(!checked);

                        if(onChange){
                            onChange(!checked);
                        }
                    }}
                />
                {label && <label
                    className={"checkbox-label-style" + (bold ? ' boldCB' : '')}
                    onClick={() => {
                        if(!disabled){
                            setChecked(!checked);

                            if(onChange){
                                onChange(!checked);
                            }
                        }
                    }}
                >
                    {label}
                </label>}
            </Box>
        </Box>
    );
  }

  export default CustomCheckbox;