import React, { useState, useCallback, useEffect, useContext } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomCropper from '../../components/Cropper/CustomCropper.jsx';
import axios from "axios";

import {
    Box,
    Grid,
    Container,
    Divider,
    IconButton,
    Select,
    CircularProgress
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const AddAccount = () => {
    const history = useHistory();
    const location = useLocation();
    const { accessToken, user, setUser } = useContext(AuthContext);
    const pathName = (location && location.state && location.state.pathname && location.state.pathname.length > 0) ? location.state.pathname : "/";
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const id = (location && location.state && location.state.id && location.state.id > 0)? location.state.id : '';
  
    // general section
    const [name, setName] = useState('')
    const [roleData, setRoleData] = useState("") 
    const [personalEmail, setPersonalEmail] = useState("")
    const [personalRole, setPersonalRole] = useState("")
    const [personalPassword, setPersonalPassword] = useState("")
    const [regId, setRegId] = useState("")
    const [personalProfileImageURL, setPersonalProfileImageURL] = useState("")
    const [nameValidated, setNameValidated] = useState(false)
    const [personalEmailValidated, setPersonalEmailValidated] = useState(false)
    const [roleValidated, setRoleValidated] = useState(false)
    const [personalProfileOldData, setPersonalProfileOldData] = useState("")
   
    const callReadApi = () => {
        let errorMessage = "";
        let status = 0;
        const {
          REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = `admins/${id}`;
        const URL = REACT_APP_AUTH_API_URL + PATH + api;

        
        setIsLoading(true)
    
        axios.get(
          // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
          URL,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken,
              'app-name': 'AUTH',
            }
          })
          .catch(error => {
            try {
              status = error.response.status;
    
              if (error.response) {
                // Request made and server responded
                errorMessage = error.response.data;
              } else if (error.request) {
                // The request was made but no response was received
                errorMessage = error.request;
              } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
              }
            } catch (err) {
              errorMessage = 'Service Unavailable!'
            }
            setIsLoading(false)
          }).then((response) => {

            
            
            var json_data = response.data.result.application_registrations;
            var reg_id= "";
            for (var i in json_data) {
                if(reg_id != "")
                reg_id += ",";
                reg_id += json_data[i].id;
            }
            setRegId(reg_id);
            if (response != null) {
                setName(response.data.result.first_name);
                setPersonalEmail(response.data.result.email);
                setPersonalPassword(response.data.result.password);
                setPersonalProfileImageURL(response.data.result.profile_image_url); 
                setPersonalRole(response.data.result.roles[0] ? response.data.result.roles[0].id : '');
                
            }
            setIsLoading(false)
          });
      }


    const callRollReadApi = () =>{
        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
        const PATH = 'admin/api/';
        const api = "roles";
        const URL = REACT_APP_AUTH_API_URL + PATH + api;

         setIsLoading(true)
        let param = {
            // page: this.state.page,
            // take: this.state.limit,
            // search: "",
            // start_date: startDate,
            // end_date: endDate,
            is_active: true,
            sorting_array: ['created_at DESC'],
        };
        axios.get(
            // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
            URL,
            {
                params: param,
                data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .catch(error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
                setIsLoading(false);
            }).then((res) => {
                if(res) {
                    var sss= res.data.result;
                    if (res != null) { 
                    setRoleData(sss);
                    }
                }
                
                
                setIsLoading(false);

            });
    }

    useEffect(() => {
        if(roleData == ""){ 
            if(id != ''){
        
            callReadApi( );  
            }
            callRollReadApi(); 
        }
    },[id, name, personalRole, personalEmail]);

    const CustomStickyPageHeader = () => {
        return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
            <Grid container>
                <Box clone>
                    <Grid item xs={'auto'}>
                        <IconButton
                            onClick={() => {
                                history.push('/accounts/all-account');
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={'auto'}>
                        <Divider orientation={'vertical'} />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={true}>
                        <CustomLabel
                            className={'no-margin'}
                            label={id ? 'Edit Account': 'Create Account'}
                            weight={'bold'}
                            size={'md'}
                        />
                    </Grid>
                </Box>
                
            </Grid>
        </Box>
    }

    const settingsAccountProfile = () => {
        return <Box>
                <Grid container p={1} >
                    <CustomLabel
                        label={'Account Information'}
                        weight={'bold'}
                        size={'md'}
                    />
                    <Box clone>
                        <Grid item xs={12}>
                            <div style={{
                                position: 'relative',
                                display: 'flex',
                                height: '260px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                justifyItems: 'center',
                            }}>
                                <CustomCropper
                                    className={'no-shadow'}
                                    path={'ProfilePicture'}
                                    initialImg={personalProfileImageURL}
                                    onLoad={() => {
                                        // setIsLoading(true);
                                    }}
                                    onUpload={(url) => {
                                        if (url && url.result && url.result.image_medium_url && url.result.image_medium_url && url.result.image_medium_url !== '') {
                                            setPersonalProfileImageURL(url.result.image_medium_url);
                                        }
                                    }}
                                    onEnd={() => {
                                        // setIsLoading(false);
                                    }}
                                />
                            </div>
                        </Grid>
                    </Box>
                    <Box clone>
                        <Grid item xs={12}>
                            <CustomInput
                                label={'Name'}
                                type={"text"}
                                placeholder={'Enter Name'}
                                className="input-label--horizontal"
                                value={name}
                                error={nameValidated}
                                required={true}
                                onChange={e => {
                                    setName(e.target.value)
                                }}
                            />
                        </Grid>
                    </Box>
                    <Box clone>
                        <Grid item xs={12}>
                            <CustomInput
                                label={'Email'}
                                className="input-label--horizontal"
                                placeholder={'Enter Email'}
                                type={"email"}
                                value={personalEmail}
                                error={personalEmailValidated}
                                required={true}
                                onChange={e => {
                                    setPersonalEmail(e.target.value)
                                }}
                            />
                        </Grid>
                    </Box>
                    { id && (<Box clone>
                        <Grid item xs={12}>
                            <CustomInput
                                label={'Password'}
                                type={"password"}
                                className="input-label--horizontal"
                                value={personalPassword}
                                placeholder={"Leave blank if u don't want to change"}
                                autoComplete={'off'}
                                onChange={e => {
                                    setPersonalPassword(e.target.value)
                                }}
                            />
                        </Grid>
                    </Box>) }
                
                    <Box clone>
                        <Grid item xs={12}>
                                    <label className="labelForm">Select Role</label>
                                    <Select
                                    className="inputForm form-control"
                                    error={roleValidated}
                                    placeholder={'Select from the list...'}
                                    native
                                    value={personalRole}
                                    required={true}
                                    onChange={e => {
                                        setPersonalRole(e.target.value)
                                    }}
                                    
                                    >
                                    <option value=''  >Select a Role from the list...</option>
                                    {roleData 
                                    && roleData.length > 0 
                                    && roleData.filter(data => data.role_name !== "Customer Template Role").map((role) => (
                                        //   (  personalRole != role.id)? 
                                        <option key={role.id} value={role.id} >{role.role_name}</option>
                                        //   <option key={role.id} value={role.id} selected="selected">{role.role_name} </option>  
                                    ))} 
                                    </Select>
                        </Grid>
                    </Box>
                </Grid>
            </Box>;
    }

    const callCreateApi = () =>{

        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
        // let dataid = this.state.id;
        const PATH = 'admin/api/';
        const api = `admins`;
        const data = {
            application_registration_ids: regId,
            first_name: name,
            email: personalEmail,
            id: '',
            // password: personalPassword,
            is_default_password: true,
            profile_image_url: personalProfileImageURL,
            role_id: personalRole,
        };
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');

        axios.post(
            // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'app-name': 'AUTH',

                }
            })
            .catch(error => {
                console.log("response", error.response)
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data.error.email[0]
                        
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
                apiUtil.toast(errorMessage, 'check_circle', 'error');
            }).then((response) => {
                if(response) {
                    apiUtil.toast('Successfully created !!', 'check_circle');
                    history.push('/accounts/all-account');
                }
            });
    }

    const callUpdateApi = () => {
        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;
        // let dataid = this.state.id;
        const PATH = 'admin/api/';
        const api = `admins/${id}/update`;
      
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        let token = window.localStorage.getItem('access_token');
        const data = {
            application_registration_ids: regId,
            first_name: name,
            email: personalEmail,
            password: personalPassword,
            id: id,
            profile_image_url: personalProfileImageURL,
            role_id: personalRole,
      };
        setIsLoading(true)
        axios.put(
            // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
            URL,
            data,
            {
                // params: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'app-name': 'AUTH',
                }
            })
            .catch(error => {
                
                try {
                    status = error.response.status;
      
                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                
                }
                if(errorMessage && errorMessage.error) {
                    apiUtil.toast(errorMessage.error, 'check_circle', 'error');
                } else {
                    apiUtil.toast(errorMessage, 'check_circle', 'error');
                }
                setIsLoading(false)
            }).then((response) => {
                if(response) {
                    apiUtil.toast('Successfully Updated !!', 'check_circle');
                    history.push('/accounts/all-account');
                    // if(id != ''){
                    // callReadApi();
                    // }
                }
                
                setIsLoading(false)
            });
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        setNameValidated(false)
        setPersonalEmailValidated(false)
        setRoleValidated(false)
        if(name === '') {
            setNameValidated(true)
        }
        
        var emailValidated = true
        if(personalEmail === '' || !apiUtil.emailValidation(personalEmail) ) {
            setPersonalEmailValidated(true)
            emailValidated = false
        }

        if(personalRole === '') {
            setRoleValidated(true)
        }

        if (form.checkValidity() === true && emailValidated) { 
            if(id != '') {
            callUpdateApi(id);
            } else {
            callCreateApi();
            }
        }

        
    }

    return (
        <Box mt={2} className="settings--form" >
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <StickyPageHeader isSmall={true}>
                <CustomStickyPageHeader />
            </StickyPageHeader>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <Card>
                        <TabPanel value={value} index={0}>
                            {
                                isLoading
                                ?
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'55vh'}>
                                    <CircularProgress size={35} />
                                </Box>
                                :
                                settingsAccountProfile()
                            }
                        </TabPanel>
                        </Card>
                    </Grid>

                </Grid>

            </Container>
            <Container maxWidth="lg">

                <Box clone m={3} mb={3}>
                    <Grid item xs={'auto'}>
                        <CustomButton
                            type={'submit'}
                            color={'secondary'}
                            isLoading={isLoading}
                        >
                            <Box pl={2} pr={2}>{id ? 'Save Changes' : 'Submit'}</Box>
                        </CustomButton>
                    </Grid>
                </Box>
            </Container>
        </form> 
    </Box>
    )
}

export default AddAccount;
