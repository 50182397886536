import React, { Component } from 'react';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import { NavLink } from 'react-router-dom';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { 
  Box,
  Grid,
  CircularProgress,
  CardContent,
  Typography,
  SvgIcon
} from '@material-ui/core';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import apiUtil from "../../api/apiUtil.jsx";
import axios from 'axios';
import moment from 'moment';
import Card from '../../components/Card/Card.jsx';

import WMSIcon from '../../assets/img/icon-wms.png';
import LDSIcon from '../../assets/img/services-icon2.png';
/**
 * http://lds-api.taskk.me/admin/api/orders/job/stats?start_date=2019-05-01&end_date=2020-11-23
 * http://lds-api.taskk.me/admin/api/dashboard/recent_activity/?start_date=2019-05-01&end_date=2020-11-23
 * http://lds-api.taskk.me/admin/api/dashboard/delivery_perday/?start_date=2019-05-01&end_date=2020-11-23
 */
class Dashboard extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.state = {
      jobTab: 0,
      jobTabTable: 0,

      dateRangePickerIsOpen: false,
      rangeDate: {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      },
      totalDevice:0,
      isLoadingStatus: false,
      status: [],
      tableStatus: [],
      statusSelected: null,
      order_status: null,
      isLoading: false,
      isStatLoading: false,
      grouping: true,
      limit: apiUtil.getDefaultPageSize(),
      data: [],
      total: 0,
      page: 1,
      total_delivery: 0,
      openDialog: false,
      openDialogItem: null,

      openPreviewDialog: false,
      openPreviewDialogItem: null,

      stats : [],
      recent_activity : [],
      delivery_perday : []
    }
  }
  componentDidMount() {
    const { setIsOnBoarding } = this.context;
     this.callReadApi();
     this.callReadDeviceApi();
   
  }
  callReadApi() {
    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "admins";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    const { accessToken } = this.context;

    let param = {
      page: this.state.page,
      take: this.state.limit,
      application_registration_ids: "",
      search: "",
      // start_date: startDate,
      // end_date: endDate,
    };
    this.setState({
      isLoading:true
    })
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
        params: param,
        data: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken,
          'app-name': 'AUTH',
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }

        // if(errorMessage && errorMessage.error) {
        //   apiUtil.toast(errorMessage.error, 'check_circle', 'error');
        // } else {
        //     apiUtil.toast(errorMessage, 'check_circle', 'error');
        // }
        this.setState({
          isLoading:false,
        })
      }).then((response) => {
        if (response != null) {
          this.setState({
            total: response.data.total,
            isLoading: false,
          });
        }

      });
  }

  callReadDeviceApi(){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "device_managements";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    const { accessToken } = this.context;
   
    let param = {
      is_banned : false,
      is_removed: false,
      search: "",
      // start_date: startDate,
      // end_date: endDate,
      sorting_array: ['created_at DESC'],
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
       { 
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
          }
      })
      .catch( error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {
        
        if(response != null){
        this.setState({
          totalDevice: response.data.result.length,
          isLoading: false,
          company_id: response.data.result.application_company_id,
        });
        }
      });
  }
 
  render() {
    const {stats, delivery_perday, total_delivery} = this.state
    let fms_link = ''
    let wms_link = ""
    if(window.location.hostname === 'localhost' || window.location.hostname.includes('staging')) {
      fms_link = "https://staging-fms.evfy.sg/"
      wms_link = "https://staging-wms.evfy.sg/"
    } else {
      fms_link = "https://fms.evfy.sg/"
      wms_link = "https://wms.evfy.sg/"
    }
    return <Box>
            <Card>
              <Grid container>
                <Grid item xs={12} md={12} sm={12} lg={6} xl={6} >
                <Box style={{ display: 'flex' }} >
                        
                        <img src={LDSIcon} alt="LDS Icon"  height="120ox" />
                        <Box m={2}>
                          <Typography gutterBottom variant="h5" component="div">
                            FMS
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                          Fleet Management System
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                          <a href={fms_link} target="_blank">Go to FMS</a> &nbsp; &nbsp; &nbsp;
                          <NavLink to={{pathname: '/accounts/add-account'}} >Add account</NavLink>
                          </Typography>
                        </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                <Box style={{ display: 'flex' }} >
                        
                        <img src={WMSIcon} alt="LDS Icon"  height="120ox" />
                        <Box m={2}>
                          <Typography gutterBottom variant="h5" component="div">
                          WMS
                          </Typography>
                          <Typography variant="h6" color="text.secondary">
                          Warehouse Management System
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                          <a href={wms_link} target="_blank">Go to WMS</a>
                          </Typography>
                        </Box>
                  </Box>
                </Grid>
              </Grid>
              </Card>
      
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <Box>
                  <Card>
                  <CardContent> 
                    <Box textAlign={'center'}>
                      <AccountCircleIcon  style={{fontSize:"50"}}/>
                    <Typography gutterBottom variant="h5" component="div">
                    Total Accounts
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                        {this.state.total? this.state.total : ''}
                    </Typography>
                    <a href="/accounts/all-account" style={{textDecoration: 'none'}}>
                  Manage Accounts
                    </a>
                    </Box>
                  
                  </CardContent>
                </Card>
                </Box>
                
              </Grid>
              <Grid item xs={12} md={6} > 
                <Box>
                  <Card >
                    <CardContent>
                      <Box textAlign={'center'}>
                        <PhoneAndroidIcon style={{fontSize:"50"}} />
                      <Typography gutterBottom variant="h5" component="div">
                      Total Devices
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                      {this.state.totalDevice? this.state.totalDevice: ''}
                      </Typography>
                      <a href="/device" style={{textDecoration: 'none'}}>
                      Manage Devices
                      </a>
                      </Box>
                    
                    </CardContent>
                </Card>
                </Box>
                
              </Grid>
            </Grid> 
    </Box>;
  }
}

export default Dashboard;
