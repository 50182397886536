import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { 
  Box,
  Grid
} from '@material-ui/core';

import CustomDialog from "../../components/Dialog/CustomDialog.jsx";
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';

class NotificationList extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    this.state = {
      vendorTab: 1,

      isLoadingStatus: false,
      total_in: 0,
      total_out: 0,

      isLoading: false,
      grouping: true,
      limit: 10,
      data: [],
      total: 10,
      page: 1,

      openDialog: false,
      openDialogItem: null,
    }
  }
  

  componentDidMount() {
    this.callReadApi();
  }


  /* API */
  callReadApi = (search = '') => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        page: this.state.page,
        take: this.state.limit,
      };

      ldsApi.getNotificationHistory('notifications/simple', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {

            let mydata = (data.result && data.result.length > 0) ? data.result : [];
            let filterData = mydata.map(data => {
              let timeDiff =  moment().diff(moment(data.created_at), "hours") - 8;
              timeDiff = timeDiff < 1 ? timeDiff + " minutes ago": timeDiff > 24 ? data.created_at: timeDiff + "hours ago";
              let name = data.admin ? data.admin.first_name : '';
              if(data.current_status.status === "Not Assigned" && data.attempts != null && data.attempts.length > 0) {
                return {profile_image_url: data.admin ? data.admin.profile_image_url : null, message: data.order_reference_no + " has been " + data.action + " by " + name, time: timeDiff};
              } else if(data.action === "Created") {
                return {profile_image_url: data.admin ? data.admin.profile_image_url  : null, message: data.order_reference_no + " was " + data.action + " by " + name, time: timeDiff};
              } else if(data.action === "Updated") {
                return {profile_image_url: data.admin ? data.admin.profile_image_url  : null, message: data.order_reference_no + " is " + data.action + " by " + name, time: timeDiff};
              } else if (data.action === "Deleted Order") {
                return {profile_image_url: data.admin ? data.admin.profile_image_url  : null, message: data.order_reference_no + " was " + data.action + " by " + name, time: timeDiff};
              }else {
                return {profile_image_url: data.admin ? data.admin.profile_image_url : null, message: data.order_reference_no + " has been " + data.action + "to " + data.current_status.status + " by " + name, time: timeDiff};
              }     
            }).filter(Boolean);
            this.setState({
            data: filterData,
            total: data.total? data.total: 100,
            total_in: data.total_in,
            total_out: data.total_out,
            isLoading: false,
            });
        
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              total_in: 0,
              total_out: 0,
              isLoading: false,
            });
          });
      });
    });
  }


  /* TABLE */
  customTable = () => {
    return <Table 
      ref={this.refTable}
      isLoading={this.state.isLoading}
      limit={this.state.limit}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      onPageChange={(page) => {
        this.setState({
          page: page,
        }, () => {
          this.callReadApi();
        });
      }}
      Toolbar={this.customToolbar}
      columns={[
       
        { 
          title: "Notifications", 
          width: '200px',
          align: 'center',
          sorting: false,
          render: (row) => {
            return <Box>
              <Grid container>
                <Box clone>
                  <Grid item xs={'auto'}>
                        <img src={row.profile_image_url} alt="" style={{display: "inline-block",width: "40px",height: "40px",borderRadius: "50%"}}/>
                        <div className="details" style={{display: "inline-block", marginLeft: "10px"}}>
                                <span className="description" style={{display:"block"}}>{row.message}</span>
                                <span className="time"  style={{display:"block", fontSize: "12px",fontWeight: 200,color: "#aaa"}}>{row.time}</span>
                        </div>   
                  </Grid>
                </Box>
              </Grid>
            </Box>
          }
        },
      ]}
    />
  }

  customToolbar = () => {
    return <Box>
      {/* <Grid container>
        <Box clone order={{ xs: 3, md: 1}}>
          <Grid item xs={12} md={true}>
            <CustomInput
              ref={this.refTableSearch}
              placeholder={'Search Vendor'}
              endAdornment={
                <IconButton
                  onClick={(e) => {
                    this.callReadApi(this.refTableSearch.current.state.value);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              }
              onPressEnter={(e) => {
                this.callReadApi(e.target.value);
              }}
            />
          </Grid>
        </Box>
        <Box clone pb={{ xs: 0, md: 0}} order={{ xs: 2, md: 2}} pr={{ xs: 0, md: 2}} textAlign={'right'}>
          <Grid item xs={12} md={true}>
            
          </Grid>
        </Box>
        <Box clone textAlign={'right'} pb={{ xs: 2, md: 0}} order={{ xs: 1, md: 3}}>
          <Grid item xs={12} md={'auto'}>
              <CustomButton 
                color={'primary'}
                onClick={() => {
                  console.log("Click Export", this.refTable.current);
                }}
              >
                <ExportIcon />
                <Box display={'inline-block'} pl={1}>Export</Box>
              </CustomButton>
          </Grid>
        </Box>
      </Grid> */}
    </Box>
  }
  /* END TABLE */
  

  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.callReadApi(this.refTableSearch.current.state.value);
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }
  /* END DIALOG */


  render() {
    return <Box className="notification-list" >

      <Card>
        {this.customTable()}
      </Card>
      
      {this.customDialog()}
    </Box>;
  }
}

export default withRouter(NotificationList);
