import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import { 
  Box,
  Grid,
} from '@material-ui/core';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

class PaymentHistory extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    

    this.state = {
      data :  [],
      page : 1,
      total : 0,
      limit : 20,
      isLoadingStatus: false,
      pageSize : 22,      // status: [],
      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,
    }

   
  }
  componentDidMount() { 
    this.callReadApi();
  }

  callReadApi(){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "application_payment";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    const { accessToken } = this.context;
   
    let param = {
      page: this.state.page,
      take: this.state.limit,
      search: "",
      // start_date: startDate,
      // end_date: endDate,
      sorting_array: ['created_at DESC'],
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
       { 
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
          }
      })
      .catch( error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {
        // console.log(response);
        if(response != null){
            var json_data = response.data.result;
          var result = [];
          var j = 0;
          for (var i in json_data) {
            if(json_data[i].paypal_payment_id != null && json_data[i].paypal_payment_id != ''){
              result[j] = json_data[i];
              j++;
            }            
          }
        this.setState({
          data: result,
          total: response.data.total,
          isLoading: false,
        });
        }
       
      });
  }

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }
 
  customTable = () => {

    return <Table 
      ref={this.refTable}
      className={'job-table-list' }  
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      limit={this.state.limit}
      pageSize= {this.state.pageSize}
      // pageSizeOptions ={this.state.pageSizeOptions}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      // defaultExpanded={true}
      // selection={true}
      onPageChange={(page,size) => {
        
        this.state.limit = size;
        this.setState({
          page: page,
        }, () => { 
          this.callReadApi();
          // this.callReadApi(this.refTableSearch.current.state.value);
        });
      }}
      // Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={[
     
        { 
          title: "Recipent Number", 
          field: "Recipent Number",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150             
          },
          render:(row, type) => {
            if(type === 'row'){
              return (row.paypal_payment_id   != ' ') ? HtmlParser(row.paypal_payment_id) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Payemnt Date", 
          field: "Payemnt date",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240            
          },

          render: (row, type) => {
            if(type === 'row'){
                if(row.created_at   != ' ') {
                    var date = new Date(row.created_at);
                    return date.toLocaleDateString()
                  }
            } else {
              return row;
            }
          }
        },
        { 
          title: "Total Payment", 
          field: "Total Payment",
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          render: (row, type) => {
            if(type === 'row'){
              return (row.payment_amount   != ' ') ? HtmlParser(row.payment_amount) : '';
             } else {
              return row;
            }
          }
        },
        { 
            title: "Action",
            field: "action",
            cellStyle: {
              minWidth: 120,
              maxWidth: 120            
            },
            render:(row, type) => {
              const { 
                REACT_APP_AUTH_API_URL
              } = process.env;
              if(type === 'row'){
                return  <a  
                  target="_blank"
                  href={ REACT_APP_AUTH_API_URL+"application_payment_records_pdf/" + row.paypal_payment_id + ".pdf"}
                  style={{ textDecoration: 'none' }}
                  ><FontAwesomeIcon icon={faFilePdf} 
                  style={{
                    color: '#ffffff',
                    backgroundColor: '#99CCFF',
                    padding:'4px',
                    fontSize:'32px',
                    borderRadius:'3px'
                  }}/></a>;
              } else {
                return row;
              }
            }
          },
      ]}
    />
  }

  render() {
    return <Box className="dashboard-page">
      <Card className={'job-listing-step'}>
          <h1>List of receipts</h1>
        {this.customTable()}
      </Card>
    </Box>;
  }
}

export default PaymentHistory;
