export class CountryDataService {

  constructor() { }

  getAll(){
  	return this.country_data;
  }

  getPhoneCode(){
    return this.countries;
  }

  /// DATA REFERENCE FROM RUBY (TZInfo::Country)
  /// COUNTRY CODE FOLLOW STANDART ISO 3166-1 alpha-2 country code.
  country_data = [
    { "name": "Andorra", "code": "AD", "identifier": "Europe/Andorra", "offset": "+0200" },
    { "name": "United Arab Emirates", "code": "AE", "identifier": "Asia/Dubai", "offset": "+0400" },
    { "name": "Afghanistan", "code": "AF", "identifier": "Asia/Kabul", "offset": "+0430" },
    { "name": "Antigua \u0026 Barbuda", "code": "AG", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Anguilla", "code": "AI", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Albania", "code": "AL", "identifier": "Europe/Tirane", "offset": "+0200" },
    { "name": "Armenia", "code": "AM", "identifier": "Asia/Yerevan", "offset": "+0400" },
    { "name": "Angola", "code": "AO", "identifier": "Africa/Lagos", "offset": "+0100" },
    { "name": "Antarctica", "code": "AQ", "identifier": "Antarctica/Casey", "offset": "+0800" },
    { "name": "Antarctica", "code": "AQ", "identifier": "Antarctica/Davis", "offset": "+0700" },
    { "name": "Antarctica", "code": "AQ", "identifier": "Antarctica/DumontDUrville", "offset": "+1000" },
    { "name": "Antarctica", "code": "AQ", "identifier": "Antarctica/Mawson", "offset": "+0500" },
    { "name": "Antarctica", "code": "AQ", "identifier": "Antarctica/Palmer", "offset": "-0400" },
    { "name": "Antarctica", "code": "AQ", "identifier": "Antarctica/Rothera", "offset": "-0300" },
    { "name": "Antarctica", "code": "AQ", "identifier": "Antarctica/Syowa", "offset": "+0300" },
    { "name": "Antarctica", "code": "AQ", "identifier": "Antarctica/Troll", "offset": "+0200" },
    { "name": "Antarctica", "code": "AQ", "identifier": "Antarctica/Vostok", "offset": "+0600" },
    { "name": "Antarctica", "code": "AQ", "identifier": "Pacific/Auckland", "offset": "+1200" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/Buenos_Aires", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/Cordoba", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/Salta", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/Jujuy", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/Tucuman", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/Catamarca", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/La_Rioja", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/San_Juan", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/Mendoza", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/San_Luis", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/Rio_Gallegos", "offset": "-0300" },
    { "name": "Argentina", "code": "AR", "identifier": "America/Argentina/Ushuaia", "offset": "-0300" },
    { "name": "Samoa (American)", "code": "AS", "identifier": "Pacific/Pago_Pago", "offset": "-1100" },
    { "name": "Austria", "code": "AT", "identifier": "Europe/Vienna", "offset": "+0200" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Lord_Howe", "offset": "+1030" },
    { "name": "Australia", "code": "AU", "identifier": "Antarctica/Macquarie", "offset": "+1100" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Hobart", "offset": "+1000" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Currie", "offset": "+1000" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Melbourne", "offset": "+1000" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Sydney", "offset": "+1000" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Broken_Hill", "offset": "+0930" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Brisbane", "offset": "+1000" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Lindeman", "offset": "+1000" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Adelaide", "offset": "+0930" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Darwin", "offset": "+0930" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Perth", "offset": "+0800" },
    { "name": "Australia", "code": "AU", "identifier": "Australia/Eucla", "offset": "+0845" },
    { "name": "Aruba", "code": "AW", "identifier": "America/Curacao", "offset": "-0400" },
    { "name": "Åland Islands", "code": "AX", "identifier": "Europe/Helsinki", "offset": "+0300" },
    { "name": "Azerbaijan", "code": "AZ", "identifier": "Asia/Baku", "offset": "+0400" },
    { "name": "Bosnia \u0026 Herzegovina", "code": "BA", "identifier": "Europe/Belgrade", "offset": "+0200" },
    { "name": "Barbados", "code": "BB", "identifier": "America/Barbados", "offset": "-0400" },
    { "name": "Bangladesh", "code": "BD", "identifier": "Asia/Dhaka", "offset": "+0600" },
    { "name": "Belgium", "code": "BE", "identifier": "Europe/Brussels", "offset": "+0200" },
    { "name": "Burkina Faso", "code": "BF", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "Bulgaria", "code": "BG", "identifier": "Europe/Sofia", "offset": "+0300" },
    { "name": "Bahrain", "code": "BH", "identifier": "Asia/Qatar", "offset": "+0300" },
    { "name": "Burundi", "code": "BI", "identifier": "Africa/Maputo", "offset": "+0200" },
    { "name": "Benin", "code": "BJ", "identifier": "Africa/Lagos", "offset": "+0100" },
    { "name": "St Barthelemy", "code": "BL", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Bermuda", "code": "BM", "identifier": "Atlantic/Bermuda", "offset": "-0300" },
    { "name": "Brunei", "code": "BN", "identifier": "Asia/Brunei", "offset": "+0800" },
    { "name": "Bolivia", "code": "BO", "identifier": "America/La_Paz", "offset": "-0400" },
    { "name": "Caribbean NL", "code": "BQ", "identifier": "America/Curacao", "offset": "-0400" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Noronha", "offset": "-0200" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Belem", "offset": "-0300" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Fortaleza", "offset": "-0300" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Recife", "offset": "-0300" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Araguaina", "offset": "-0300" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Maceio", "offset": "-0300" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Bahia", "offset": "-0300" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Sao_Paulo", "offset": "-0300" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Campo_Grande", "offset": "-0400" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Cuiaba", "offset": "-0400" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Santarem", "offset": "-0300" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Porto_Velho", "offset": "-0400" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Boa_Vista", "offset": "-0400" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Manaus", "offset": "-0400" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Eirunepe", "offset": "-0500" },
    { "name": "Brazil", "code": "BR", "identifier": "America/Rio_Branco", "offset": "-0500" },
    { "name": "Bahamas", "code": "BS", "identifier": "America/Nassau", "offset": "-0400" },
    { "name": "Bhutan", "code": "BT", "identifier": "Asia/Thimphu", "offset": "+0600" },
    { "name": "Botswana", "code": "BW", "identifier": "Africa/Maputo", "offset": "+0200" },
    { "name": "Belarus", "code": "BY", "identifier": "Europe/Minsk", "offset": "+0300" },
    { "name": "Belize", "code": "BZ", "identifier": "America/Belize", "offset": "-0600" },
    { "name": "Canada", "code": "CA", "identifier": "America/St_Johns", "offset": "-0230" },
    { "name": "Canada", "code": "CA", "identifier": "America/Halifax", "offset": "-0300" },
    { "name": "Canada", "code": "CA", "identifier": "America/Glace_Bay", "offset": "-0300" },
    { "name": "Canada", "code": "CA", "identifier": "America/Moncton", "offset": "-0300" },
    { "name": "Canada", "code": "CA", "identifier": "America/Goose_Bay", "offset": "-0300" },
    { "name": "Canada", "code": "CA", "identifier": "America/Blanc-Sablon", "offset": "-0400" },
    { "name": "Canada", "code": "CA", "identifier": "America/Toronto", "offset": "-0400" },
    { "name": "Canada", "code": "CA", "identifier": "America/Nipigon", "offset": "-0400" },
    { "name": "Canada", "code": "CA", "identifier": "America/Thunder_Bay", "offset": "-0400" },
    { "name": "Canada", "code": "CA", "identifier": "America/Iqaluit", "offset": "-0400" },
    { "name": "Canada", "code": "CA", "identifier": "America/Pangnirtung", "offset": "-0400" },
    { "name": "Canada", "code": "CA", "identifier": "America/Atikokan", "offset": "-0500" },
    { "name": "Canada", "code": "CA", "identifier": "America/Winnipeg", "offset": "-0500" },
    { "name": "Canada", "code": "CA", "identifier": "America/Rainy_River", "offset": "-0500" },
    { "name": "Canada", "code": "CA", "identifier": "America/Resolute", "offset": "-0500" },
    { "name": "Canada", "code": "CA", "identifier": "America/Rankin_Inlet", "offset": "-0500" },
    { "name": "Canada", "code": "CA", "identifier": "America/Regina", "offset": "-0600" },
    { "name": "Canada", "code": "CA", "identifier": "America/Swift_Current", "offset": "-0600" },
    { "name": "Canada", "code": "CA", "identifier": "America/Edmonton", "offset": "-0600" },
    { "name": "Canada", "code": "CA", "identifier": "America/Cambridge_Bay", "offset": "-0600" },
    { "name": "Canada", "code": "CA", "identifier": "America/Yellowknife", "offset": "-0600" },
    { "name": "Canada", "code": "CA", "identifier": "America/Inuvik", "offset": "-0600" },
    { "name": "Canada", "code": "CA", "identifier": "America/Creston", "offset": "-0700" },
    { "name": "Canada", "code": "CA", "identifier": "America/Dawson_Creek", "offset": "-0700" },
    { "name": "Canada", "code": "CA", "identifier": "America/Fort_Nelson", "offset": "-0700" },
    { "name": "Canada", "code": "CA", "identifier": "America/Vancouver", "offset": "-0700" },
    { "name": "Canada", "code": "CA", "identifier": "America/Whitehorse", "offset": "-0700" },
    { "name": "Canada", "code": "CA", "identifier": "America/Dawson", "offset": "-0700" },
    { "name": "Cocos (Keeling) Islands", "code": "CC", "identifier": "Indian/Cocos", "offset": "+0630" },
    { "name": "Congo (Dem. Rep.)", "code": "CD", "identifier": "Africa/Maputo", "offset": "+0200" },
    { "name": "Congo (Dem. Rep.)", "code": "CD", "identifier": "Africa/Lagos", "offset": "+0100" },
    { "name": "Central African Rep.", "code": "CF", "identifier": "Africa/Lagos", "offset": "+0100" },
    { "name": "Congo (Rep.)", "code": "CG", "identifier": "Africa/Lagos", "offset": "+0100" },
    { "name": "Switzerland", "code": "CH", "identifier": "Europe/Zurich", "offset": "+0200" },
    { "name": "Côte d'Ivoire", "code": "CI", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "Cook Islands", "code": "CK", "identifier": "Pacific/Rarotonga", "offset": "-1000" },
    { "name": "Chile", "code": "CL", "identifier": "America/Santiago", "offset": "-0400" },
    { "name": "Chile", "code": "CL", "identifier": "Pacific/Easter", "offset": "-0600" },
    { "name": "Cameroon", "code": "CM", "identifier": "Africa/Lagos", "offset": "+0100" },
    { "name": "China", "code": "CN", "identifier": "Asia/Shanghai", "offset": "+0800" },
    { "name": "China", "code": "CN", "identifier": "Asia/Urumqi", "offset": "+0600" },
    { "name": "Colombia", "code": "CO", "identifier": "America/Bogota", "offset": "-0500" },
    { "name": "Costa Rica", "code": "CR", "identifier": "America/Costa_Rica", "offset": "-0600" },
    { "name": "Cuba", "code": "CU", "identifier": "America/Havana", "offset": "-0400" },
    { "name": "Cape Verde", "code": "CV", "identifier": "Atlantic/Cape_Verde", "offset": "-0100" },
    { "name": "Curacao", "code": "CW", "identifier": "America/Curacao", "offset": "-0400" },
    { "name": "Christmas Island", "code": "CX", "identifier": "Indian/Christmas", "offset": "+0700" },
    { "name": "Cyprus", "code": "CY", "identifier": "Asia/Nicosia", "offset": "+0300" },
    { "name": "Czech Republic", "code": "CZ", "identifier": "Europe/Prague", "offset": "+0200" },
    { "name": "Germany", "code": "DE", "identifier": "Europe/Berlin", "offset": "+0200" },
    { "name": "Germany", "code": "DE", "identifier": "Europe/Zurich", "offset": "+0200" },
    { "name": "Djibouti", "code": "DJ", "identifier": "Africa/Nairobi", "offset": "+0300" },
    { "name": "Denmark", "code": "DK", "identifier": "Europe/Copenhagen", "offset": "+0200" },
    { "name": "Dominica", "code": "DM", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Dominican Republic", "code": "DO", "identifier": "America/Santo_Domingo", "offset": "-0400" },
    { "name": "Algeria", "code": "DZ", "identifier": "Africa/Algiers", "offset": "+0100" },
    { "name": "Ecuador", "code": "EC", "identifier": "America/Guayaquil", "offset": "-0500" },
    { "name": "Ecuador", "code": "EC", "identifier": "Pacific/Galapagos", "offset": "-0600" },
    { "name": "Estonia", "code": "EE", "identifier": "Europe/Tallinn", "offset": "+0300" },
    { "name": "Egypt", "code": "EG", "identifier": "Africa/Cairo", "offset": "+0200" },
    { "name": "Western Sahara", "code": "EH", "identifier": "Africa/El_Aaiun", "offset": "+0100" },
    { "name": "Eritrea", "code": "ER", "identifier": "Africa/Nairobi", "offset": "+0300" },
    { "name": "Spain", "code": "ES", "identifier": "Europe/Madrid", "offset": "+0200" },
    { "name": "Spain", "code": "ES", "identifier": "Africa/Ceuta", "offset": "+0200" },
    { "name": "Spain", "code": "ES", "identifier": "Atlantic/Canary", "offset": "+0100" },
    { "name": "Ethiopia", "code": "ET", "identifier": "Africa/Nairobi", "offset": "+0300" },
    { "name": "Finland", "code": "FI", "identifier": "Europe/Helsinki", "offset": "+0300" },
    { "name": "Fiji", "code": "FJ", "identifier": "Pacific/Fiji", "offset": "+1200" },
    { "name": "Falkland Islands", "code": "FK", "identifier": "Atlantic/Stanley", "offset": "-0300" },
    { "name": "Micronesia", "code": "FM", "identifier": "Pacific/Chuuk", "offset": "+1000" },
    { "name": "Micronesia", "code": "FM", "identifier": "Pacific/Pohnpei", "offset": "+1100" },
    { "name": "Micronesia", "code": "FM", "identifier": "Pacific/Kosrae", "offset": "+1100" },
    { "name": "Faroe Islands", "code": "FO", "identifier": "Atlantic/Faroe", "offset": "+0100" },
    { "name": "France", "code": "FR", "identifier": "Europe/Paris", "offset": "+0200" },
    { "name": "Gabon", "code": "GA", "identifier": "Africa/Lagos", "offset": "+0100" },
    { "name": "Britain (UK)", "code": "GB", "identifier": "Europe/London", "offset": "+0100" },
    { "name": "Grenada", "code": "GD", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Georgia", "code": "GE", "identifier": "Asia/Tbilisi", "offset": "+0400" },
    { "name": "French Guiana", "code": "GF", "identifier": "America/Cayenne", "offset": "-0300" },
    { "name": "Guernsey", "code": "GG", "identifier": "Europe/London", "offset": "+0100" },
    { "name": "Ghana", "code": "GH", "identifier": "Africa/Accra", "offset": "+0000" },
    { "name": "Gibraltar", "code": "GI", "identifier": "Europe/Gibraltar", "offset": "+0200" },
    { "name": "Greenland", "code": "GL", "identifier": "America/Godthab", "offset": "-0200" },
    { "name": "Greenland", "code": "GL", "identifier": "America/Danmarkshavn", "offset": "+0000" },
    { "name": "Greenland", "code": "GL", "identifier": "America/Scoresbysund", "offset": "+0000" },
    { "name": "Greenland", "code": "GL", "identifier": "America/Thule", "offset": "-0300" },
    { "name": "Gambia", "code": "GM", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "Guinea", "code": "GN", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "Guadeloupe", "code": "GP", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Equatorial Guinea", "code": "GQ", "identifier": "Africa/Lagos", "offset": "+0100" },
    { "name": "Greece", "code": "GR", "identifier": "Europe/Athens", "offset": "+0300" },
    { "name": "South Georgia \u0026 the South Sandwich Islands", "code": "GS", "identifier": "Atlantic/South_Georgia", "offset": "-0200" },
    { "name": "Guatemala", "code": "GT", "identifier": "America/Guatemala", "offset": "-0600" },
    { "name": "Guam", "code": "GU", "identifier": "Pacific/Guam", "offset": "+1000" },
    { "name": "Guinea-Bissau", "code": "GW", "identifier": "Africa/Bissau", "offset": "+0000" },
    { "name": "Guyana", "code": "GY", "identifier": "America/Guyana", "offset": "-0400" },
    { "name": "Hong Kong", "code": "HK", "identifier": "Asia/Hong_Kong", "offset": "+0800" },
    { "name": "Honduras", "code": "HN", "identifier": "America/Tegucigalpa", "offset": "-0600" },
    { "name": "Croatia", "code": "HR", "identifier": "Europe/Belgrade", "offset": "+0200" },
    { "name": "Haiti", "code": "HT", "identifier": "America/Port-au-Prince", "offset": "-0500" },
    { "name": "Hungary", "code": "HU", "identifier": "Europe/Budapest", "offset": "+0200" },
    { "name": "Indonesia", "code": "ID", "identifier": "Asia/Jakarta", "offset": "+0700" },
    { "name": "Indonesia", "code": "ID", "identifier": "Asia/Pontianak", "offset": "+0700" },
    { "name": "Indonesia", "code": "ID", "identifier": "Asia/Makassar", "offset": "+0800" },
    { "name": "Indonesia", "code": "ID", "identifier": "Asia/Jayapura", "offset": "+0900" },
    { "name": "Ireland", "code": "IE", "identifier": "Europe/Dublin", "offset": "+0100" },
    { "name": "Israel", "code": "IL", "identifier": "Asia/Jerusalem", "offset": "+0300" },
    { "name": "Isle of Man", "code": "IM", "identifier": "Europe/London", "offset": "+0100" },
    { "name": "India", "code": "IN", "identifier": "Asia/Kolkata", "offset": "+0530" },
    { "name": "British Indian Ocean Territory", "code": "IO", "identifier": "Indian/Chagos", "offset": "+0600" },
    { "name": "Iraq", "code": "IQ", "identifier": "Asia/Baghdad", "offset": "+0300" },
    { "name": "Iran", "code": "IR", "identifier": "Asia/Tehran", "offset": "+0430" },
    { "name": "Iceland", "code": "IS", "identifier": "Atlantic/Reykjavik", "offset": "+0000" },
    { "name": "Italy", "code": "IT", "identifier": "Europe/Rome", "offset": "+0200" },
    { "name": "Jersey", "code": "JE", "identifier": "Europe/London", "offset": "+0100" },
    { "name": "Jamaica", "code": "JM", "identifier": "America/Jamaica", "offset": "-0500" },
    { "name": "Jordan", "code": "JO", "identifier": "Asia/Amman", "offset": "+0300" },
    { "name": "Japan", "code": "JP", "identifier": "Asia/Tokyo", "offset": "+0900" },
    { "name": "Kenya", "code": "KE", "identifier": "Africa/Nairobi", "offset": "+0300" },
    { "name": "Kyrgyzstan", "code": "KG", "identifier": "Asia/Bishkek", "offset": "+0600" },
    { "name": "Cambodia", "code": "KH", "identifier": "Asia/Bangkok", "offset": "+0700" },
    { "name": "Kiribati", "code": "KI", "identifier": "Pacific/Tarawa", "offset": "+1200" },
    { "name": "Kiribati", "code": "KI", "identifier": "Pacific/Enderbury", "offset": "+1300" },
    { "name": "Kiribati", "code": "KI", "identifier": "Pacific/Kiritimati", "offset": "+1400" },
    { "name": "Comoros", "code": "KM", "identifier": "Africa/Nairobi", "offset": "+0300" },
    { "name": "St Kitts \u0026 Nevis", "code": "KN", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Korea (North)", "code": "KP", "identifier": "Asia/Pyongyang", "offset": "+0830" },
    { "name": "Korea (South)", "code": "KR", "identifier": "Asia/Seoul", "offset": "+0900" },
    { "name": "Kuwait", "code": "KW", "identifier": "Asia/Riyadh", "offset": "+0300" },
    { "name": "Cayman Islands", "code": "KY", "identifier": "America/Panama", "offset": "-0500" },
    { "name": "Kazakhstan", "code": "KZ", "identifier": "Asia/Almaty", "offset": "+0600" },
    { "name": "Kazakhstan", "code": "KZ", "identifier": "Asia/Qyzylorda", "offset": "+0600" },
    { "name": "Kazakhstan", "code": "KZ", "identifier": "Asia/Aqtobe", "offset": "+0500" },
    { "name": "Kazakhstan", "code": "KZ", "identifier": "Asia/Aqtau", "offset": "+0500" },
    { "name": "Kazakhstan", "code": "KZ", "identifier": "Asia/Oral", "offset": "+0500" },
    { "name": "Laos", "code": "LA", "identifier": "Asia/Bangkok", "offset": "+0700" },
    { "name": "Lebanon", "code": "LB", "identifier": "Asia/Beirut", "offset": "+0300" },
    { "name": "St Lucia", "code": "LC", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Liechtenstein", "code": "LI", "identifier": "Europe/Zurich", "offset": "+0200" },
    { "name": "Sri Lanka", "code": "LK", "identifier": "Asia/Colombo", "offset": "+0530" },
    { "name": "Liberia", "code": "LR", "identifier": "Africa/Monrovia", "offset": "+0000" },
    { "name": "Lesotho", "code": "LS", "identifier": "Africa/Johannesburg", "offset": "+0200" },
    { "name": "Lithuania", "code": "LT", "identifier": "Europe/Vilnius", "offset": "+0300" },
    { "name": "Luxembourg", "code": "LU", "identifier": "Europe/Luxembourg", "offset": "+0200" },
    { "name": "Latvia", "code": "LV", "identifier": "Europe/Riga", "offset": "+0300" },
    { "name": "Libya", "code": "LY", "identifier": "Africa/Tripoli", "offset": "+0200" },
    { "name": "Morocco", "code": "MA", "identifier": "Africa/Casablanca", "offset": "+0100" },
    { "name": "Monaco", "code": "MC", "identifier": "Europe/Monaco", "offset": "+0200" },
    { "name": "Moldova", "code": "MD", "identifier": "Europe/Chisinau", "offset": "+0300" },
    { "name": "Montenegro", "code": "ME", "identifier": "Europe/Belgrade", "offset": "+0200" },
    { "name": "St Martin (French)", "code": "MF", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Madagascar", "code": "MG", "identifier": "Africa/Nairobi", "offset": "+0300" },
    { "name": "Marshall Islands", "code": "MH", "identifier": "Pacific/Majuro", "offset": "+1200" },
    { "name": "Marshall Islands", "code": "MH", "identifier": "Pacific/Kwajalein", "offset": "+1200" },
    { "name": "Macedonia", "code": "MK", "identifier": "Europe/Belgrade", "offset": "+0200" },
    { "name": "Mali", "code": "ML", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "Myanmar (Burma)", "code": "MM", "identifier": "Asia/Rangoon", "offset": "+0630" },
    { "name": "Mongolia", "code": "MN", "identifier": "Asia/Ulaanbaatar", "offset": "+0900" },
    { "name": "Mongolia", "code": "MN", "identifier": "Asia/Hovd", "offset": "+0800" },
    { "name": "Mongolia", "code": "MN", "identifier": "Asia/Choibalsan", "offset": "+0900" },
    { "name": "Macau", "code": "MO", "identifier": "Asia/Macau", "offset": "+0800" },
    { "name": "Northern Mariana Islands", "code": "MP", "identifier": "Pacific/Guam", "offset": "+1000" },
    { "name": "Martinique", "code": "MQ", "identifier": "America/Martinique", "offset": "-0400" },
    { "name": "Mauritania", "code": "MR", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "Montserrat", "code": "MS", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Malta", "code": "MT", "identifier": "Europe/Malta", "offset": "+0200" },
    { "name": "Mauritius", "code": "MU", "identifier": "Indian/Mauritius", "offset": "+0400" },
    { "name": "Maldives", "code": "MV", "identifier": "Indian/Maldives", "offset": "+0500" },
    { "name": "Malawi", "code": "MW", "identifier": "Africa/Maputo", "offset": "+0200" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Mexico_City", "offset": "-0500" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Cancun", "offset": "-0500" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Merida", "offset": "-0500" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Monterrey", "offset": "-0500" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Matamoros", "offset": "-0500" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Mazatlan", "offset": "-0600" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Chihuahua", "offset": "-0600" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Ojinaga", "offset": "-0600" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Hermosillo", "offset": "-0700" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Tijuana", "offset": "-0700" },
    { "name": "Mexico", "code": "MX", "identifier": "America/Bahia_Banderas", "offset": "-0500" },
    { "name": "Malaysia", "code": "MY", "identifier": "Asia/Kuala_Lumpur", "offset": "+0800" },
    { "name": "Malaysia", "code": "MY", "identifier": "Asia/Kuching", "offset": "+0800" },
    { "name": "Mozambique", "code": "MZ", "identifier": "Africa/Maputo", "offset": "+0200" },
    { "name": "Namibia", "code": "NA", "identifier": "Africa/Windhoek", "offset": "+0100" },
    { "name": "New Caledonia", "code": "NC", "identifier": "Pacific/Noumea", "offset": "+1100" },
    { "name": "Niger", "code": "NE", "identifier": "Africa/Lagos", "offset": "+0100" },
    { "name": "Norfolk Island", "code": "NF", "identifier": "Pacific/Norfolk", "offset": "+1100" },
    { "name": "Nigeria", "code": "NG", "identifier": "Africa/Lagos", "offset": "+0100" },
    { "name": "Nicaragua", "code": "NI", "identifier": "America/Managua", "offset": "-0600" },
    { "name": "Netherlands", "code": "NL", "identifier": "Europe/Amsterdam", "offset": "+0200" },
    { "name": "Norway", "code": "NO", "identifier": "Europe/Oslo", "offset": "+0200" },
    { "name": "Nepal", "code": "NP", "identifier": "Asia/Kathmandu", "offset": "+0545" },
    { "name": "Nauru", "code": "NR", "identifier": "Pacific/Nauru", "offset": "+1200" },
    { "name": "Niue", "code": "NU", "identifier": "Pacific/Niue", "offset": "-1100" },
    { "name": "New Zealand", "code": "NZ", "identifier": "Pacific/Auckland", "offset": "+1200" },
    { "name": "New Zealand", "code": "NZ", "identifier": "Pacific/Chatham", "offset": "+1245" },
    { "name": "Oman", "code": "OM", "identifier": "Asia/Dubai", "offset": "+0400" },
    { "name": "Panama", "code": "PA", "identifier": "America/Panama", "offset": "-0500" },
    { "name": "Peru", "code": "PE", "identifier": "America/Lima", "offset": "-0500" },
    { "name": "French Polynesia", "code": "PF", "identifier": "Pacific/Tahiti", "offset": "-1000" },
    { "name": "French Polynesia", "code": "PF", "identifier": "Pacific/Marquesas", "offset": "-0930" },
    { "name": "French Polynesia", "code": "PF", "identifier": "Pacific/Gambier", "offset": "-0900" },
    { "name": "Papua New Guinea", "code": "PG", "identifier": "Pacific/Port_Moresby", "offset": "+1000" },
    { "name": "Papua New Guinea", "code": "PG", "identifier": "Pacific/Bougainville", "offset": "+1100" },
    { "name": "Philippines", "code": "PH", "identifier": "Asia/Manila", "offset": "+0800" },
    { "name": "Pakistan", "code": "PK", "identifier": "Asia/Karachi", "offset": "+0500" },
    { "name": "Poland", "code": "PL", "identifier": "Europe/Warsaw", "offset": "+0200" },
    { "name": "St Pierre \u0026 Miquelon", "code": "PM", "identifier": "America/Miquelon", "offset": "-0200" },
    { "name": "Pitcairn", "code": "PN", "identifier": "Pacific/Pitcairn", "offset": "-0800" },
    { "name": "Puerto Rico", "code": "PR", "identifier": "America/Puerto_Rico", "offset": "-0400" },
    { "name": "Palestine", "code": "PS", "identifier": "Asia/Gaza", "offset": "+0300" },
    { "name": "Palestine", "code": "PS", "identifier": "Asia/Hebron", "offset": "+0300" },
    { "name": "Portugal", "code": "PT", "identifier": "Europe/Lisbon", "offset": "+0100" },
    { "name": "Portugal", "code": "PT", "identifier": "Atlantic/Madeira", "offset": "+0100" },
    { "name": "Portugal", "code": "PT", "identifier": "Atlantic/Azores", "offset": "+0000" },
    { "name": "Palau", "code": "PW", "identifier": "Pacific/Palau", "offset": "+0900" },
    { "name": "Paraguay", "code": "PY", "identifier": "America/Asuncion", "offset": "-0400" },
    { "name": "Qatar", "code": "QA", "identifier": "Asia/Qatar", "offset": "+0300" },
    { "name": "Réunion", "code": "RE", "identifier": "Indian/Reunion", "offset": "+0400" },
    { "name": "Romania", "code": "RO", "identifier": "Europe/Bucharest", "offset": "+0300" },
    { "name": "Serbia", "code": "RS", "identifier": "Europe/Belgrade", "offset": "+0200" },
    { "name": "Russia", "code": "RU", "identifier": "Europe/Kaliningrad", "offset": "+0200" },
    { "name": "Russia", "code": "RU", "identifier": "Europe/Moscow", "offset": "+0300" },
    { "name": "Russia", "code": "RU", "identifier": "Europe/Simferopol", "offset": "+0300" },
    { "name": "Russia", "code": "RU", "identifier": "Europe/Volgograd", "offset": "+0300" },
    { "name": "Russia", "code": "RU", "identifier": "Europe/Kirov", "offset": "+0300" },
    { "name": "Russia", "code": "RU", "identifier": "Europe/Astrakhan", "offset": "+0400" },
    { "name": "Russia", "code": "RU", "identifier": "Europe/Samara", "offset": "+0400" },
    { "name": "Russia", "code": "RU", "identifier": "Europe/Ulyanovsk", "offset": "+0400" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Yekaterinburg", "offset": "+0500" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Omsk", "offset": "+0600" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Novosibirsk", "offset": "+0700" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Barnaul", "offset": "+0700" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Tomsk", "offset": "+0700" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Novokuznetsk", "offset": "+0700" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Krasnoyarsk", "offset": "+0700" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Irkutsk", "offset": "+0800" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Chita", "offset": "+0900" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Yakutsk", "offset": "+0900" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Khandyga", "offset": "+0900" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Vladivostok", "offset": "+1000" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Ust-Nera", "offset": "+1000" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Magadan", "offset": "+1100" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Sakhalin", "offset": "+1100" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Srednekolymsk", "offset": "+1100" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Kamchatka", "offset": "+1200" },
    { "name": "Russia", "code": "RU", "identifier": "Asia/Anadyr", "offset": "+1200" },
    { "name": "Rwanda", "code": "RW", "identifier": "Africa/Maputo", "offset": "+0200" },
    { "name": "Saudi Arabia", "code": "SA", "identifier": "Asia/Riyadh", "offset": "+0300" },
    { "name": "Solomon Islands", "code": "SB", "identifier": "Pacific/Guadalcanal", "offset": "+1100" },
    { "name": "Seychelles", "code": "SC", "identifier": "Indian/Mahe", "offset": "+0400" },
    { "name": "Sudan", "code": "SD", "identifier": "Africa/Khartoum", "offset": "+0300" },
    { "name": "Sweden", "code": "SE", "identifier": "Europe/Stockholm", "offset": "+0200" },
    { "name": "Singapore", "code": "SG", "identifier": "Asia/Singapore", "offset": "+0800" },
    { "name": "St Helena", "code": "SH", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "Slovenia", "code": "SI", "identifier": "Europe/Belgrade", "offset": "+0200" },
    { "name": "Svalbard \u0026 Jan Mayen", "code": "SJ", "identifier": "Europe/Oslo", "offset": "+0200" },
    { "name": "Slovakia", "code": "SK", "identifier": "Europe/Prague", "offset": "+0200" },
    { "name": "Sierra Leone", "code": "SL", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "San Marino", "code": "SM", "identifier": "Europe/Rome", "offset": "+0200" },
    { "name": "Senegal", "code": "SN", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "Somalia", "code": "SO", "identifier": "Africa/Nairobi", "offset": "+0300" },
    { "name": "Suriname", "code": "SR", "identifier": "America/Paramaribo", "offset": "-0300" },
    { "name": "South Sudan", "code": "SS", "identifier": "Africa/Khartoum", "offset": "+0300" },
    { "name": "Sao Tome \u0026 Principe", "code": "ST", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "El Salvador", "code": "SV", "identifier": "America/El_Salvador", "offset": "-0600" },
    { "name": "St Maarten (Dutch)", "code": "SX", "identifier": "America/Curacao", "offset": "-0400" },
    { "name": "Syria", "code": "SY", "identifier": "Asia/Damascus", "offset": "+0300" },
    { "name": "Swaziland", "code": "SZ", "identifier": "Africa/Johannesburg", "offset": "+0200" },
    { "name": "Turks \u0026 Caicos Is", "code": "TC", "identifier": "America/Grand_Turk", "offset": "-0400" },
    { "name": "Chad", "code": "TD", "identifier": "Africa/Ndjamena", "offset": "+0100" },
    { "name": "French Southern \u0026 Antarctic Lands", "code": "TF", "identifier": "Indian/Kerguelen", "offset": "+0500" },
    { "name": "French Southern \u0026 Antarctic Lands", "code": "TF", "identifier": "Indian/Reunion", "offset": "+0400" },
    { "name": "Togo", "code": "TG", "identifier": "Africa/Abidjan", "offset": "+0000" },
    { "name": "Thailand", "code": "TH", "identifier": "Asia/Bangkok", "offset": "+0700" },
    { "name": "Tajikistan", "code": "TJ", "identifier": "Asia/Dushanbe", "offset": "+0500" },
    { "name": "Tokelau", "code": "TK", "identifier": "Pacific/Fakaofo", "offset": "+1300" },
    { "name": "East Timor", "code": "TL", "identifier": "Asia/Dili", "offset": "+0900" },
    { "name": "Turkmenistan", "code": "TM", "identifier": "Asia/Ashgabat", "offset": "+0500" },
    { "name": "Tunisia", "code": "TN", "identifier": "Africa/Tunis", "offset": "+0100" },
    { "name": "Tonga", "code": "TO", "identifier": "Pacific/Tongatapu", "offset": "+1300" },
    { "name": "Turkey", "code": "TR", "identifier": "Europe/Istanbul", "offset": "+0300" },
    { "name": "Trinidad \u0026 Tobago", "code": "TT", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Tuvalu", "code": "TV", "identifier": "Pacific/Funafuti", "offset": "+1200" },
    { "name": "Taiwan", "code": "TW", "identifier": "Asia/Taipei", "offset": "+0800" },
    { "name": "Tanzania", "code": "TZ", "identifier": "Africa/Nairobi", "offset": "+0300" },
    { "name": "Ukraine", "code": "UA", "identifier": "Europe/Kiev", "offset": "+0300" },
    { "name": "Ukraine", "code": "UA", "identifier": "Europe/Uzhgorod", "offset": "+0300" },
    { "name": "Ukraine", "code": "UA", "identifier": "Europe/Zaporozhye", "offset": "+0300" },
    { "name": "Uganda", "code": "UG", "identifier": "Africa/Nairobi", "offset": "+0300" },
    { "name": "US minor outlying islands", "code": "UM", "identifier": "Pacific/Wake", "offset": "+1200" },
    { "name": "US minor outlying islands", "code": "UM", "identifier": "Pacific/Pago_Pago", "offset": "-1100" },
    { "name": "US minor outlying islands", "code": "UM", "identifier": "Pacific/Honolulu", "offset": "-1000" },
    { "name": "United States", "code": "US", "identifier": "America/New_York", "offset": "-0400" },
    { "name": "United States", "code": "US", "identifier": "America/Detroit", "offset": "-0400" },
    { "name": "United States", "code": "US", "identifier": "America/Kentucky/Louisville", "offset": "-0400" },
    { "name": "United States", "code": "US", "identifier": "America/Kentucky/Monticello", "offset": "-0400" },
    { "name": "United States", "code": "US", "identifier": "America/Indiana/Indianapolis", "offset": "-0400" },
    { "name": "United States", "code": "US", "identifier": "America/Indiana/Vincennes", "offset": "-0400" },
    { "name": "United States", "code": "US", "identifier": "America/Indiana/Winamac", "offset": "-0400" },
    { "name": "United States", "code": "US", "identifier": "America/Indiana/Marengo", "offset": "-0400" },
    { "name": "United States", "code": "US", "identifier": "America/Indiana/Petersburg", "offset": "-0400" },
    { "name": "United States", "code": "US", "identifier": "America/Indiana/Vevay", "offset": "-0400" },
    { "name": "United States", "code": "US", "identifier": "America/Chicago", "offset": "-0500" },
    { "name": "United States", "code": "US", "identifier": "America/Indiana/Tell_City", "offset": "-0500" },
    { "name": "United States", "code": "US", "identifier": "America/Indiana/Knox", "offset": "-0500" },
    { "name": "United States", "code": "US", "identifier": "America/Menominee", "offset": "-0500" },
    { "name": "United States", "code": "US", "identifier": "America/North_Dakota/Center", "offset": "-0500" },
    { "name": "United States", "code": "US", "identifier": "America/North_Dakota/New_Salem", "offset": "-0500" },
    { "name": "United States", "code": "US", "identifier": "America/North_Dakota/Beulah", "offset": "-0500" },
    { "name": "United States", "code": "US", "identifier": "America/Denver", "offset": "-0600" },
    { "name": "United States", "code": "US", "identifier": "America/Boise", "offset": "-0600" },
    { "name": "United States", "code": "US", "identifier": "America/Phoenix", "offset": "-0700" },
    { "name": "United States", "code": "US", "identifier": "America/Los_Angeles", "offset": "-0700" },
    { "name": "United States", "code": "US", "identifier": "America/Anchorage", "offset": "-0800" },
    { "name": "United States", "code": "US", "identifier": "America/Juneau", "offset": "-0800" },
    { "name": "United States", "code": "US", "identifier": "America/Sitka", "offset": "-0800" },
    { "name": "United States", "code": "US", "identifier": "America/Metlakatla", "offset": "-0800" },
    { "name": "United States", "code": "US", "identifier": "America/Yakutat", "offset": "-0800" },
    { "name": "United States", "code": "US", "identifier": "America/Nome", "offset": "-0800" },
    { "name": "United States", "code": "US", "identifier": "America/Adak", "offset": "-0900" },
    { "name": "United States", "code": "US", "identifier": "Pacific/Honolulu", "offset": "-1000" },
    { "name": "Uruguay", "code": "UY", "identifier": "America/Montevideo", "offset": "-0300" },
    { "name": "Uzbekistan", "code": "UZ", "identifier": "Asia/Samarkand", "offset": "+0500" },
    { "name": "Uzbekistan", "code": "UZ", "identifier": "Asia/Tashkent", "offset": "+0500" },
    { "name": "Vatican City", "code": "VA", "identifier": "Europe/Rome", "offset": "+0200" },
    { "name": "St Vincent", "code": "VC", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Venezuela", "code": "VE", "identifier": "America/Caracas", "offset": "-0400" },
    { "name": "Virgin Islands (UK)", "code": "VG", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Virgin Islands (US)", "code": "VI", "identifier": "America/Port_of_Spain", "offset": "-0400" },
    { "name": "Vietnam", "code": "VN", "identifier": "Asia/Ho_Chi_Minh", "offset": "+0700" },
    { "name": "Vietnam", "code": "VN", "identifier": "Asia/Bangkok", "offset": "+0700" },
    { "name": "Vanuatu", "code": "VU", "identifier": "Pacific/Efate", "offset": "+1100" },
    { "name": "Wallis \u0026 Futuna", "code": "WF", "identifier": "Pacific/Wallis", "offset": "+1200" },
    { "name": "Samoa (western)", "code": "WS", "identifier": "Pacific/Apia", "offset": "+1300" },
    { "name": "Yemen", "code": "YE", "identifier": "Asia/Riyadh", "offset": "+0300" },
    { "name": "Mayotte", "code": "YT", "identifier": "Africa/Nairobi", "offset": "+0300" },
    { "name": "South Africa", "code": "ZA", "identifier": "Africa/Johannesburg", "offset": "+0200" },
    { "name": "Zambia", "code": "ZM", "identifier": "Africa/Maputo", "offset": "+0200" },
    { "name": "Zimbabwe", "code": "ZW", "identifier": "Africa/Maputo", "offset": "+0200" }
  ]

  countries = [
            {
                name: '',
                dialCode: '994',
                countryCode: 'az'
            },
            {
                name: '',
                dialCode: '1242',
                countryCode: 'bs'
            },
            {
                name: '',
                dialCode: '973',
                countryCode: 'bh'
            },
            {
                name: '',
                dialCode: '880',
                countryCode: 'bd'
            },
            {
                name: '',
                dialCode: '1246',
                countryCode: 'bb'
            },
            {
                name: '',
                dialCode: '375',
                countryCode: 'by'
            },
            {
                name: '',
                dialCode: '32',
                countryCode: 'be'
            },
            {
                name: '',
                dialCode: '501',
                countryCode: 'bz'
            },
            {
                name: '',
                dialCode: '229',
                countryCode: 'bj'
            },
            {
                name: '',
                dialCode: '1441',
                countryCode: 'bm'
            },
            {
                name: '',
                dialCode: '975',
                countryCode: 'bt'
            },
            {
                name: '',
                dialCode: '591',
                countryCode: 'bo'
            },
            {
                name: '',
                dialCode: '387',
                countryCode: 'ba'
            },
            {
                name: '',
                dialCode: '267',
                countryCode: 'bw'
            },
            {
                name: '',
                dialCode: '55',
                countryCode: 'br'
            },
            {
                name: '',
                dialCode: '246',
                countryCode: 'io'
            },
            {
                name: '',
                dialCode: '1284',
                countryCode: 'vg'
            },
            {
                name: '',
                dialCode: '673',
                countryCode: 'bn'
            },
            {
                name: '',
                dialCode: '359',
                countryCode: 'bg'
            },
            {
                name: '',
                dialCode: '226',
                countryCode: 'bf'
            },
            {
                name: '',
                dialCode: '257',
                countryCode: 'bi'
            },
            {
                name: '',
                dialCode: '855',
                countryCode: 'kh'
            },
            {
                name: '',
                dialCode: '237',
                countryCode: 'cm'
            },
            {
                name: '',
                dialCode: '1',
                countryCode: 'ca'
            },
            {
                name: '',
                dialCode: '238',
                countryCode: 'cv'
            },
            {
                name: '',
                dialCode: '599',
                countryCode: 'bq'
            },
            {
                name: '',
                dialCode: '1345',
                countryCode: 'ky'
            },
            {
                name: '',
                dialCode: '236',
                countryCode: 'cf'
            },
            {
                name: '',
                dialCode: '235',
                countryCode: 'td'
            },
            {
                name: '',
                dialCode: '56',
                countryCode: 'cl'
            },
            {
                name: '',
                dialCode: '86',
                countryCode: 'cn'
            },
            {
                name: '',
                dialCode: '61',
                countryCode: 'cx'
            },
            {
                name: '',
                dialCode: '57',
                countryCode: 'co'
            },
            {
                name: '',
                dialCode: '269',
                countryCode: 'km'
            },
            {
                name: '',
                dialCode: '243',
                countryCode: 'cd'
            },
            {
                name: '',
                dialCode: '242',
                countryCode: 'cg'
            },
            {
                name: '',
                dialCode: '682',
                countryCode: 'ck'
            },
            {
                name: '',
                dialCode: '506',
                countryCode: 'cr'
            },
            {
                name: '',
                dialCode: '225',
                countryCode: 'ci'
            },
            {
                name: '',
                dialCode: '385',
                countryCode: 'hr'
            },
            {
                name: '',
                dialCode: '53',
                countryCode: 'cu'
            },
            {
                name: '',
                dialCode: '599',
                countryCode: 'cw'
            },
            {
                name: '',
                dialCode: '357',
                countryCode: 'cy'
            },
            {
                name: '',
                dialCode: '420',
                countryCode: 'cz'
            },
            {
                name: '',
                dialCode: '45',
                countryCode: 'dk'
            },
            {
                name: '',
                dialCode: '253',
                countryCode: 'dj'
            },
            {
                name: '',
                dialCode: '1767',
                countryCode: 'dm'
            },
            {
                name: '',
                dialCode: '1',
                countryCode: 'do'
            },
            {
                name: '',
                dialCode: '593',
                countryCode: 'ec'
            },
            {
                name: '',
                dialCode: '20',
                countryCode: 'eg'
            },
            {
                name: '',
                dialCode: '503',
                countryCode: 'sv'
            },
            {
                name: '',
                dialCode: '240',
                countryCode: 'gq'
            },
            {
                name: '',
                dialCode: '291',
                countryCode: 'er'
            },
            {
                name: '',
                dialCode: '372',
                countryCode: 'ee'
            },
            {
                name: '',
                dialCode: '251',
                countryCode: 'et'
            },
            {
                name: '',
                dialCode: '500',
                countryCode: 'fk'
            },
            {
                name: '',
                dialCode: '298',
                countryCode: 'fo'
            },
            {
                name: '',
                dialCode: '679',
                countryCode: 'fj'
            },
            {
                name: '',
                dialCode: '358',
                countryCode: 'fi'
            },
            {
                name: '',
                dialCode: '33',
                countryCode: 'fr'
            },
            {
                name: '',
                dialCode: '594',
                countryCode: 'gf'
            },
            {
                name: '',
                dialCode: '689',
                countryCode: 'pf'
            },
            {
                name: '',
                dialCode: '241',
                countryCode: 'ga'
            },
            {
                name: '',
                dialCode: '220',
                countryCode: 'gm'
            },
            {
                name: '',
                dialCode: '995',
                countryCode: 'ge'
            },
            {
                name: '',
                dialCode: '49',
                countryCode: 'de'
            },
            {
                name: '',
                dialCode: '233',
                countryCode: 'gh'
            },
            {
                name: '',
                dialCode: '350',
                countryCode: 'gi'
            },
            {
                name: '',
                dialCode: '30',
                countryCode: 'gr'
            },
            {
                name: '',
                dialCode: '299',
                countryCode: 'gl'
            },
            {
                name: '',
                dialCode: '1473',
                countryCode: 'gd'
            },
            {
                name: '',
                dialCode: '590',
                countryCode: 'gp'
            },
            {
                name: '',
                dialCode: '1671',
                countryCode: 'gu'
            },
            {
                name: '',
                dialCode: '502',
                countryCode: 'gt'
            },
            {
                name: '',
                dialCode: '44',
                countryCode: 'gg'
            },
            {
                name: '',
                dialCode: '224',
                countryCode: 'gn'
            },
            {
                name: '',
                dialCode: '245',
                countryCode: 'gw'
            },
            {
                name: '',
                dialCode: '592',
                countryCode: 'gy'
            },
            {
                name: '',
                dialCode: '509',
                countryCode: 'ht'
            },
            {
                name: '',
                dialCode: '504',
                countryCode: 'hn'
            },
            {
                name: '',
                dialCode: '852',
                countryCode: 'hk'
            },
            {
                name: '',
                dialCode: '36',
                countryCode: 'hu'
            },
            {
                name: '',
                dialCode: '354',
                countryCode: 'is'
            },
            {
                name: '',
                dialCode: '91',
                countryCode: 'in'
            },
            {
                name: '',
                dialCode: '62',
                countryCode: 'id'
            },
            {
                name: '',
                dialCode: '98',
                countryCode: 'ir'
            },
            {
                name: '',
                dialCode: '964',
                countryCode: 'iq'
            },
            {
                name: '',
                dialCode: '353',
                countryCode: 'ie'
            },
            {
                name: '',
                dialCode: '44',
                countryCode: 'im'
            },
            {
                name: '',
                dialCode: '972',
                countryCode: 'il'
            },
            {
                name: '',
                dialCode: '39',
                countryCode: 'it'
            },
            {
                name: '',
                dialCode: '1876',
                countryCode: 'jm'
            },
            {
                name: '',
                dialCode: '81',
                countryCode: 'jp'
            },
            {
                name: '',
                dialCode: '44',
                countryCode: 'je'
            },
            {
                name: '',
                dialCode: '962',
                countryCode: 'jo'
            },
            {
                name: '',
                dialCode: '7',
                countryCode: 'kz'
            },
            {
                name: '',
                dialCode: '254',
                countryCode: 'ke'
            },
            {
                name: '',
                dialCode: '686',
                countryCode: 'ki'
            },
            {
                name: '',
                dialCode: '383',
                countryCode: 'xk'
            },
            {
                name: '',
                dialCode: '965',
                countryCode: 'kw'
            },
            {
                name: '',
                dialCode: '996',
                countryCode: 'kg'
            },
            {
                name: '',
                dialCode: '856',
                countryCode: 'la'
            },
            {
                name: '',
                dialCode: '371',
                countryCode: 'lv'
            },
            {
                name: '',
                dialCode: '961',
                countryCode: 'lb'
            },
            {
                name: '',
                dialCode: '266',
                countryCode: 'ls'
            },
            {
                name: '',
                dialCode: '231',
                countryCode: 'lr'
            },
            {
                name: '',
                dialCode: '218',
                countryCode: 'ly'
            },
            {
                name: '',
                dialCode: '423',
                countryCode: 'li'
            },
            {
                name: '',
                dialCode: '370',
                countryCode: 'lt'
            },
            {
                name: '',
                dialCode: '352',
                countryCode: 'lu'
            },
            {
                name: '',
                dialCode: '853',
                countryCode: 'mo'
            },
            {
                name: '',
                dialCode: '389',
                countryCode: 'mk'
            },
            {
                name: '',
                dialCode: '261',
                countryCode: 'mg'
            },
            {
                name: '',
                dialCode: '265',
                countryCode: 'mw'
            },
            {
                name: '',
                dialCode: '60',
                countryCode: 'my'
            },
            {
                name: '',
                dialCode: '960',
                countryCode: 'mv'
            },
            {
                name: '',
                dialCode: '223',
                countryCode: 'ml'
            },
            {
                name: '',
                dialCode: '356',
                countryCode: 'mt'
            },
            {
                name: '',
                dialCode: '692',
                countryCode: 'mh'
            },
            {
                name: '',
                dialCode: '596',
                countryCode: 'mq'
            },
            {
                name: '',
                dialCode: '222',
                countryCode: 'mr'
            },
            {
                name: '',
                dialCode: '230',
                countryCode: 'mu'
            },
            {
                name: '',
                dialCode: '262',
                countryCode: 'yt'
            },
            {
                name: '',
                dialCode: '52',
                countryCode: 'mx'
            },
            {
                name: '',
                dialCode: '691',
                countryCode: 'fm'
            },
            {
                name: '',
                dialCode: '373',
                countryCode: 'md'
            },
            {
                name: '',
                dialCode: '377',
                countryCode: 'mc'
            },
            {
                name: '',
                dialCode: '976',
                countryCode: 'mn'
            },
            {
                name: '',
                dialCode: '382',
                countryCode: 'me'
            },
            {
                name: '',
                dialCode: '1664',
                countryCode: 'ms'
            },
            {
                name: '',
                dialCode: '212',
                countryCode: 'ma'
            },
            {
                name: '',
                dialCode: '258',
                countryCode: 'mz'
            },
            {
                name: '',
                dialCode: '95',
                countryCode: 'mm'
            },
            {
                name: '',
                dialCode: '264',
                countryCode: 'na'
            },
            {
                name: '',
                dialCode: '674',
                countryCode: 'nr'
            },
            {
                name: '',
                dialCode: '977',
                countryCode: 'np'
            },
            {
                name: '',
                dialCode: '31',
                countryCode: 'nl'
            },
            {
                name: '',
                dialCode: '687',
                countryCode: 'nc'
            },
            {
                name: '',
                dialCode: '64',
                countryCode: 'nz'
            },
            {
                name: '',
                dialCode: '505',
                countryCode: 'ni'
            },
            {
                name: '',
                dialCode: '227',
                countryCode: 'ne'
            },
            {
                name: '',
                dialCode: '234',
                countryCode: 'ng'
            },
            {
                name: '',
                dialCode: '683',
                countryCode: 'nu'
            },
            {
                name: '',
                dialCode: '672',
                countryCode: 'nf'
            },
            {
                name: '',
                dialCode: '850',
                countryCode: 'kp'
            },
            {
                name: '',
                dialCode: '1670',
                countryCode: 'mp'
            },
            {
                name: '',
                dialCode: '47',
                countryCode: 'no'
            },
            {
                name: '',
                dialCode: '968',
                countryCode: 'om'
            },
            {
                name: '',
                dialCode: '92',
                countryCode: 'pk'
            },
            {
                name: '',
                dialCode: '680',
                countryCode: 'pw'
            },
            {
                name: '',
                dialCode: '970',
                countryCode: 'ps'
            },
            {
                name: '',
                dialCode: '507',
                countryCode: 'pa'
            },
            {
                name: '',
                dialCode: '675',
                countryCode: 'pg'
            },
            {
                name: '',
                dialCode: '595',
                countryCode: 'py'
            },
            {
                name: '',
                dialCode: '51',
                countryCode: 'pe'
            },
            {
                name: '',
                dialCode: '63',
                countryCode: 'ph'
            },
            {
                name: '',
                dialCode: '48',
                countryCode: 'pl'
            },
            {
                name: '',
                dialCode: '351',
                countryCode: 'pt'
            },
            {
                name: '',
                dialCode: '1',
                countryCode: 'pr'
            },
            {
                name: '',
                dialCode: '974',
                countryCode: 'qa'
            },
            {
                name: '',
                dialCode: '262',
                countryCode: 're'
            },
            {
                name: '',
                dialCode: '40',
                countryCode: 'ro'
            },
            {
                name: '',
                dialCode: '7',
                countryCode: 'ru'
            },
            {
                name: '',
                dialCode: '250',
                countryCode: 'rw'
            },
            {
                name: '',
                dialCode: '590',
                countryCode: 'bl'
            },
            {
                name: '',
                dialCode: '290',
                countryCode: 'sh'
            },
            {
                name: '',
                dialCode: '1869',
                countryCode: 'kn'
            },
            {
                name: '',
                dialCode: '1758',
                countryCode: 'lc'
            },
            {
                name: '',
                dialCode: '508',
                countryCode: 'pm'
            },
            {
                name: '',
                dialCode: '1784',
                countryCode: 'vc'
            },
            {
                name: '',
                dialCode: '685',
                countryCode: 'ws'
            },
            {
                name: '',
                dialCode: '378',
                countryCode: 'sm'
            },
            {
                name: '',
                dialCode: '239',
                countryCode: 'st'
            },
            {
                name: '',
                dialCode: '966',
                countryCode: 'sa'
            },
            {
                name: '',
                dialCode: '221',
                countryCode: 'sn'
            },
            {
                name: '',
                dialCode: '381',
                countryCode: 'rs'
            },
            {
                name: '',
                dialCode: '248',
                countryCode: 'sc'
            },
            {
                name: '',
                dialCode: '232',
                countryCode: 'sl'
            },
            {
                name: '',
                dialCode: '65',
                countryCode: 'sg'
            },
            {
                name: '',
                dialCode: '1721',
                countryCode: 'sx'
            },
            {
                name: '',
                dialCode: '421',
                countryCode: 'sk'
            },
            {
                name: '',
                dialCode: '386',
                countryCode: 'si'
            },
            {
                name: '',
                dialCode: '677',
                countryCode: 'sb'
            },
            {
                name: '',
                dialCode: '252',
                countryCode: 'so'
            },
            {
                name: '',
                dialCode: '27',
                countryCode: 'za'
            },
            {
                name: '',
                dialCode: '82',
                countryCode: 'kr'
            },
            {
                name: '',
                dialCode: '211',
                countryCode: 'ss'
            },
            {
                name: '',
                dialCode: '34',
                countryCode: 'es'
            },
            {
                name: '',
                dialCode: '94',
                countryCode: 'lk'
            },
            {
                name: '',
                dialCode: '249',
                countryCode: 'sd'
            },
            {
                name: '',
                dialCode: '597',
                countryCode: 'sr'
            },
            {
                name: '',
                dialCode: '47',
                countryCode: 'sj'
            },
            {
                name: '',
                dialCode: '268',
                countryCode: 'sz'
            },
            {
                name: '',
                dialCode: '46',
                countryCode: 'se'
            },
            {
                name: '',
                dialCode: '41',
                countryCode: 'ch'
            },
            {
                name: '',
                dialCode: '963',
                countryCode: 'sy'
            },
            {
                name: '',
                dialCode: '886',
                countryCode: 'tw'
            },
            {
                name: '',
                dialCode: '992',
                countryCode: 'tj'
            },
            {
                name: '',
                dialCode: '255',
                countryCode: 'tz'
            },
            {
                name: '',
                dialCode: '66',
                countryCode: 'th'
            },
            {
                name: '',
                dialCode: '670',
                countryCode: 'tl'
            },
            {
                name: '',
                dialCode: '228',
                countryCode: 'tg'
            },
            {
                name: '',
                dialCode: '690',
                countryCode: 'tk'
            },
            {
                name: '',
                dialCode: '676',
                countryCode: 'to'
            },
            {
                name: '',
                dialCode: '1868',
                countryCode: 'tt'
            },
            {
                name: '',
                dialCode: '216',
                countryCode: 'tn'
            },
            {
                name: '',
                dialCode: '90',
                countryCode: 'tr'
            },
            {
                name: '',
                dialCode: '993',
                countryCode: 'tm'
            },
            {
                name: '',
                dialCode: '1649',
                countryCode: 'tc'
            },
            {
                name: '',
                dialCode: '688',
                countryCode: 'tv'
            },
            {
                name: '',
                dialCode: '1340',
                countryCode: 'vi'
            },
            {
                name: '',
                dialCode: '256',
                countryCode: 'ug'
            },
            {
                name: '',
                dialCode: '380',
                countryCode: 'ua'
            },
            {
                name: '',
                dialCode: '971',
                countryCode: 'ae'
            },
            {
                name: '',
                dialCode: '44',
                countryCode: 'gb'
            },
            {
                name: '',
                dialCode: '1',
                countryCode: 'us'
            },
            {
                name: '',
                dialCode: '598',
                countryCode: 'uy'
            },
            {
                name: '',
                dialCode: '998',
                countryCode: 'uz'
            },
            {
                name: '',
                dialCode: '678',
                countryCode: 'vu'
            },
            {
                name: '',
                dialCode: '39',
                countryCode: 'va'
            },
            {
                name: '',
                dialCode: '58',
                countryCode: 've'
            },
            {
                name: '',
                dialCode: '84',
                countryCode: 'vn'
            },
            {
                name: '',
                dialCode: '681',
                countryCode: 'wf'
            },
            {
                name: '',
                dialCode: '212',
                countryCode: 'eh'
            },
            {
                name: '',
                dialCode: '967',
                countryCode: 'ye'
            },
            {
                name: '',
                dialCode: '260',
                countryCode: 'zm'
            },
            {
                name: '',
                dialCode: '263',
                countryCode: 'zw'
            },
            {
                name: '',
                dialCode: '358',
                countryCode: 'ax'
            }
  ];

}
