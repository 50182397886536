import React, { useState, useCallback, useEffect, useContext } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import Api from "../../api/api.jsx";
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomCropper from '../../components/Cropper/CustomCropper.jsx';

import {
  Box,
  Grid,
  Container,
  Divider,
  Table,
  IconButton,
  CircularProgress
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import axios from 'axios';
import { conformsTo } from 'lodash';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

const CompanySetting = () => {
  const history = useHistory();
  const location = useLocation();
  const { accessToken, user, setUser } = useContext(AuthContext)
  const pathName = (location && location.state && location.state.pathname && location.state.pathname.length > 0) ? location.state.pathname : "/";
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRead, setIsLoadingRead] = useState(false);
  const [branchDialog, setBranchDialog] = useState(false);
  const [branch, setBranch] = useState("false");
  const [tempBranch, setTempBranch] = useState([])
  const [branchName, setBranchName] = useState('')
  const [branchEmail, setBranchEmail] = useState('')
  const [branchAddress, setBranchAddress] = useState('')
  const [branchTel, setBranchTel] = useState('')
  const [branchFax, setBranchFax] = useState('')
  
  // general section
  const [personalName, setPersonalName] = useState("")
  const [personalEmail, setPersonalEmail] = useState("")
  const [personalWebsite, setPersonalWebsite] = useState("")
  const [personalProfileImageURL, setPersonalProfileImageURL] = useState("")
  const [compnayAddress, setCompanyAddress] = useState("")
  const [registrationNo, setRegistrationNo] = useState("")
  const [faxNo, setFaxNo] = useState("")
  const [contactNo, setContactNo] = useState("")
  const [companyNameValidated, setCompanyNameValidated] = useState(false)
  const [companyEmailValidated, setCompanyEmailValidated] = useState(false)
  const [companyAddressValidated, setCompanyAddressValidated] = useState(false)
  const [contactNoValidated, setContactNoValidated] = useState(false)

  const [registrationValidated, setRegistrationValidated] = useState(false)
  const [deleteBranchDialog, setDeleteBranchDialog] = useState(false)
  const [deleteItem, setDeleteItem] = useState('')
  const [branchNameValidated, setBranchNameValidated] = useState(false)
  const [branchEmailValidated, setBranchEmailValidated] = useState(false)
  const [branchTelValidated, setBranchTelValidated] = useState(false)

  const callReadSettingsApi = useCallback(() => {
    setIsLoadingRead(true);

    Api.read('application_companies', {}, accessToken)
      .then(result => {
        console.log(result)
        apiUtil.parseResult(result, data => {
          // const res = (data.result && Object.keys(data.result).length > 0 ) ? data.result : {}
          // setCurrentMeasurementData(res);
          if (data.result && Object.keys(data.result).length > 0) {
            setPersonalName(data.result[0].company_name);
            setPersonalEmail(data.result[0].email);
            setPersonalWebsite(data.result[0].website);
            setPersonalProfileImageURL(data.result[0].company_logo);
            setCompanyAddress(data.result[0].address)
            setContactNo(data.result[0].contact_no)
            setFaxNo(data.result[0].fax_no)
            setRegistrationNo(data.result[0].company_registration_no)
            setTempBranch(data.result[0].application_company_addresses)
            setBranch(data.result[0].application_company_addresses.length > 0 ? "true" : "false")
            // setPersonalProfileOldData(data.result);
            // let user = JSON.parse(window.localStorage.getItem("user"));
            // user.owner_info = data.result;
            // window.localStorage.setItem('user', JSON.stringify(user));
            console.log("RegistrationNo", data.result[0].company_registration_no, data.result[0])
          }
          setIsLoadingRead(false);
        }, (error, type) => {
         
          setIsLoadingRead(false);
        })
      })

  }, [accessToken])

  const callUpdateProfile = (row, callback = null) => {
    setIsLoading(true);
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "application_companies/undefined";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let param = row
    axios.put(
      URL,
      param,
       { 
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken,
          }
      })
      .catch( error => {
          try {
              status = error.response.status;
              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data.error;
                  // setErrorConfirm(error.response.data.result);
                  // setShowDialogActive(true);
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
              
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
          setIsLoading(false)
          apiUtil.toast(errorMessage, 'check_circle');
      }).then((response) => {
        if (response) {
          console.log("response",  response.data.result)
          callback()

        }
        setIsLoading(false)
      });
  }



  useEffect(() => {
    callReadSettingsApi();
    return () => {

    }
  }, [callReadSettingsApi])


  const CustomStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <Box clone>
          <Grid item xs={'auto'}>
            <IconButton
              onClick={() => {
                history.push(pathName);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <Divider orientation={'vertical'} />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={true}>
            <CustomLabel
              className={'no-margin'}
              label={'Company Settings'}
              weight={'bold'}
              size={'md'}
            />
          </Grid>
        </Box>
        {/* <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton
              type={'submit'}
              color={'secondary'}
              isLoading={isLoading}
            >
              <Box pl={2} pr={2}>Save Changes</Box>
            </CustomButton>
          </Grid>
        </Box> */}
      </Grid>
    </Box>
  }

  const companySettings = () => {
    return <Box><Grid container p={1} justifyContent="center">
      <Card>
      <CustomLabel
        label={'By setting up your company profile, the company details will be shown in reports and other necessary places.'}
        weight={'bold'}
        size={'md'}
      />
      <Box clone>
        <Grid item xs={12}>
          <div style={{
            position: 'relative',
            display: 'flex',
            height: '260px',
            alignItems: 'center',
            justifyContent: 'center',
            justifyItems: 'center',
          }}>
            <CustomCropper
              className={'no-shadow'}
              path={'ProfilePicture'}
              initialImg={personalProfileImageURL}
              onLoad={() => {
                setIsLoading(true);
              }}
              onUpload={(url) => {
                if (url && url.result && url.result.image_medium_url && url.result.image_medium_url && url.result.image_medium_url !== '') {
                  setPersonalProfileImageURL(url.result.image_medium_url);
                }
              }}
              onEnd={() => {
                setIsLoading(false);
              }}
            />
          </div>
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Company Name'}
            placeholder={"Enter Company Name"}
            className="input-label--horizontal"
            value={personalName}
            error={companyNameValidated}
            required={true}
            onChange={e => {
              setPersonalName(e.target.value)
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Company Email'}
            placeholder={'Enter Company Email'}
            className="input-label--horizontal"
            value={personalEmail}
            error={companyEmailValidated}
            required={true}
            onChange={e => {
              setPersonalEmail(e.target.value)
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Company Website'}
            placeholder={'Enter Company Website'}
            className="input-label--horizontal"
            value={personalWebsite}
            required={false}
            onChange={e => {
              setPersonalWebsite(e.target.value)
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Company Address'}
            className="input-label--horizontal"
            value={compnayAddress}
            error={companyAddressValidated}
            required={true}
            onChange={e => {
              setCompanyAddress(e.target.value)
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Tel. No.'}
            placeholder={'Enter Phone No.'}
            className="input-label--horizontal"
            value={contactNo}
            error={contactNoValidated}
            onChange={e => {
              setContactNo(e.target.value)
              // setFirstNameValidated((e.target.value !== '') ? false : true) 
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Fax No.'}
            className="input-label--horizontal"
            value={faxNo}
            onChange={e => {
              setFaxNo(e.target.value)
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Registration No.'}
            className="input-label--horizontal"
            error={registrationValidated}
            required={true}
            value={registrationNo}
            onChange={e => {
              setRegistrationNo(e.target.value)
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <span>I have branch offices</span>
          <div style={{ paddingLeft: '20px' }}><input type="radio" name="branch" value="false" checked={branch == "false"? true : false}
            onChange={e => {
              setBranch(e.target.value)
            }} />No</div>
          <div style={{ paddingLeft: '20px' }}><input type="radio" name="branch" value="true" checked={branch == "true"? true : false}
            onChange={e => {
              setBranch(e.target.value)
            }} />Yes</div>
        </Grid>
      </Box>
      </Card>
     
    </Grid>

    {branch == "true" ?
        < Card>
      <Box clone >
        <Grid item xs={12}>
          <Box style={{display: 'flex', justifyContent:"space-between"}} my={2}>
          <h1>List of available branch offices</h1>
          <CustomButton
              color={'primary'}
              isLoading={isLoading}
            >
              <Box pl={2} pr={2} onClick={() =>{
                            setBranchDialog(true);
                        }} >Add Branch</Box>
            </CustomButton>
            </Box>
         <Table>
         <tr className="table-head-style">
          <th className="table-head-style-th">Branch Name</th>
          <th className="table-head-style-th">Branch Email</th>
          <th className="table-head-style-th">Branch Address</th>
          <th className="table-head-style-th">Branch Tel No</th>
          <th className="table-head-style-th">Branch Fax No</th>
          <th className="table-head-style-th">Actions</th>
        </tr> 
        {tempBranch.length > 0 && tempBranch.map((data) => 

          <tr className="even-style">
            <td className="table-head-style-td">{data.branch_name}</td>
            <td className="table-head-style-td">{data.branch_email}</td>
            <td className="table-head-style-td">{data.address}</td>
            <td className="table-head-style-td">{data.branch_tel_no}</td> 
            <td className="table-head-style-td">{data.branch_fax_no}</td> 
            <td className="table-head-style-td"><IconButton 
            onClick={() =>{
              setDeleteItem(data)
              setDeleteBranchDialog(true)
            } 
            } > <DeleteIcon /></IconButton></td> 
          </tr>
          )}
  </Table>
  </Grid>
      </Box> 
      </Card>
      : '' }
 
    <Card>
    <Box clone m={2}>
          <Grid item xs={'auto'}>
            <CustomButton
              type={'submit'}
              color={'secondary'}
              isLoading={isLoading}
            >
              <Box pl={2} pr={2}>Save Changes</Box>
            </CustomButton>
          </Grid>
        </Box>
        </Card>
    </Box>;
  }

  const branchDataTable = () => {
    return <Table>
      
      
    </Table>
  }
  const addBranchData = () => {
    const branchData = {
      "address" : branchAddress,
      "branch_email" : branchEmail,
      "branch_fax_no" : branchFax,
      "branch_name" : branchName,
      "branch_tel_no" : branchTel
    }
    tempBranch.push(branchData)
    setTempBranch(tempBranch)
    setBranchName('')
    setBranchAddress('')
    setBranchEmail('')
    setBranchFax('')
    setBranchTel('')

  }

  const removeFromBranch = ( value)  =>{
    let index = tempBranch.indexOf(value);
    if (index > -1) {
      tempBranch.splice(index, 1);
    }
    setTempBranch(tempBranch)
  }

  const deleteDialog = () => {
    return <CustomDialog 
    open={deleteBranchDialog}
    title={'Delete Branch'}
    onClose={() => {
      setDeleteBranchDialog(false);
    }}
    onOk={() => {
      removeFromBranch(deleteItem)
      setDeleteBranchDialog(false);
    }}
  >
    <Box>Are you sure want to delete?</Box>
  </CustomDialog>
  }
  const AddBranchPopUp = () => {
    return <CustomDialog 
   open={branchDialog}
   title={'Add Branch'}
   onClose={() => {
     setBranchDialog(false);
   }}
   autoClose={false}
   onOk={() => {

     setBranchNameValidated(false)
     setBranchEmailValidated(false)
     setBranchTelValidated(false)
     let nameValid = true
     let phoneValid = true
     let emailValid = true

     if(branchName == ''){
       nameValid = false
       setBranchNameValidated(true)
     }
     if(!apiUtil.phoneValidation(branchTel)){
       phoneValid = false
       setBranchTelValidated(true)
     }
     if(!apiUtil.emailValidation(branchEmail)){
       emailValid = false
      setBranchEmailValidated(true)
    }
    if(nameValid  && phoneValid && emailValid) {
      addBranchData()
      setBranchDialog(false);
    } 
     
   }}
 >
   <Box>
   <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Branch Name'}
            className="input-label--horizontal"
            value={branchName}
            error={branchNameValidated}
            onChange={e => {
              setBranchName(e.target.value)
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Branch Email'}
            className="input-label--horizontal"
            type={"email"}
            value={branchEmail}
            error={branchEmailValidated}
            onChange={e => {
              setBranchEmail(e.target.value)
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Branch Address'}
            className="input-label--horizontal"
            value={branchAddress}
            onChange={e => {
              setBranchAddress(e.target.value)
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Branch Tel No.'}
            className="input-label--horizontal"
            value={branchTel}
            error={branchTelValidated}
            onChange={e => {
              setBranchTel(e.target.value)
            }}
          />
        </Grid>
      </Box>
      <Box clone>
        <Grid item xs={12}>
          <CustomInput
            label={'Branch Fax No.'}
            className="input-label--horizontal"
            value={branchFax}
            onChange={e => {
              setBranchFax(e.target.value)
            }}
          />
        </Grid>
      </Box>
      </Box>
 </CustomDialog>
 }


  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    setCompanyNameValidated(false)
    setCompanyAddressValidated(false)
    setCompanyEmailValidated(false)
    setRegistrationValidated(false)

    if(personalName === "" || personalName === null ) {
      setCompanyNameValidated(true)
    }

    if(compnayAddress === ""  || compnayAddress === null) {
      setCompanyAddressValidated(true)
    }

    let personalEmailValid = true
    if(!apiUtil.emailValidation(personalEmail)) {
      personalEmailValid = false
      setCompanyEmailValidated(true)
    }

    if(registrationNo === "" || registrationNo === null) {
      setRegistrationValidated(true)
    }

    let contactNoValid = true
    if(contactNo !== "" && !apiUtil.phoneValidation(contactNo)) {
      contactNoValid = false
      setContactNoValidated(true)
    }
    console.log('form check',form.checkValidity());
    console.log('contact',contactNoValid);
    console.log('personal',personalEmailValid);
    if (form.checkValidity() === true && contactNoValid && personalEmailValid) { 
      let updateProfileFormatData = { 
        "company_name": personalName, 
        "email": personalEmail, 
        "website": personalWebsite, 
        "company_registration_no": registrationNo,
        "address": compnayAddress,
        "contact_no": contactNo,
        "fax_no": faxNo,
        "company_logo": personalProfileImageURL,
        "application_company_addresses": tempBranch,
       }
  
      callUpdateProfile(updateProfileFormatData, (data) => {
        let userInfo = apiUtil.getObject(user);
      
        apiUtil.toast('Successfully Updated Profile', 'check_circle');
        history.push('/dashboard');
      })
    }


    

  }
  return (
    <Box className="settings--form" >
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <StickyPageHeader isSmall={true}>
          <CustomStickyPageHeader />
        </StickyPageHeader>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
                <TabPanel value={value} index={0}>
                  {
                    isLoadingRead
                      ?
                      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'55vh'}>
                        <CircularProgress size={35} />
                      </Box>
                      :
                      companySettings()
                  }
                </TabPanel>
            </Grid>
            {AddBranchPopUp()}
            {deleteDialog()}
          </Grid>

        </Container>
      </form>
    </Box>
  )
}
export default CompanySetting;
