import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
  List,
  ListItem,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

// import Icon from '@material-ui/core/Icon';
import logoTextWhite from '../../assets/img/logo_text_white.png';

import { ReactComponent as ArrowRightIcon } from '../../assets/img/left_menu/arrow-right.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as DashboardIcon } from '../../assets/img/left_menu/dashboard.svg';
import { ReactComponent as JobsIcon } from '../../assets/img/left_menu/jobs.svg';
import { ReactComponent as CustomersIcon } from '../../assets/img/left_menu/customers.svg';
import { ReactComponent as ReportIcon } from '../../assets/img/left_menu/report.svg';
import { ReactComponent as VendorIcon } from '../../assets/img/left_menu/vendor.svg';
import { ReactComponent as ResourcesIcon } from '../../assets/img/left_menu/resources.svg';
import { ReactComponent as DeviceIcon } from '../../assets/img/left_menu/smartphone.svg';

import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import WMSIcon from '../../assets/img/services-icon1.png';
import LDSIcon from '../../assets/img/services-icon2.png';
import {RoleAccessService} from '../../data/role-access'; 
const CustomMenuItem = (props) => {
  let {text, icon, url, active, items, onDrawerToggle} = props;
  let [open, setOpen] = React.useState(active);

  return <React.Fragment>
    <ListItem
      // onClick = {() => onDrawerToggle? onDrawerToggle(): ""}
      className={'custom-item ' + ((typeof active === "function") ? active() : (active ? "active" : ""))}
    >
      <Link to={(items && items.length > 0) ? "" : url}
        onClick={(e) => {
          // e.stopPropagation();

          if(items && items.length > 0){
            e.preventDefault();
            setOpen(!open);
            
            if(onDrawerToggle){
              onDrawerToggle()
            }
          }
        }}
      >
        {icon ? icon : <span className={'no-icon'}></span>}
        <span>{text}</span>
        {(items && items.length > 0) && (open ? <ArrowDownIcon className={'custom-expand-down'} /> : <ArrowRightIcon className={'custom-expand-right'} />)}
      </Link>
    </ListItem>
    {(items && items.length > 0) && <Collapse in={open} timeout="auto" unmountOnExit>
      <List className={'sub-nav'} component="div" disablePadding>
        {items.map((item, i) => {
          return <React.Fragment key={i}>
            {item}
          </React.Fragment>;
        })}
      </List>
    </Collapse>}
  </React.Fragment>
}


class LeftMenu extends Component {
  static contextType = AuthContext;
  
  validURL = (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?'+
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
      '((\\d{1,3}\\.){3}\\d{1,3}))'+
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
      '(\\?[;&a-z\\d%_.~+=-]*)?'+
      '(\\#[-a-z\\d_]*)?$','i');
    return !!pattern.test(str);
  }

  handleServiceChange = (e) => {
    let url = e.target.value;
    if (!this.validURL(url)) return false;
    window.location.replace(url);
  } 

  render() {
    const { isCustomerRequired, currentRole, appPermission } = this.context;
    let grantedMenu = new RoleAccessService();
    return <Box>
      <List component="nav">
        <div className={this.props.className + ' custom-logo'}>
          <Link to={'/'}>
            <img src={logoTextWhite} alt={'evfy'} height={'50px'}/>
          </Link>
        </div>

        <div id="servicesMenuSelector">
          <label>Services</label>
          {/* <Select
            style={{width: "100%"}}
            value='#'
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            onChange={(e) => this.handleServiceChange(e)}
          >
            {appPermission.includes('wls') ? <MenuItem style={{width: 210}} className="dark-select" value={'#'}>
              <img src={LDSIcon} className="service-icon" alt="LDS Icon" />
              Fleet Management System</MenuItem> : ""}
            {appPermission.includes('wms') ? <MenuItem style={{width: 210}} className="dark-select" value={'https://wms.evfy.sg/'}><img src={WMSIcon} className="service-icon" alt="WMS Icon" />Warehouse Management System</MenuItem>: ""}
          </Select> */}
          <div style={{margin:"0 auto", display:"inline-block"}}><img src={LDSIcon} className="service-icon" alt="LDS Icon" /> <div style={{color:"white", paddingTop:"8px"}}> WLS </div></div>
        </div>

        <hr />
        <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Dashboard'}
          icon={<DashboardIcon />}
          url={'/dashboard'}
          active={(this.props.location.pathname === "/dashboard" ? true : false)}
        />
        

        <CustomMenuItem
          // onDrawerToggle={this.props.onDrawerToggle} 
          text={'Accounts'}
          icon={<CustomersIcon />}
          active={() => {
            if(this.props.location.pathname === "/accounts/all-account") {
              return true
            } else if(this.props.location.pathname === "/accounts/add-acccount") {
              return true
            } else if(this.props.location.pathname === "/accounts/all-role") {
              return true
            } else if(this.props.location.pathname === "/accounts/add-role") {
              return true
            }else {
              return false;
            }
          }}

          //items list of resource of new enhancement role
          // items={this.getResourceItemListForNewRole(currentRole)}  
          items={[
            <CustomMenuItem
              onDrawerToggle={this.props.onDrawerToggle} 
              text={'All Accounts'}
              url={'/accounts/all-account'}
              active={(this.props.location.pathname === "/accounts/all-account" ? true : false)}
            />,
            <CustomMenuItem
              onDrawerToggle={this.props.onDrawerToggle} 
              text={'Add Account'}
              url={'/accounts/add-account'}
              active={(this.props.location.pathname === "/accounts/add-acccount" ? true : false)}
            />,
            <CustomMenuItem
              onDrawerToggle={this.props.onDrawerToggle}   
              text={'All Roles'}
              url={'/accounts/all-role'}
              active={(this.props.location.pathname === "/accounts/all-role" ? true : false)}
            />,
            <CustomMenuItem
              onDrawerToggle={this.props.onDrawerToggle}   
              text={'Add Role'}
              url={'/accounts/add-role'}
              active={(this.props.location.pathname === "/accounts/add-role" ? true : false)}
            />,
          ] }       
        />
        
        <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Devices'}
          icon={<DeviceIcon />}
          url={'/device'}
          active={((this.props.location.pathname === "/device") ? true : false)}
        />
        <CustomMenuItem
          onDrawerToggle={this.props.onDrawerToggle} 
          text={'Invoice'}
          icon={<ReportIcon />}
          url={'/invoice'}
          active={(this.props.location.pathname === "/invoice" ? true : false)}
        />

      </List>
    </Box>
  }

  
}

export default withRouter(LeftMenu);
