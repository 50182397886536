import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import { 
  Box,
  Grid,
  Button
} from '@material-ui/core';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import { NavLink } from 'react-router-dom';


const {
  REACT_APP_AUTH_API_URL
} = process.env;

class Invoice extends Component {
  static contextType = AuthContext;
  
  constructor(props){
    super(props);
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    

    this.state = {
      data :  [],
      page : 1,
      total : 2,
      limit : 20,
      isLoading: false,
      pageSize : 22,      // status: [],
      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,
    }

   
  }
  componentDidMount() { 
    this.callReadApi();
  }

  callReadApi(){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;

    this.setState({
      isLoading: true
    });
    
    const PATH = 'admin/api/';
    const api = "application_subscription_billing";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    const { accessToken } = this.context;

    let param = {
      page: this.state.page,
      take: this.state.limit,
      is_paid: false,
      // start_date: startDate,
      // end_date: endDate,
      // sorting_array: ['created_at DESC'],
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
       { 
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
          }
      })
      .catch( error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }

          this.setState({
            isLoading: false,
          });

      }).then((response) => {
        // console.log(response);
        if(response != null){
        this.setState({
          data: response.data.result,
          isLoading: false,
        });
        }
       this.callPaidApi()
      });
  }

  callPaidApi(){
    let errorMessage = "";
    let status = 0;
    const { 
      REACT_APP_AUTH_API_URL
    } = process.env;

    this.setState({
      isLoading: true
    });
    
    const PATH = 'admin/api/';
    const api = "application_subscription_billing";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    const { accessToken } = this.context;
    let param = {
      is_paid: true,
    };
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
       { 
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
          }
      })
      .catch( error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
          this.setState({
            isLoading: false
          })
      }).then((response) => {
        // console.log(response);
        if(response != null){
        let unpaid_bill = this.state.data;
        let paid_bill = response.data.result.filter(data => {
          if ( data.hasOwnProperty("application_payment_record") && data.application_payment_record.payment_method != "free_of_charges") {
            return true
          }
          else {return false}
        })
        let data = unpaid_bill.concat(paid_bill)
        this.setState({
          data,
          isLoading: false,
        });
        }
       
      });
  }

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }
 
  customTable = () => {

    return <Table 
      ref={this.refTable}
      className={'job-table-list' }  
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      limit={this.state.limit}
      pageSize= {this.state.pageSize}
      // pageSizeOptions ={this.state.pageSizeOptions}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      // defaultExpanded={true}
      // selection={true}
      onPageChange={(page,size) => {
        
        this.state.limit = size;
        this.setState({
          page: page,
        }, () => { 
          this.callReadApi();
          // this.callReadApi(this.refTableSearch.current.state.value);
        });
      }}
      // Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={[
     
        { 
          title: "Invoice Number", 
          field: "Invoice Number",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150             
          },
          render:(row, type) => {
            if(type === 'row'){
              return (row.invoice_number   != ' ') ? HtmlParser(row.invoice_number) : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Date", 
          field: "date",
          cellStyle: {
            minWidth: 140,
            maxWidth: 140            
          },

          render: (row, type) => {
            if(type === 'row'){
              if(row.billing_date   != ' ') {
                var date = new Date(row.billing_date);
                return date.toLocaleDateString()
              }
            } else {
              return row;
            }
          }
        },
        { 
          title: "Payment Status", 
          field: "Payment Status",
          cellStyle: {
            minWidth: 340,
            maxWidth: 340
          },
          render: (row, type) => {
            if(type === 'row'){
              var date = new Date(row.billing_date);
                date.setDate(date.getDate() + 10);
              return <>
              {(row.is_paid == false) ? <span style={{color:"#ff0011"}}>UNPAID</span> : <span style={{color:"#66BD63"}}>PAID</span>}<br />
              {(row.is_paid == false) ? <p>Please pay before 
                <span style={{fontWeight:"800"}}> {(row.billing_date != "") ? date.toLocaleDateString() : ''} </span>
                to avoid service suspension.
              </p> : ''}
              </>
             } else {
              return row;
            }
          }
        },
        { 
            title: "Action",
            field: "action",
            cellStyle: {
              minWidth: 220,
              maxWidth: 220            
            },
            render:(row, type) => {
              // console.log("aa", row.id);
              if (type === 'row') {
                return <Box>
                  {(row.is_paid == false) ? <Button style={{ backgroundColor: '#006837' }}
                onClick={() => {
                  this.props.history.push({
                    pathname: '/payment',
                    state: {
                        id: row.id,
                    }
                    });
                }}
              ><span style={{color: "#FFFFFF", textTransform: "lowercase"}}>Pay Now</span></Button>: ''}&nbsp;
              {row.is_paid ? <Button style={{ backgroundColor: '#565553'}}
                      
                      type={'button'}
                      color={'secd'}
                      onClick={() => {
                        const URL = REACT_APP_AUTH_API_URL+'application_payment_records_pdf/'+row.id+'.pdf';
                        window.open(URL, '_blank');
                      }}
                    >
                    <span style={{color: "#FFFFFF", textTransform: "lowercase"}}>Receipt</span></Button> : ''}&nbsp;
              
              <Button style={{ backgroundColor: '#565553'}}>
              <NavLink  
                to={{
                  pathname: "/invoice-detail",
                  state : {id:row.id} // your data array of objects
                }} 
                style={{ textDecoration: 'none', textTransform:"lowercase" , color: "#FFFFFF"}}
                >View</NavLink>
              </Button>
              </Box> 
            } else {
                return row;
            }
            }
          },
      ]}
    />
  }

  render() {
    return <Box className="dashboard-page">
      <Card className={'job-listing-step'}>
        {this.customTable()}
      </Card>
    </Box>;
  }
}

export default Invoice;
