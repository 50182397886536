import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";

import { 
  Box,
} from '@material-ui/core';

class StickyPageHeader extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let isSmall = ((props.isSmall === true || props.isSmall === false) ? props.isSmall : false);

    this.state = {
      isSmall: isSmall,
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({
        open: nextProps.open
      });
    }
  }


  render() {
    const { isOnBoarding } = this.context;

    return <Box className={'custom-sticky-page-header ' + (this.state.isSmall ? 'small' : '') + ' ' + (isOnBoarding ? 'top-0' : '')}>
        <Box width={'100%'} height={'100%'}>
          {this.props.children}
        </Box>
    </Box>
  }
}

export default StickyPageHeader;
