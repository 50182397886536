import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import { 
  Box,
  Table,
  Grid,
  Button
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Card from '../../components/Card/Card.jsx';
// import Table from '../../components/Table/Table.jsx';
import { NavLink } from 'react-router-dom';
import apiUtil from "../../api/apiUtil.jsx";
import axios from 'axios';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
const { 
  REACT_APP_AUTH_API_URL
} = process.env;

class InvoiceDetail extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();
    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }
    

    this.state = {
      data :  [],
      billing: [],
      page : 1,
      plan:'',
      total : 2,
      limit : 10,
      isLoadingStatus: false,
      pageSize : 22,      // status: [],
      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,
      id: this.props.location.state.id
    }

   
  }
  componentDidMount() { 
    this.callReadApi();
  }

  callReadApi(){
    let errorMessage = "";
    let status = 0;
    let dataid = this.state.id;
    const PATH = 'admin/api/';
    const api = `application_subscription_billing/${dataid}`;
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    const { accessToken } = this.context;
   
    let param = {
      page: this.state.page,
      take: this.state.limit,
      search: "",
      // start_date: startDate,
      // end_date: endDate,
    //   sorting_array: ['created_at DESC'],
    };
    
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
       { 
          params: param,
          data: param,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
          }
      })
      .catch( error => {
          try {
              status = error.response.status;

              if (error.response) {
                  // Request made and server responded
                  errorMessage = error.response.data;
              } else if (error.request) {
                  // The request was made but no response was received
                  errorMessage = error.request;
              } else {
                  // Something happened in setting up the request that triggered an Error
                  errorMessage = error.message;
              }
          } catch(err) {
              errorMessage = 'Service Unavailable!'
          }
      }).then((response) => {

        var json_data = response.data.result.billing_items;
        var result = [];
        for (var i in json_data) {
          result[i] = json_data[i];
        }

        if(response != null){
        this.setState({
          data: response.data.result,
          billing: result,
          total: response.data.total,
          isLoading: false,
          plan:result[0].application_company_plan
        });
        }

      });
  }

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }

  topBottomList = () => {
    return <Grid container>
      <Grid item xs={6} >
      <CustomButton 
          className={'create-role-step'}
          type={'button'}
          color={'secondary'}
          isLoading={this.state.isLoading}
          onClick={() => {
            this.props.history.push({
              pathname: '/invoice',
              state: {
                pathname: this.props.location.pathname
              }
            });
          }}
        >
          <Box ><ArrowBackIosIcon style={{fontSize:"10px"}}/><ArrowBackIosIcon style={{fontSize:"10px"}}/> Back</Box>
          
        </CustomButton>
      </Grid>
      <Grid item container xs={6} spacing={2} justify="flex-end">
        <Grid item >
        <CustomButton 
          className={'create-role-step'}
          type={'button'}
          color={'secondary'}
          isLoading={this.state.isLoading}
          onClick={() => {
            
            const URL = REACT_APP_AUTH_API_URL+'application_invoices_pdf/'+this.state.id+'.pdf';
            axios.get(URL)
            .catch(error=>{
              if(error) {
                apiUtil.toast("Cannot print this invoice", 'check_circle', 'error')
              }
            })
            .then((result)=>{
              if(result) {
                window.open(URL, '_blank')
              }
            })
          }}
        >
         <Box>Print Invoice</Box> 
          
        </CustomButton>
        </Grid>
        {this.state.data.length > 0 && !this.state.data.is_paid ? <Grid item >
        <CustomButton 
          className={'create-role-step'}
          type={'button'}
          color={'primary'}
          isLoading={this.state.isLoading}
          onClick={() => {
            this.props.history.push({
              pathname: '/payment',
              state: {
                  id: this.state.id,
              }
              });
          }}
        >
          <Box>Pay Now</Box>
          
        </CustomButton>
        </Grid> : ''}
        
      </Grid>
    </Grid>
  }

  invoiceList = () => {
       return <>
    <table style={{ border: "0px solid #c1c1c1" }}>
    <tr style={{ borderBottom: "1px solid #eaf2fb" }}>
      <td width="50%" height="50px" style={{ paddingTop: "25px !important" }}><strong>
      {(this.state.data && this.state.data.invoice_number) ? this.state.data.invoice_number : ""}
      </strong></td>
      <td></td>
    </tr>
    <tr style={{ borderBottom: "1px solid #eaf2fb" }}>
      <td><strong>Invoice Form</strong></td>
      <td height="50px">LogisFleet Pte. Ltd <br />
        01, Bukit Batok Crescent, #09-51, Wcega Plaza, Singapore 658064</td>
    </tr>
    <tr style={{ borderBottom: "1px solid #eaf2fb" }}>
      <td height="50px"><strong> Billed To : </strong></td>
      <td >{(this.state.data && this.state.data.billing_name) ? this.state.data.billing_name : ""}</td>
    </tr>
    <tr style={{ borderBottom: "1px solid #eaf2fb" }}>
      <td height="50px"><strong> Billing Date : </strong></td>
      <td> {(() => {
          if(this.state.data && this.state.data.billing_date != ' ') {
                var date = new Date(this.state.data.billing_date);
                return date.toLocaleDateString()
              } })()}
      </td>
    </tr>
    <tr style={{ borderBottom: "1px solid #eaf2fb 0.4" }}>
      <td height="50px"><strong> Due Date : </strong></td>
      <td >
      {(() => {
          if(this.state.data && this.state.data.billing_date != ' ') {
                var date = new Date(this.state.data.billing_date);
                date.setDate(date.getDate() + 14);
                return date.toLocaleDateString()
              } })()}
      </td>
    </tr>
  </table>
  </>
  }

  customTable = () => {

    return <Table>
    <tr className="table-head-style">
      <th className="table-head-style-th">Device ID</th>
      <th className="table-head-style-th">Usage Dates</th>
      <th className="table-head-style-th">Total Duration</th>
      <th className="table-head-style-th">Total</th>
    </tr> 
    {this.state.billing.filter(function(data){
        if(data.device_management != null)
            return data;
    }).map((data) =>

      <tr className="even-style">
        <td className="table-head-style-td">{(data.device_management && data.device_management.device_id != '')? data.device_management.device_id : ''}</td>
        <td className="table-head-style-td">{(data.device_start_date && data.device_start_date != '')? data.device_start_date + ' to ' + data.device_end_date: ''}</td>
        <td className="table-head-style-td">{(data.number_of_days_used && data.number_of_days_used != '')? data.number_of_days_used + ' days' : ''}</td>
        <td className="table-head-style-td">{(data.charges && data.charges != '')? '$ ' + data.charges : ''}</td> 
      </tr>
    )}
    <tr className="even-style-plan">
        <td colspan="3" className="table-head-style-td"><span>Subscribed Plan: </span>
        {(this.state.plan && this.state.plan != '')? this.state.plan.plan_name : ''}
        </td>
        <td className="table-head-style-td">
        {(this.state.plan && this.state.plan != '')? '$ ' + this.state.plan.price : ''}
        </td>
    </tr>
    <tr className="even-style-plan">
        <td colspan="3" className="table-head-style-td">Subtotal</td>
        <td className="table-head-style-td">
        {(this.state.data && this.state.data.total_price != '')? '$ ' + this.state.data.total_price : ''}
        </td>
    </tr>
    <tr className="even-style-total">
        <td colspan="3" className="table-head-style-td">Total</td>
        <td className="table-head-style-td">
        {(this.state.data && this.state.data.total_price != '')? '$ ' + this.state.data.total_price : ''}
        </td>
    </tr>
  </Table>
  }

  render() {
    return <Box className="dashboard-page">
       
      <Card className={'job-listing-step'}>
        {this.topBottomList()}
        {this.invoiceList()}
        {this.customTable()}
      </Card>
    </Box>;
  }
}

export default InvoiceDetail;
