import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import HoldIcon from '../../assets/img/hold-on.png';
import QuotaPanel from '../../assets/img/subscribe-now/step-1/quota-panel.png';
import QuotaLogin from '../../assets/img/subscribe-now/step-1/quota-login.png';
import QuotaPanelFilled from '../../assets/img/subscribe-now/step-2/quota-panel-filled.png';
import QuotaRejected from '../../assets/img/subscribe-now/step-2/quota-rejected.png';
import QuotaPanelBlankOne from '../../assets/img/subscribe-now/step-3/quota-panel-blank-one.png';
import QuotaRemoved from '../../assets/img/subscribe-now/step-3/quota-removed.png';
import QuotaUsage from '../../assets/img/subscribe-now/step-4/quota-usage.png';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import apiUtil from "../../api/apiUtil.jsx";
import Api from "../../api/api.jsx";
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    padding: "10px",
    marginTop: theme.spacing(3)
  },
  startingPage: {
    // width: '50%',
    margin: '0 auto',
    marginTop: theme.spacing(10),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

function getSteps() {
  return ['Device Quota', 'Quota Overload', 'Remove Device', 'Track Usage', 'Set Quota'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return deviceQuota();
    case 1:
      return quotaOverload();
    case 2:
      return removeDevice();
    case 3:
      return trackUsage();
    // case 4:
    //   return setQuota();
    default:
      return 'Unknown stepIndex';
  }
}

function deviceQuota () {
  return <>
  <Typography variant="h5">Device quota</Typography>
  <br/>
  <Typography variant="body1">Example : Quota is set to 10 devices.</Typography>
  
  <Typography variant="body1">Any device logged into this quota will get billed.</Typography>
  <br/>
  <div style={{position:'relative', marginBottom:"70px", paddingBottom:"50px"}}>
  <img src={QuotaPanel} width="100%" />
  <img src={QuotaLogin} height="50%" style={{position: 'absolute', top: '50%',right: '-20px'}} />
  <Typography variant="body1" style={{textAlign:"right", paddingTop:'20px', paddingRight:"50px"}}>Up to 10 devices can login!</Typography>

  </div>
  </>
}
function quotaOverload () {
  return <>
  <Typography variant="h5">Quota overload</Typography>
  <br/>
  <Typography variant="body1">Example : Quota is set to 10 devices & all 10 devices are logged in. Another devices tried to login but got rejected.</Typography>
  <br/>
  <Typography variant="body1">Increase your quota count to allow more devices to login. You wont be charged for increasing quota however charges will incur per logged in device as usual.</Typography>
  <br/>
  <div>
  <img src={QuotaPanelFilled} width="100%" />
  <img src={QuotaRejected} style={{display:"block", margin:'auto', marginTop:"20px"}} />
  <Typography variant="body1" style={{marginTop:'20px', textAlign:'center'}}>Additional devices attempted to login gets rejected. Please increase quota.</Typography>

  </div>
  </>
}


function removeDevice () {
  return <>
  <Typography variant="h5">Removing devices</Typography>
  <br/>
  <Typography variant="body1">Removed devices will no longer incur any more charges as it no longer stays in the quota slot.</Typography>
  <br/>
  <Typography variant="body1">Drivers that are disabled or removed from FMS will also get their current devices removed in the quota slot to avoid unnecessary charges for unused devices.</Typography>
  <br/>
  <div style={{position:'relative', marginBottom:"70px", paddingBottom:"50px"}}>
  <img src={QuotaPanelBlankOne} width="100%" />
  <img src={QuotaRemoved} height="50%" style={{position: 'absolute', top: '45%',right: '-20px'}} />
  <Typography variant="body1" style={{marginRight:"40px", paddingTop:'20px', paddingRight:"100px", marginLeft: "90px"}}>Once a device is removed, a slot is available for other device to login unless quota is reduced.</Typography>

  </div>
  <Typography variant="body1" style={{marginTop:'20px', textAlign:'center'}}>Additional devices attempted to login gets rejected. Please increase quota.</Typography>
  </>
}

function trackUsage () {
  return <>
  <Typography variant="h5">Track usage</Typography>
  <br/>
  <Typography variant="body1">You can track your device usage in the "Usage" tab. Easily plan your expenses by identifying which device is not being utilized.</Typography>
  <br/>
  <div >
  <img src={QuotaUsage} width="100%" />
  </div>
  </>
}

export default function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [initPage, setInitPage] = React.useState(true);
  const [numQuota, setNumQuota] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(props.openDialog? props.openDialog : true);
  const [compnayId, setCompanyId] = React.useState(0)
  const [numQuotaValidated, setNumQuotaValidated] = React.useState(false);
  const steps = getSteps();

  React.useEffect(() => {
    console.log("props",props);
    if(numQuota === 0) {
      callReadDeviceApi();
    }
    
  }, [numQuota])

  const callReadDeviceApi = ( callback = null) => { 
    let param = {}
    let token = window.localStorage.getItem('access_token');

    Api.read('application_companies', param, token)
        .then((result) => {
            apiUtil.parseResult(result, (data) => {
                
                let result = (data && data.result) ? data.result : null;
                console.log(result)
                if(result){
                  setNumQuota(result[0].application_company_license.requested_devices)
                  setCompanyId(result[0].id)
                }
              }, (error, type) => {
                  
                  console.log(error)
              });
        })
  }

  const callUpdateQuotaApi = () => {
  
    let token = window.localStorage.getItem('access_token');
    const data = {
      requested_devices: numQuota,
      is_subscribing: true,
    }
    Api.update('application_companies/' + compnayId, data, token)
    .then((result) => {
        apiUtil.parseResult(result, (data) => {
        if(data){
          history.push('/device');
        }
        }, (error, type) => {
            console.log("error", error)
            history.push('/device');
            
        });
    }).catch(
        error => console.log(error)
    )
  }

  const subscribeService = () => {

    if(numQuota !== null && numQuota !== 0){
      if(props.onClose()){
        console.log("have props");
            props.onClose();
        }
    }
    
    if(numQuota === null || numQuota === '0') {
      setNumQuotaValidated(true);
      return
    }

    callUpdateQuotaApi()
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleStart = () => {
    setActiveStep(0);
    setInitPage(false)
  };

  const handleSkipAll = () => {
    setActiveStep(4);
    setInitPage(false)
  };

  return (

      <Grid container justifyContent="center" className={classes.root}>
        <Grid item xs={12} sm={8} xl={6}>
          {initPage ? (
      <div className={classes.startingPage} >
        <Typography variant="h4">Subscribe to Evfy Tracker</Typography>
        <Box style={{ display: 'flex' , marginTop: '5vh'}} p={2} >   
            <img src={HoldIcon} alt="Hold Icon"  height="80px" />
            <Box ml={2}>
              <Typography gutterBottom variant="h5" component="div">
                HOLD ON!
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
              It's important to understand how Evfy Tracker's billing work first before we proceed to set quota.
              </Typography>
            </Box>
          </Box>

          <Typography variant="h5" style={{marginTop:'10vh', textAlign: 'center'}}>We promise you it will be quick!</Typography>
          <div style={{marginTop:'15vh'}} className='container'>
            <Button
              onClick={handleSkipAll}
              color="secondary"
              className={classes.backButton}
            >
              Skip this & set quota
            </Button>
            <Button variant="contained" color="primary" onClick={handleStart}>
            Learn now & set quota
            </Button>
          </div>
      </div>
      ):(
        <>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>All steps completed</Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {activeStep === steps.length - 1 ? <>
                  <Typography variant="h5">Let's start!</Typography>
                  <br/>
                  <Typography variant="body1">Now that you have understand our billing structure, let's subscribe to Evfy Tracker.</Typography>
                  <br/>
                  <div >
                  <Grid item xs={6} sm={4} xl={3} >
                      <CustomInput
                          label={'Set Quota'}
                          className="input-label--horizontal"
                          type={'number'}
                          value={numQuota}
                          error={numQuotaValidated}
                          onChange={e => {
                              setNumQuota(e.target.value)
                              setNumQuotaValidated((e.target.value !== '') ? false : true) 
                          }}
                      />
                  </Grid>
                  </div>
                  </> : getStepContent(activeStep)}</Typography>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    {activeStep === 0 ? 'Back' : steps[activeStep - 1]}
                  </Button>
                  <Button variant="contained" color="primary" 
                  onClick={
                    activeStep === steps.length - 1 ? 
                      subscribeService 
                      :handleNext }>
                    {activeStep === steps.length - 1 ? 'Subscribe to Evfy Tracker' : steps[activeStep + 1]}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
        </Grid>
      
      
    </Grid>

    
  );
}
