import React, { useState } from 'react';
// import Geocode from "react-geocode";
import { compose, withProps, lifecycle } from 'recompose';
import { GoogleMap, Polygon , withScriptjs, withGoogleMap,InfoWindow} from "react-google-maps"
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomMultiSelectOption from '../../components/CustomFields/CustomMultiSelectOption.jsx';
import CustomAlert from '../../components/Alert/CustomAlert.jsx';
import { ReactComponent as InformationIcon } from '../../assets/img/icons/information.svg';
import { 
  Box,
  Grid,
  Divider,
  Container,
  ListItemText

} from '@material-ui/core';

import apiUtil from '../../api/apiUtil.jsx';


// Geocode.setApiKey(apiUtil.getGoogleMapApiKey());


const DEF_CENTER = { lat: 1.352083, lng: 103.819836 };


const CustomPolygonMap = compose(
    withProps({
        googleMapURL: apiUtil.getGoogleMapUrl(),
        loadingElement: <div style={{ width: '100%', height: '100%' }} />,
        containerElement: <div style={{ width: '100%', height:'100%' }} />,
        mapElement: <div style={{ width: '100%', height: `100%` }} />
    }),
    lifecycle({
        componentWillMount() {
          const refs = {}
          let center = (this.props.center) ? this.props.center : DEF_CENTER;

          this.setState({
            getRefs: () => {
              return refs;
            },
            getCenter: () => {
              return center;
            },
            getDrawingMode: () => {
              return refs.drawingMode;
            },
            getDrawingModeShape: () => {
              return refs.drawingModeShape;
            },
            onMapMounted: ref => {
              refs.map = ref;
            },
            onPolygonMounted: ref => {
              refs.polygon = ref;
            }
            
          })
        },
      }),
    withScriptjs,
    withGoogleMap
  )(props => {
    const [defaultCenter] = useState(props.defaultCenter ? props.defaultCenter : DEF_CENTER);
    const [center, setCenter] = useState(props.center ? props.center : DEF_CENTER);
    const [defaultZoom] = useState(props.defaultZoom ? props.defaultZoom : 12);
    const [zoom, setZoom] = useState(props.zoom ? props.zoom : 12);
    const [polygons, setPolygons] = useState((props.polygons && props.polygons.length > 0) ? props.polygons : []);
    const [drawingMode, setDrawingMode] = useState(props.drawingMode ? props.drawingMode : false);
    const [location, setLocation] = React.useState(props.center ? props.center : DEF_CENTER);
    const [drawingModeShape, setDrawingModeShape] = useState(props.drawingModeShape ? props.drawingModeShape : null);

    React.useEffect(() => {
        setCenter(props.center ? props.center : DEF_CENTER);
        setZoom(props.zoom ? props.zoom : 12);
        setDrawingMode(props.drawingMode ? props.drawingMode : false);
        setDrawingModeShape(props.drawingModeShape ? props.drawingModeShape : null);
  
    }, [props.center, props.zoom, props.polygons, props.drawingMode, props.drawingModeShape]);

    function getPaths(polygon) {

      let polygonBounds = polygon.getPath();
      let bounds = [];
      for (let i = 0; i < polygonBounds.length; i++) {
        let point = {
          lat: polygonBounds.getAt(i).lat(),
          lng: polygonBounds.getAt(i).lng()
        };
        bounds.push(point);
      }
      polygon.setMap(null);
      props.onClick(bounds, null, null);
    }

    function calculateCenter(polys)
    {
      let x = 0;
      let y = 0;
      let z = 0;

      for(let i = 0; i < polys.length; i++)
      {
        let lat = polys[i].latitude * Math.PI / 180;
        let lng = polys[i].longitude * Math.PI / 180;

        x += Math.cos(lat) * Math.cos(lng);
        y += Math.cos(lat) * Math.sin(lng);
        z += Math.sin(lat);
      }

      let total = polys.length;
      x = x/total;
      y = y/total;
      z = z/total;

      let centalLong = Math.atan2(y,x);
      let centralSquare = Math.sqrt(x * x + y * y);
      let centerLat = Math.atan2(z,centralSquare);

      return {lat : centerLat * 180/Math.PI, lng:centalLong * 180/Math.PI}
    }

    const renderInfoWindow = () => {
      let infoWindow = []
      props.polygons.map((item, i) => {     
          let name = ((item.polygon_name && item.polygon_name !== '') ? item.polygon_name : '');
          let coordCenter = calculateCenter(item.worker_polygon_coordinates);         
  
          if(item.isOpen){
            infoWindow.push(<InfoWindow 
            key = {i}
                position={new window.google.maps.LatLng(coordCenter.lat, coordCenter.lng)}

                onCloseClick={() => {
                    item.hasClosed = true;
                    if(props.onClick){
                        props.onClick(null, item, i);
                    }
                }}
               
            >
                <Box clone alignItems={'center'}
                    style={{
                        width: '250px',
                        backgroundColor: 'white',
                        borderRadius: '6px',
                        overflow: 'hidden',
                    }}
                >
                  <Grid container>                      
                      <Box clone p={2}>
                          <Grid container p={0}>

                              {
                                item.isOpen && !item.isEdit && !item.isNew && 
                                  <Grid container p={0}>                           
                                    <ListItemText xs={12} primary={name} secondary={item.polygon_desc} />
                                    <Box clone xs={12}>
                                      <Grid item>
                                        <CustomMultiSelectOption
                                          label={"Assign Worker"}
                                          placeholder={"Choose worker name"}
                                          items={props.workers}
                                          value={item.worker_names}
                                          onChange={(e, f) => {
                                            item.isAssigned = true;
                                            item.worker_names = e;
                                            let newWorkerIds = [];
                                            f.map((pin, p) => { newWorkerIds.push(pin.id); });  
                                            item.workerIds = newWorkerIds;                                          
                                            props.onClick(f, item, i);
                                          }}
                                        />
                                      </Grid>
                                    </Box>
                                    
                                    <Box clone xs={4} textAlign={'center'}>
                                      <Grid item>
                                        <CustomButton
                                          className={"delete-zone"}
                                          color={"secondary"}
                                          key={i}
                                          isLoading = {item.loadingDelete}
                                          onClick={(e) => {
                                            item.isDeleted = true;
                                            props.onClick(null, item, i);
                                            item.loadingDelete = true;
                                          }}
                                        >
                                          Delete
                                        </CustomButton>
                                      </Grid>
                                    </Box>
                                    
                                    
                                    <Box clone xs={4}  textAlign={'center'}>
                                      <Grid item>
                                        <CustomButton
                                          className={"edit-zone"}
                                          color={"primary"}
                                          key={i}
                                          onClick={(e) => {
                                            item.isEdit = true;
                                            props.onClick(null, item, i);

                                          }}
                                        >
                                          Edit
                                        </CustomButton>
                                      </Grid>
                                    </Box>                                  

                                    {item.isAssigned && 
                                      <Box clone xs={4}  textAlign={'center'}>
                                        <Grid item>
                                          <CustomButton
                                            className={"assign-driver"}
                                            color={"primary"}
                                            key={i}
                                            isLoading = {item.loadingAssign}
                                            onClick={(e) => {
                                              item.isAssignedSubmit = true;
                                              props.onClick(item.worker_ids, item, i);
                                              item.loadingAssign = true;
                                            }}
                                          >
                                            Assign
                                          </CustomButton>
                                        </Grid>
                                      </Box>
                                    }
                                  </Grid>                                        
                              }

                              {
                                item.isOpen && !item.isEdit && item.isNew && 
                                  <Grid container p={0}>  
                                    <Box clone xs={12}>
                                      <Grid item>
                                        <CustomInput
                                          label={"Zone Name"}
                                          placeholder={"Enter zone name"}
                                          value={item.polygon_name}
                                          required={true}
                                          autoFocus={true}
                                          onChange={(e) => {
                                            item.polygon_name = e.target.value;
                                          }}
                                        />
                                      </Grid>
                                    </Box>
                                    <Box clone xs={12}>
                                      <Grid item>
                                        <CustomInput
                                          label={"Zone Description (Optional)"}
                                          placeholder={"Enter zone description"}
                                          value={item.polygon_desc}
                                          multiline={true}
                                          required={false}
                                          autoFocus={true}
                                          onChange={(e) => {
                                            item.polygon_desc = e.target.value;
                                          }}
                                        />
                                      </Grid>
                                    </Box>
                                    <Box clone xs={6}  textAlign={'center'}>
                                      <Grid item>
                                        <CustomButton
                                          className={"delete-zone"}
                                          color={"secondary"}
                                          key={i}
                                          isLoading = {item.loadingDelete}
                                          onClick={(e) => {
                                            item.isDeleted = true;
                                            props.onClick(null, item, i);
                                            item.loadingDelete = true;
                                          }}
                                        >
                                          Delete
                                        </CustomButton>
                                      </Grid>
                                    </Box>

                                    <Box clone xs={6}  textAlign={'center'}>
                                      <Grid item>
                                        <CustomButton
                                          className={"add-zone"}
                                          color={"primary"}
                                          key={i}
                                          isLoading = {item.loadingSave}
                                          onClick={(e) => {
                                            item.isSave = true;
                                            props.onClick(null, item, i);
                                            item.loadingSave = true;
                                          }}
                                        >
                                          Save
                                        </CustomButton>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                
                              }

                              {
                                item.isOpen && item.isEdit && !item.isNew &&
                                  <Grid container p={0}> 
                                    <Box clone  xs={12}>
                                      <Grid item>
                                        <CustomInput
                                          label={"Zone Name"}
                                          placeholder={"Enter zone name"}
                                          value={item.polygon_name}
                                          required={true}
                                          autoFocus={true}
                                          onChange={(e) => {
                                            item.polygon_name = e.target.value;
                                          }}
                                        />
                                      </Grid>
                                    </Box>
                                    <Box clone xs={12}>
                                      <Grid item>
                                        <CustomInput
                                          label={"Zone Description (Optional)"}
                                          placeholder={"Enter zone description"}
                                          value={item.polygon_desc}
                                          required={false}
                                          autoFocus={true}
                                          multiline={true}
                                          onChange={(e) => {
                                            item.polygon_desc = e.target.value;
                                          }}
                                        />
                                      </Grid>
                                    </Box>
                                    <Box clone xs={6}  textAlign={'center'}>
                                      <Grid item>
                                        <CustomButton
                                          className={"delete-zone"}
                                          color={"secondary"}
                                          key={i}
                                          isLoading = {item.loadingDelete}
                                          onClick={(e) => {
                                            item.isDeleted = true;
                                            item.loadingDelete = true;
                                            props.onClick(null, item, i);
                                          }}
                                        >
                                          Delete
                                        </CustomButton>
                                      </Grid>
                                    </Box>

                                    <Box clone xs={6}  textAlign={'center'}>
                                      <Grid item>
                                        <CustomButton
                                          className={"edit-zone"}
                                          color={"primary"}
                                          key={i}
                                          isLoading = {item.loadingSave}
                                          onClick={(e) => {
                                            item.loadingSave = true;
                                            item.isUpdate = true;
                                            props.onClick(null, item, i);
                                          }}
                                        >
                                          Save
                                        </CustomButton>
                                      </Grid>
                                    </Box>
                                  </Grid>                                
                              }
                              
                          </Grid>
                      </Box>                          
                  </Grid>
                </Box>
            </InfoWindow>);
          }
      });
  
      return infoWindow;
    }

    const showAllPolygons = () => {
      return props.polygons.map((item, i) => {     
          let coordPath = []; 
          let name = ((item.polygon_name && item.polygon_name !== '') ? item.polygon_name : '');
          item.worker_polygon_coordinates.map((v,k) => {
            let point = {
              lat: v.latitude,
              lng: v.longitude
            };
            coordPath.push(point);
          });

          return <Polygon
            path={coordPath}
            key={i}
            editable={false}
            options={{
              strokeColor: item.polygon_color_hash,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: item.polygon_color_hash,
              fillOpacity: 0.35
            }}
            onClick={(e) => {
                if(props.onClick){
                    props.onClick(e, item, i);
                }
            }}
          >

          </Polygon>          
      });
    }

    const googleMap = () => {
      return <div>
        <GoogleMap
          ref={props.onMapMounted}
          onCenterChanged={props.onCenterChanged}
          defaultZoom={defaultZoom}
          defaultCenter={DEF_CENTER}
          defaultOptions={{
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            rotateControl: false,
            gestureHandling: "cooperative",
          }}
          center={center}
        >
        {props.drawingMode && <DrawingManager
            drawingMode={props.drawingModeShape}
            
            
            onPolygonComplete={value => console.log(getPaths(value))} />}

        {showAllPolygons()}     
        {renderInfoWindow()}   
        </GoogleMap>

        
      </div>
    }
    

    return googleMap();
});


export default CustomPolygonMap;