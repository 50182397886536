import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import {
    Box,
    Grid,
    Button,
    Typography
} from '@material-ui/core';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import Icon from '@material-ui/core/Icon';
import apiUtil from "../../api/apiUtil.jsx";
import authApi from "../../api/api.jsx";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import WMSIcon from '../../assets/img/icon-wms.png';
import LDSIcon from '../../assets/img/icon-lds.png';

class AllRole extends Component {
    static contextType = AuthContext;
    
    is_master_admin_application = ''
    constructor(props) {
        super(props);
        this.refTable = React.createRef();
        this.refTableSearch = React.createRef();

        let hiddenColumns = [];
        let columnHiddenSettingsId = 0;
        let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

        if (columnHiddenSettings !== null) {
            hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
            columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
        }


        this.state = {
            data: [],
            page: 1,
            total: 2,
            limit: 10,
            isLoading: false,
            pageSize: 22,      // status: [],
            hiddenColumnSettingId: columnHiddenSettingsId,
            hiddenColumns: hiddenColumns,
            openDialog: false,
            openDialogItem: null,
        }


    }
    componentDidMount() {
        const { user } = this.context;
        let userValue = JSON.parse(user);
        this.is_master_admin_application = userValue.owner_info.is_master_admin_application;
        this.callReadApi();
    }

    callReadApi() {
        let errorMessage = "";
        let status = 0;
        const {
            REACT_APP_AUTH_API_URL
        } = process.env;

        this.setState({
            isLoading: true
          });

        const PATH = 'admin/api/';
        const api = "roles";
        const URL = REACT_APP_AUTH_API_URL + PATH + api;
        const { accessToken } = this.context;

        let param = {
            page: this.state.page,
            take: this.state.limit,
            search: "",
            // start_date: startDate,
            // end_date: endDate,
            sorting_array: ['created_at DESC'],
        };
        axios.get(
            // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
            URL,
            {
                params: param,
                data: param,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .catch(error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch (err) {
                    errorMessage = 'Service Unavailable!'
                }
                this.setState({
                    isLoading: false
                })
            }).then((response) => {
                // console.log(response);
                if (response != null) {
                    this.setState({
                        data: response.data.result,
                        total: response.data.total,
                        isLoading: false,
                    });
                    //console.log("response sas", this.state.data);
                }

            });
    }

    callDisableApi = (row) => {
        const accessToken = window.localStorage.getItem('access_token');

        this.setState({
            isLoading: true
        }, () => {

            let param = {
                is_active: !row.is_active,
            }

            authApi.update('roles/' + row.id, param, accessToken)
                .then((result) => {
                    apiUtil.parseResult(result, (data) => {
                        this.setState({
                            isLoading: false,
                        });

                        this.callReadApi();
                        apiUtil.toast('Successfully Updated', 'check_circle');
                    }, (error, type) => {
                        this.setState({
                            data: [],
                            total: 0,
                            isLoading: false,
                        });
                    });
                });
        });
    }
    callDeleteApi = (row, callback = null) => {
        const accessToken = window.localStorage.getItem('access_token');

        this.setState({
            isLoading: true
        }, () => {


            authApi.delete('roles/' + row.id, null, accessToken)
                .then((result) => {
                    apiUtil.parseResult(result, (data) => {
                        this.setState({
                            isLoading: false,
                        }, () => {
                            if (callback) {
                                callback();
                            }
                        });
                    }, (error, type) => {
                        this.setState({
                            isLoading: false,
                        }, () => {
                            if (callback) {
                                callback();
                            }
                        });
                    });
                });
        });
    }

    customDialog = () => {
        return <CustomDialog
            open={this.state.openDialog}
            title={'Delete'}
            onClose={() => {
                this.setState({
                    openDialog: false,
                    openDialogItem: null,
                });
            }}
            onOk={() => {
                let row = this.state.openDialogItem;
                this.callDeleteApi(row, () => {
                    this.setState({
                        openDialog: false,
                        openDialogItem: null,
                    }, () => {
                        this.callReadApi();
                        apiUtil.toast('Successfully Deleted', 'check_circle');
                    });
                });
            }}
        >
            <Box>Are you sure you want to delete?</Box>
        </CustomDialog>
    }
    customStickyPageHeader = () => {
        return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
            <Grid container>
                <BreadCrumb />
            </Grid>
        </Box>
    }

    customTable = () => {

        return <Table
            ref={this.refTable}
            className={'job-table-list'}
            isLoading={this.state.isLoading}
            grouping={this.state.grouping}
            limit={this.state.limit}
            pageSize={this.state.pageSize}
            // pageSizeOptions ={this.state.pageSizeOptions}
            page={this.state.page}
            total={this.state.total}
            data={this.state.data}
            // defaultExpanded={true}
            // selection={true}
            onPageChange={(page, size) => {

                this.state.limit = size;
                this.setState({
                    page: page,
                }, () => {
                    this.callReadApi();
                    // this.callReadApi(this.refTableSearch.current.state.value);
                });
            }}
            // Toolbar={this.customToolbar}
            // Row={this.customRow}
            columns={[

                {
                    title: "Role Name",
                    field: "Role Name",
                    cellStyle: {
                        minWidth: 150,
                        maxWidth: 150
                    },
                    render: (row, type) => {
                        if (type === 'row') {
                            return (row.role_name != ' ') ? HtmlParser(row.role_name) : '';
                        } else {
                            return row;
                        }
                    }
                },
                {
                    title: "Status",
                    field: "status",
                    hidden: this.state.hiddenColumns.includes("'company_name'"),
                    render: (row, type) => {
                        if (type === 'row') {
                            return (row.is_active == true) ?
                                <Box>
                                    <Icon style={{ color: "green", width: "10px", height: "10px" }}>lens</Icon>
                                    <span> Active</span>
                                </Box>
                                :
                                <Box>
                                    <Icon style={{ color: "red", width: "10px", height: "10px" }}>lens</Icon>
                                    <span> Active</span>
                                </Box>;
                        } else {
                            return row;
                        }
                    }
                },
                {
                    title: "Action",
                    field: "action",
                    cellStyle: {
                        minWidth: 120,
                        maxWidth: 120
                    },
                    render: (row, type) => {
                        if (type === 'row') {
                            return <Box>
                                
                                {(row.is_system_role == true || row.role_name == "Super Admin Role") ? 
                                <>
                                <Button style={{backgroundColor: '#d3d3d3' }}
                                disabled={true}
                            ><Icon style={{ color: "#FFFFFF" }}>edit</Icon></Button>&nbsp;
                                <Button style={{ backgroundColor: '#d3d3d3' }}
                                    disabled={true}
                                ><Icon style={{ color: "#FFFFFF" }}>power_settings_new</Icon></Button>&nbsp;
                                 <Button style={{ backgroundColor: '#d3d3d3' }}
                                    disabled={true}
                                ><Icon style={{ color: "#FFFFFF" }}>delete_forever</Icon></Button>
                                </>
                                :
                                <>
                                <Button style={this.is_master_admin_application == true ? { backgroundColor: '#2e86aa' } : { backgroundColor: '#d3d3d3' }}
                                onClick={() => {
                                    this.props.history.push({
                                        pathname: '/accounts/add-role',
                                        state: {
                                            id: row.id
                                        }
                                    });
                                }}
                                disabled={this.is_master_admin_application == true ? false : true}
                            ><Icon style={{ color: "#FFFFFF" }}>edit</Icon></Button>&nbsp;
                                <Button style={this.is_master_admin_application == true? ((row.is_active == true) ? { backgroundColor: '#009900' } : { backgroundColor: '#333333' }) : {backgroundColor: '#d3d3d3'}}
                                    onClick={() => {
                                        this.callDisableApi(row)
                                    }}
                                    disabled={this.is_master_admin_application == true ? false : true}
                                ><Icon style={{ color: "#FFFFFF" }}>power_settings_new</Icon></Button>&nbsp;
                                <Button style={this.is_master_admin_application == true ? { backgroundColor: '#D11A2A' } : { backgroundColor: '#d3d3d3' }}
                                    onClick={() => {
                                        this.setState({
                                            openDialog: true,
                                            openDialogItem: row,
                                        });
                                    }}
                                    disabled={this.is_master_admin_application == true ? false : true}
                                ><Icon style={{ color: "#FFFFFF" }}>delete_forever</Icon></Button>
                                </>
                        }
                            </Box>
                        } else {
                            return row;
                        }
                    }
                },
            ]}
        />
    }

    render() {
        return <Box className="dashboard-page">

            <Card className={'job-listing-step'}>
                <Grid container>
                    <Grid xs={6} lg={6}><h1>List of available roles</h1></Grid>
                    <Grid xs={6} lg={6} style={{ textAlign: 'right' }}>
                        <Button style={{ backgroundColor: '#2E86AA' }}>
                            <NavLink
                                to={{
                                    pathname: "/accounts/add-role",
                                    // state : {id:row.id} // your data array of objects
                                }}
                                style={{ textDecoration: 'none', textTransform: "lowercase", color: "#FFFFFF" }}
                            >Add Role</NavLink>
                        </Button></Grid>
                </Grid>
                {this.customTable()}
            </Card>
            {this.customDialog()}
        </Box>;
    }
}

export default AllRole;
