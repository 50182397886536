import React, { useEffect, useState, useCallback } from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Signin from '../../views/Account/Signin.jsx';
import SignUp from "../../views/Account/SignUp.jsx";
import SignUpSuccess from "../../views/Account/SignUpSuccess.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import Api from "../../api/api.jsx";
import { CountryDataService } from '../../data/country-data';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import countryList from '../../assets/countryList.json';

const theme = createMuiTheme({
  /* RESPONSIVE -> It is also necessary to change at _mixins.scss*/
  breakpoints: {
    values: {
      xs: 0, 
      sm: 768, 
      md: 992, 
      lg: 1200, 
      xl: 1366
    }
  },
  palette: {
    primary: {
      light: '#83d6d3',
      main: '#2e86aa',
      // main: '#64CCC9',
      dark: '#468e8c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3f464c',
      main: '#101820',
      dark: '#0b1016',
      contrastText: '#fff',
    }
  }
});

const { 
  REACT_APP_COOKIE_ACCESS_TOKEN,
  REACT_APP_COOKIE_REFRESH_TOKEN,
  REACT_APP_COOKIE_TOKEN_TYPE,
  REACT_APP_COOKIE_TOKEN,
  REACT_APP_COOKIE_USER
} = process.env;

export const AuthContext = React.createContext();


export default ({ children }) => {
  const prevAuth = window.localStorage.getItem('authenticatedAuth') || false;
  const prevAccessToken = window.localStorage.getItem('access_token') || null;
  const prevRefreshToken = window.localStorage.getItem('refresh_token') || null;
  const prevTokenType = window.localStorage.getItem('token_type') || null;
  const prevCurrentRole = window.localStorage.getItem('current_role') || null;
  const prevToken = window.localStorage.getItem('token') || null;
  const prevUser = window.localStorage.getItem('user') || null;
  // const prevIsCustomerRequired = window.localStorage.getItem('isCustomerRequired') || true;
  // let prevIsOnBoarding = window.localStorage.getItem('isOnBoarding') || false;
  // if (prevIsOnBoarding === "false") {
  //   prevIsOnBoarding = false;
  // }
  // const prevOnBoardingCustomer = window.localStorage.getItem('onBoardingCustomer') || null;
  // const prevOnBoardingVehicle = window.localStorage.getItem('onBoardingVehicle') || null;
  // const prevOnBoardingDriver = window.localStorage.getItem('onBoardingDriver') || null;
  // const prevOnBoardingJob = window.localStorage.getItem('onBoardingJob') || null;

  const prevAppPermission = window.localStorage.getItem('appPermission') || [];
  const prevUniqueRolePermissions = window.localStorage.getItem('uniqueRolePermissions') || [];

  const [authenticated, setAuthenticated] = useState(prevAuth);
  const [accessToken, setAccessToken] = useState(prevAccessToken);
  const [currentRole, setCurrentRole] = useState(prevCurrentRole);
  const [refreshToken, setRefreshToken] = useState(prevRefreshToken);
  const [tokenType, setTokenType] = useState(prevTokenType);
  const [token, setToken] = useState(prevToken);
  const [user, setUser] = useState(prevUser);
  const [tokenCookie, setTokenCookie] = useState(false);
  // const [isCustomerRequired, setIsCustomerRequired] = useState(prevIsCustomerRequired);
  // const [isOnBoarding, setIsOnBoarding] = useState(prevIsOnBoarding);
  // const [onBoardingCustomer, setOnBoardingCustomer] = useState(prevOnBoardingCustomer);
  // const [onBoardingVehicle, setOnBoardingVehicle] = useState(prevOnBoardingVehicle);
  // const [onBoardingDriver, setOnBoardingDriver] = useState(prevOnBoardingDriver);
  // const [onBoardingJob, setOnBoardingJob] = useState(prevOnBoardingJob);

  const [appPermission, setAppPermission] = useState(prevAppPermission);
  const [uniqueRolePermissions, setUniqueRolePermissions] = useState(prevUniqueRolePermissions);


  const clearStorage = () => {
    setAuthenticated(false);
    setAccessToken('');
    setCurrentRole(null);
    setRefreshToken('');
    setTokenType('');
    setToken(null);
    setUser(null);
    // setIsCustomerRequired(true);
    // setIsOnBoarding(true);
    // setOnBoardingCustomer(null);
    // setOnBoardingVehicle(null);
    // setOnBoardingDriver(null);
    // setOnBoardingJob(null);
    setAppPermission([]);
    setUniqueRolePermissions([]);
    window.localStorage.clear();

    apiUtil.deleteCookie(REACT_APP_COOKIE_ACCESS_TOKEN);
    apiUtil.deleteCookie(REACT_APP_COOKIE_REFRESH_TOKEN);
    apiUtil.deleteCookie(REACT_APP_COOKIE_TOKEN_TYPE);
    apiUtil.deleteCookie(REACT_APP_COOKIE_TOKEN);
    apiUtil.deleteCookie(REACT_APP_COOKIE_USER);
  };

  // const callReadSettingsApi = useCallback(() => {

  //   let driver_label = localStorage.getItem('global_driver_label');
  //   let manpower_label = localStorage.getItem('global_manpower_label');
  //   let country_phone = localStorage.getItem('country_phone');
  //   let access_token = localStorage.getItem('access_token');

  //   Api.read('application_company_settings', {}, access_token)
  //     .then(result => {
  //         apiUtil.parseResult(result, data => {
              
  //             let prevFilterDataId = 0;

  //             data.result.filter((filterData) => {
           
  //               if(filterData.group_name === "Country") {
  //                 let jsonParse = JSON.parse(filterData.json_data);
  //                if (jsonParse !=  null) {
  //                 let cloneCountryList =   countryList.map(object => ({ ...object }));
  //                 let timeZoneList = cloneCountryList.filter((oldCountry) => {
  //                   if(jsonParse.name === oldCountry.value) {
  //                       oldCountry.value = oldCountry.offset;
  //                       oldCountry.label = "GMT "+oldCountry.offset + "("+ oldCountry.identifier+")";;
  //                       return oldCountry;
  //                   }
  //                 });
  //                 // localStorage.setItem('TimeZoneList', JSON.stringify(timeZoneList));
  //                 // localStorage.setItem('TimeZone', jsonParse.offset);
  //               }
  //               }

  //               if (filterData.rule === "job_column_settings") {
  //                 if (filterData.id > prevFilterDataId) {
  //                   // localStorage.setItem("job_column_settings", JSON.stringify(filterData));
  //                   prevFilterDataId = filterData.id;
  //                 }
  //               }
  //             })
  //             driver_label = data.result.filter(
  //               (setting) => {
  //                 localStorage.setItem("channel", setting.application_company_id.toString())
  //                 return setting.rule === "driver_label";
  //               }
  //             )[0].view_name
  //             driver_label = driver_label? driver_label : "Driver";
  //             // localStorage.setItem('global_driver_label', driver_label);
              
  //             let is_customer_required = data.result.filter(x => x.rule === "is_customer_required")
  //             if(is_customer_required && is_customer_required.length > 0){
  //               setIsCustomerRequired(is_customer_required[0].enable);
  //             } else {
  //               setIsCustomerRequired(false);
  //             }
              
  //             manpower_label = data.result.filter(
  //               (setting) => {
  //                 return setting.rule === "manpower_label";
  //               }
  //             )[0].view_name
  //             manpower_label = manpower_label? manpower_label : "Manpower";
  //             // localStorage.setItem('global_manpower_label', manpower_label);
    
  //             country_phone = data.result.filter(
  //               (setting) => {
  //                 return setting.rule === "country_code";
  //               }
  //             )[0].json_data
  //             let found = false;
  //             if(country_phone){
  //               const countries = new CountryDataService().getPhoneCode(); 
  //               let phone = JSON.parse(country_phone);
  //               countries.forEach(objCountry=>{
  //                 if(objCountry.countryCode.toUpperCase() === phone.code){
  //                   country_phone = objCountry.dialCode;
  //                   found = true
  //                 }
  //               });
  //             }
    
  //             // localStorage.setItem('country_phone', found? country_phone:"");
  //             let company_id = data.result[0].application_company_id;
  //             window.localStorage.setItem('company_id', company_id);

  //         })
  //     })

  //   Api.read('application_unit_measurements', {}, accessToken)
  //   .then(result => {
  //       apiUtil.parseResult(result, data => {
  //           const res = (data.result && data.result.length > 0) ? data.result : []
  //           res.forEach( item => {
  //             if(item.module && item.module != "" && item.is_active){
  //               // localStorage.setItem(item.measurement_type, item.measurement);
  //             }
  //           })
  //       })
  //   })
  // },[accessToken])

  const loginFromCookie = (accessToken) => {
    Api.read('profile/admins', {}, accessToken).then(result => {
      
      apiUtil.parseResult(result, data => {
        window.localStorage.setItem('authenticatedAuth', true);
        window.localStorage.setItem('access_token', accessToken);
        window.localStorage.setItem('current_role', currentRole);

        let refreshToken = apiUtil.getCookie(REACT_APP_COOKIE_REFRESH_TOKEN);
        if (refreshToken !== null) {
          window.localStorage.setItem('refresh_token', refreshToken);
        }

        let tokenType = apiUtil.getCookie(REACT_APP_COOKIE_TOKEN_TYPE);
        if (tokenType !== null) {
          window.localStorage.setItem('token_type', tokenType);
        }

        let token = apiUtil.getCookie(REACT_APP_COOKIE_TOKEN);
        if (token !== null) {
          window.localStorage.setItem('token', token);
        }

        let user = {
          status: true,
          token: token,
          owner_info: data.result,
          result: data.result
        };

        window.localStorage.setItem('user', JSON.stringify(user));
        window.location.replace("/");
      });

    }, (error, type) => {
      window.location.href = "/sign-in";
    });
  }

  const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split(';')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

let url = getQueryStringParams(window.location.pathname);
// const { clearStorage } = useContext(AuthContext);
  const show = url.show;
  const email = url.email;
  const resetToken = url.token;
  const setPasswordId = url.id;
  const ref_company_id = url.ref_company_id
  const ref_admin_id = url.ref_admin_id


  useEffect(
    () => {
      // set company settings and unit measurements 
      // const initAppSettings = () => {
      //   callReadSettingsApi()
      // }
      if(show == "setpassword") {
        setAuthenticated(false);
      }
      if(authenticated){
        window.localStorage.setItem('authenticatedAuth', authenticated);
        window.localStorage.setItem('access_token', accessToken);
        window.localStorage.setItem('current_role', currentRole);
        window.localStorage.setItem('refresh_token', refreshToken);
        window.localStorage.setItem('token_type', tokenType);
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', user);
        // window.localStorage.setItem('isCustomerRequired', isCustomerRequired);
        // window.localStorage.setItem('isOnBoarding', isOnBoarding);
        // window.localStorage.setItem('onBoardingCustomer', onBoardingCustomer);
        // window.localStorage.setItem('onBoardingVehicle', onBoardingVehicle);
        // window.localStorage.setItem('onBoardingDriver', onBoardingDriver);
        // window.localStorage.setItem('onBoardingJob', onBoardingJob);
        // initAppSettings();

        if (!tokenCookie && (accessToken !== null && refreshToken !== null && tokenType !== null && token !== null && user !== null)) {
          apiUtil.setCookie(REACT_APP_COOKIE_ACCESS_TOKEN, accessToken);
          apiUtil.setCookie(REACT_APP_COOKIE_REFRESH_TOKEN, refreshToken);
          apiUtil.setCookie(REACT_APP_COOKIE_TOKEN_TYPE, tokenType);
          apiUtil.setCookie(REACT_APP_COOKIE_TOKEN, token);
          apiUtil.setCookie(REACT_APP_COOKIE_USER, user);
          window.localStorage.setItem('has_logged_in_cookie', true);
          setTokenCookie(true);
        }
      } else {
        let accessTokenCookie = apiUtil.getCookie(REACT_APP_COOKIE_ACCESS_TOKEN);
        let checkLoginCookie = window.localStorage.getItem('has_logged_in_cookie') || null;

        if (accessTokenCookie !== null && checkLoginCookie === null) {
          loginFromCookie(accessTokenCookie);
        }

        window.localStorage.removeItem('authenticatedAuth');
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('current_role');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        
        // window.localStorage.removeItem('isCustomerRequired');
        // window.localStorage.removeItem('isOnBoarding');
        // window.localStorage.removeItem('onBoardingCustomer');
        // window.localStorage.removeItem('onBoardingVehicle');
        // window.localStorage.removeItem('onBoardingDriver');
        // window.localStorage.removeItem('onBoardingJob');
      }
      
    },
    // [authenticated, currentRole, token, accessToken, refreshToken, tokenType, user, tokenCookie, isCustomerRequired, isOnBoarding, onBoardingCustomer, onBoardingVehicle, onBoardingDriver, onBoardingJob, appPermission,uniqueRolePermissions, callReadSettingsApi]
    [authenticated, currentRole, token, accessToken, refreshToken, tokenType, user, tokenCookie, appPermission,uniqueRolePermissions]
  );


  const defaultContext = {
    clearStorage,
    authenticated,
    setAuthenticated,
    token,
    currentRole,
    setCurrentRole,
    setToken,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    tokenType,
    setTokenType,
    user,
    setUser,
    // isCustomerRequired,
    // setIsCustomerRequired,
    // isOnBoarding,
    // setIsOnBoarding,
    // onBoardingCustomer,
    // setOnBoardingCustomer,
    // onBoardingVehicle,
    // setOnBoardingVehicle,
    // onBoardingDriver,
    // setOnBoardingDriver,
    // onBoardingJob,
    // setOnBoardingJob,
    appPermission,
    setAppPermission,
    uniqueRolePermissions,
    setUniqueRolePermissions
  };

  if ( window.location.pathname.includes('/signup') && ref_company_id  && ref_admin_id) {
    return (
      <MuiThemeProvider theme={theme}>
        <AuthContext.Provider value={defaultContext}>
          
            <div className="App">
              <BrowserRouter>
                <Switch>
                  <Route exact path="/">
                    <Redirect to={"/Sign-in"} />
                  </Route>
                  <Route path="/Sign-in" component={Signin} />
                  <Route path={window.location.pathname}  component={SignUp} />
                  <Route path="/sign-up-success"  component={SignUpSuccess} />
  
                  <Route component={Signin} />
                </Switch>
              </BrowserRouter>
            </div>
        </AuthContext.Provider>
      </MuiThemeProvider>
    );
  } else {
    return (
      <MuiThemeProvider theme={theme}>
        <AuthContext.Provider value={defaultContext}>
          {
            authenticated
            ?
            children
            :
            <div className="App">
              <BrowserRouter>
                <Switch>
                  <Route exact path={["/","/dashboard","/settings","/setting-profile","/accounts/all-account","/accounts/add-account","/accounts/all-role","/accounts/add-role","/device","/company-profile","/invoice","/payment-history","/payment","/invoice-detail"]}>
                    <Redirect to={"/Sign-in"} />
                  </Route>

                  <Route path="/Sign-in" component={Signin} />
                  <Route path="/Sign-up"  component={SignUp} />
                  <Route path="/sign-up-success"  component={SignUpSuccess} />
  
                  <Route component={Signin} />
                </Switch>
              </BrowserRouter>
            </div>
          }
        </AuthContext.Provider>
      </MuiThemeProvider>
    );
  }
};