import React from 'react';

import SunEditor from 'suneditor-react';
import plugins from 'suneditor/src/plugins'
import 'suneditor/dist/css/suneditor.min.css';

import {
    Box,
} from '@material-ui/core';


const RSunEditor = (props) => {
    const [className] = React.useState(props.className ? props.className : '')
    const [placeholder] = React.useState(props.placeholder ? props.placeholder : '')

    const [defaultValue, setDefaultValue] = React.useState(props.defaultValue)
    const [value, setValue] = React.useState(props.value)


    React.useEffect(() => {
        setDefaultValue(props.defaultValue);
    }, []);

      
    const onChange = (value) => {
        setValue(value)

        if (props.onChange) {
            props.onChange(value);
        }
    }

    
    return <Box className={"rich-sun-editor-component " + className}>
        <SunEditor
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            height={400}
            mode={'classic'}
            rtl={false}
            tabDisable={false}
            plugins={plugins.align}
            buttonList={['align']}
            onChange={onChange}
        />
    </Box>
}


export default RSunEditor;