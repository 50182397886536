import React, { useRef, useState } from 'react';

import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import apiUtil from '../../api/apiUtil.jsx';
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import CustomCheckbox from '../../components/CustomFields/CustomCheckbox.jsx';
//import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import authApi from '../../api/Authentication.jsx';
//import apiUtil from '../../api/apiUtil.jsx';

import trackerLogo from '../../assets/img/logo_black.png';
import jobIcon from '../../assets/img/cms/job.png';
import customizationIcon from '../../assets/img/cms/customization.png';
import mobileIcon from '../../assets/img/cms/mobile.png';
import reportIcon from '../../assets/img/cms/report.png';
import resourceIcon from '../../assets/img/cms/resources.png';
import trackingIcon from '../../assets/img/cms/tracking.png';

const styles = {
  error: {
      color: 'red',
      fontSize: 'small'
  }
};
export default function SignUp() {
  //const { setAuthenticated, setToken, setUser } = useContext(AuthContext);
  let history = useHistory();


 
  const [reCAPTCHA, setReCAPTCHA] = useState(null);
  const [singUpsuccess, setSingUpsuccess] = useState(false);


  const [validatedEmail, setValidatedEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);

  const [validatedCompanyName, setValidatedCompanyName] = useState(false);
  const [errorCompanyName, setErrorCompanyName] = useState(null);
  
  const [validatedFullName, setValidatedFullName] = useState(false);
  const [errorFullName, setErrorFullName] = useState(null);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const emailInput = useRef();
  const companyNameInput = useRef();
  const fullNameInput = useRef();
  // const acceptterms = useRef();
  const getQueryStringParams = (query) => {
    return query
          ? (/^[?#]/.test(query) ? query.slice(1) : query)
              .split(';')
              .reduce((params, param) => {
                      let [key, value] = param.split('=');
                      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                      return params;
                  }, {}
              )
          : {}
  };

  let url = getQueryStringParams(window.location.pathname);
  const ref_company_id = url.ref_company_id
  const ref_admin_id = url.ref_admin_id


  const onChageReCAPTCHA = (value) => {
    setReCAPTCHA(value)
  }
  
  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    setError(null);

    setValidatedEmail(false);
    setErrorEmail(null);


    setValidatedCompanyName(false);
    setErrorCompanyName(null);    
    
    setValidatedFullName(false);
    setErrorFullName(null);
    
    if(emailInput.current.lastChild.firstChild.value === ''){
      setValidatedEmail(true);
      setErrorEmail('Please enter your email address.');
    }

    if(companyNameInput.current.lastChild.firstChild.value === ''){
      setValidatedCompanyName(true);
      setErrorCompanyName('Please enter your CompanyName.');
    }    
    if(fullNameInput.current.lastChild.firstChild.value === ''){
      setValidatedFullName(true);
      setErrorFullName('Please enter your FullName.');
    }
    // if(acceptTerm == false){
    //     setValidatedAcceptTerms(true);
    //     setErrorAcceptTerm('Please accept term and condition');
    // }
    if (form.checkValidity() === true) {
      
      setIsLoading(true);
    
      let data = {
        "admins": [
          {
            "email": emailInput.current.lastChild.firstChild.value,
            "first_name": fullNameInput.current.lastChild.firstChild.value,
            "password": ""
          }
        ],
        "captcha_response": reCAPTCHA,
        "company_name": companyNameInput.current.lastChild.firstChild.value,
        "contact_no": "",
        "email": emailInput.current.lastChild.firstChild.value,
        "is_default_password": true,
        "ref_admin_id": ref_admin_id,
        "ref_company_id": ref_company_id
      };
      
      authApi.signup(data)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data.status === true) {
              setSingUpsuccess(true)
            }
            // history.push('/sign-up-success');
          }, (error, type) => {
            
            if (error['email']) {
              setValidatedEmail(true);
              setErrorEmail(`Email ${error['email']}.`);
            }

            if (error['company_name']) {
              setValidatedCompanyName(true);
              setErrorCompanyName(`Company name ${error['company_name']}.`);
            }
            
            if (!error['email'] && !error['company_name']) {
              setError({ title: JSON.stringify(error) });
            }
            
            setIsLoading(false);
          });
      });
    }
  };

  
  return (
    <Grid container>
    <div className="signup container">
       
      <Grid className="signup-left" component="main">
    <div className="form-wrapper">
      {singUpsuccess === false ? (<form noValidate onSubmit={handleSubmit}>

          <Box textAlign="center" mb={3}>
          <div className="logo">
              <img src={trackerLogo} alt="" width="220px" />
            </div>
            <div className="form-title">
            BEGIN YOUR FREE 30 DAYS TRIAL
            </div>
            <div className="form-description">
            Try Evfy Tracker for FREE with unlimited devices for 30 days then 
            decide how many devices works best for your business.
            </div>
            {
              error && !isLoading
              &&
              <Box textAlign="left" mt={3} mb={3}>
                <Alert severity="error">
                  {error.title && <b>{error.title}</b>}
                  {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
                    return <div key={i}>{e}</div>
                  })}
                </Alert>
              </Box>

              
            }
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="fullname"
              label="Full name"
              name="fullname"
              autoFocus
              error={validatedFullName} 
              helperText={errorFullName}
              ref={fullNameInput}
            /> 
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              error={validatedEmail} 
              helperText={errorEmail}
              ref={emailInput}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="companyname"
              label="Company Name"
              id="companyname"
              ref={companyNameInput}
              error={validatedCompanyName} 
              helperText={errorCompanyName}

            />

              <ReCAPTCHA
                // sitekey="6Lc9IV0dAAAAAKpfh-WYyQiQuFymk0MI3iyDZ1is"
                sitekey="6LfcYCMdAAAAAEtci36XI0kYA68wcvq9c_tysPwZ"
                onChange={onChageReCAPTCHA}
              />
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained" 
            color="primary"
            // disabled={(reCAPTCHA == null ) ? true : false}
          >
            <Box pl={2} pr={2} style={{ color: 'white' }}>SIGN UP AND START Evfy Tracker</Box>
            {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
          </Button>

          <div className="sign-in">Already have an account? 
          <a variant="contained" color="primary" href="/Sign-in">
            Login
          </a></div>
          </form> ): (
          <Box textAlign="center" mb={3}>
          <div className="logo">
              <img src={trackerLogo} alt="" width="220px" />
            </div>
            <div className="form-description"  color="success.main">
                <Box color="success.main"> A confirmation mail has been sent to you. Please check your email.</Box>
                 
                </div>
      
              <a href="https://www.evfy.sg/" style={{marginLeft:'-200px'}}>Return to Evfy Website</a>
          </Box>
       
        )} 
      </div>
      
    </Grid>
    <Grid className="signup-right">
    <div>
      {/* <div className="top-menu">
        <div className="logo"></div>
        <div className="signin-acc"> 
        <div>Already have an account?</div>
        <Button variant="contained" color="primary" href="/Sign-in">
          Login
        </Button>
        </div>
      </div> */}
      <div className="form-warp">
          <div className="form-title">
          <h2>TRY IT AS IF YOU OWN IT</h2>
          <p>Get full features for 30 days, no credit card required!</p>
          </div>
          <div className="form-description">
          Evfy Tracker's feature will always be available to you. No locked features, no limitation. 
          All you need to do is at least subscribe to one device to continue using our service upon 
          trial expiration.
          </div>
      </div>
      <Grid container className="right-Group">
        <Grid item xs={12} md={12} lg={12} className="right-header">MAKE THE BEST OUT OF 30 DAYS TRIAL</Grid>
        <Grid item xs={12} md={12} lg={12} className="right-title">To fully utilize Evfy Tracker's feature within the trial period</Grid>
        <Grid item xs={6} md={4} lg={4} className="right-warper">
        <div className="right-warper-img"><img src={jobIcon} alt="" width="80%"/></div>
            <h2>CREATE JOB</h2>
            <p>No limits to job creation. Create as much as you like.</p>
        </Grid>
        <Grid item xs={6} md={4} lg={4} className="right-warper middle">
        <div className="right-warper-img"><img src={resourceIcon} alt="" width="80%"/></div>
            <h2>ASSIGN JOBS</h2>
            <p>Assign your bulk jobs to drivers automatically.</p>
        </Grid>
        <Grid item xs={6} md={4} lg={4} className="right-warper ">
        <div className="right-warper-img"><img src={customizationIcon} alt="" width="80%"/></div>
            <h2>CUSTOMIZE FORMS</h2>
            <p>Create your custom labels and custom fields that suits you.</p>
        </Grid>
        <Grid item xs={6} md={4} lg={4} className="right-warper">
        <div className="right-warper-img"><img src={trackingIcon} alt="" width="80%"/></div>
            <h2>TRACK DRIVERS</h2>
            <p>Track up to 30 drivers of their jobs and locations.</p>
        </Grid>
        <Grid item xs={6} md={4} lg={4} className="right-warper middle">
        <div className="right-warper-img"><img src={mobileIcon} alt="" width="80%"/></div>
            <h2>MOBILE APPLICATION</h2>
            <p>Get your drivers to do their work with our mobile app.</p>
        </Grid>
        <Grid item xs={6} md={4} lg={4} className="right-warper">
        <div className="right-warper-img"><img src={reportIcon} alt="" width="80%"/></div>
            <h2>GENERATE REPORT</h2>
            <p>Produce reports of your drivers and customers.</p>
        </Grid>
      </Grid>
    </div>
    </Grid>
    </div>
    </Grid>
  );
}