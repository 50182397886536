import React, {useState, useCallback, useEffect, useContext} from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import Api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader.jsx';
import Card from '../../components/Card/Card.jsx';
import CustomSelectOption from '../../components/CustomFields/CustomSelectOption';
import CustomSwitch from '../../components/CustomFields/CustomSwitch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';

import { 
    Box,
    Grid,
    Container,
    Divider,
    IconButton,
    Tabs,
    Tab
  } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CustomLabel from '../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import countryList from '../../assets/countryList.json';

import RSunEditor from '../../components/RichTextEditor/RSunEditor.jsx';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            {children}
          </Box>
        )}
      </div>
    );
}
const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
}
export const Settings = () => {
    const history = useHistory();
    const location = useLocation();
    const { accessToken, setIsCustomerRequired, isOnBoarding } = useContext(AuthContext)
    const pathName = (location && location.state && location.state.pathname && location.state.pathname.length > 0) ? location.state.pathname : "/";
    const [value, setValue] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const allCountry  = countryList.filter((v,i,a)=>a.findIndex(t=>(t.label === v.label))===i)

    //pre section
    const preScalePrefWeight = window.localStorage.getItem('Weight') || null;
    const preScalePrefVol = window.localStorage.getItem('Volume') || null;
    const preScalePrefLength = window.localStorage.getItem('Length') || null;
    const preScalePrefDistance = window.localStorage.getItem('Distance') || null;
    const preScalePrefSpeed = window.localStorage.getItem('Speed') || null;
    const preScalePrefDateFormat = window.localStorage.getItem('Date Format') || null;
    const preScalePrefTimeFormat = window.localStorage.getItem('Time Format') || null;
    const preCountry = window.localStorage.getItem('Country') || null;
    const preTimeZone = window.localStorage.getItem('TimeZone') || null;
    const preTimeZoneList = window.localStorage.getItem('TimeZoneList')? JSON.parse(window.localStorage.getItem('TimeZoneList')) : [];
    // general section
    const [country, setCountry] = useState(preCountry)
    const [countryCode, setCountryCode] = useState("")
    const [countryIdentifier, setCountryIdentifier] = useState("")
    const [timeZone, setTimeZone] = useState(preTimeZone)
    const [timeZoneList, setTimeZoneList] = useState(preTimeZoneList)
    // const [timeZone, setTimeZone] = useState("Singapore")
    const [driverLabel, setDriverLabel] = useState("")
    const [manPowerLabel, setManPowerLabel] = useState("")

    const [twilioSmsEnabled, setTwilioSmsEnabled] = useState(false)
    const [twilioSmsKey, setTwilioSmsKey] = useState("")
    const [fromNumber, setFromNumber] = useState("")
    const [twilioSmsSecret, setTwilioSmsSecret] = useState("")
    // formatting preference
    const [deliveryFormattingInitial, setDeliveryFormattingInitial] = useState("")
    const [deliveryFormattingYear, setDeliveryFormattingYear] = useState("")
    const [deliveryFormattingMonth, setDeliveryFormattingMonth] = useState("")
    const [deliveryFormattingDay, setDeliveryFormattingDay] = useState("")
    const [deliveryNoSeparator, setDeliveryNoSeparator] = useState("")
    const [deliveryNoSeparatorDash, setDeliveryNoSeparatorDash] = useState("")
    const [deliveryNoSeparatorSlash, setDeliveryNoSeparatorSlash] = useState("")
    const [deliveryNoSeparatorDot, setDeliveryNoSeparatorDot] = useState("")
    const [scalePrefWeight, setScalePrefWeight] = useState(preScalePrefWeight)
    const [scalePrefSpeed, setScalePrefSpeed] = useState(preScalePrefSpeed)
    const [scalePrefDistance, setScalePrefDistance] = useState(preScalePrefDistance)
    const [scalePrefLength, setScalePrefLength] = useState(preScalePrefLength)
    const [scalePrefVol, setScalePrefVol] = useState(preScalePrefVol)
    const [scalePrefDateFormat, setScalePrefDateFormat] = useState(preScalePrefDateFormat)
    const [scalePrefDateExampleFormat, setScalePrefDateExampleFormat] = useState("")
    const [scalePrefTimeFormat, setScalePrefTimeFormat] = useState(preScalePrefTimeFormat)
    const [enableCustomer, setEnableCustomer] = useState(false);
    const [enableManPower, setEnableManPower] = useState(false);
    const [enableManPowerAcknowledged, setEnableManPowerAcknowledged] = useState(false);
    const [enableManPowerInProgress, setEnableManPowerInProgress] = useState(false);
    const [enableManPowerCompleted, setEnableManPowerCompleted] = useState(false);
    const [enableManPowerFailed, setEnableManPowerFailed] = useState(false);
    const [currentApplicationData, setCurrentApplicationData] = useState([]);
    const [currentMeasurementData, setCurrentMeasurementData] = useState([]);

    //Templates
    const [ emailTemplates, setEmailTemplates ] = useState([]);
    const [ smsTemplates, setSmsTemplates ] = useState([]);


    //
    // end general section
    // http://auth-api.taskk.me/admin/api/application_company_settings
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const callReadSettingsApi = useCallback(() => {
        
        Api.read('application_company_settings', {}, accessToken)
        .then(result => {
            apiUtil.parseResult(result, data => {

              
                const res = (data.result && data.result.length > 0) ? data.result : []
                res.forEach( obj => {
                    switch (obj.rule) {
                        case "manpower_label":
                            setManPowerLabel(obj.view_name)
                            break;
                        case "driver_label":
                            setDriverLabel(obj.view_name)
                            break;
                        case "sms_credentials":
                        case "twilio_sms_credentials":
                            let twilio = JSON.parse(obj.json_data);
                            setTwilioSmsEnabled(obj.enable ? obj.enable : false)
                            setTwilioSmsKey(twilio.account_key ? twilio.account_key : "")
                            setFromNumber(twilio.from_number ? twilio.from_number : "")
                            setTwilioSmsSecret(twilio.account_secret ? twilio.account_secret : "")
                            break;
                        default:
                            break;
                    }
                    if(obj.group_name === "Customer") {
                        setEnableCustomer(obj.enable);
                    }
                    if(obj.view_name === "In Progress" && obj.group_name === "mobile") {
                        setEnableManPowerInProgress(obj.enable);
                    }
                    if(obj.view_name === "Completed" && obj.group_name === "mobile") {
                        setEnableManPowerCompleted(obj.enable);
                    }
                    if(obj.view_name === "Acknowledged" && obj.group_name === "mobile") {
                        setEnableManPowerAcknowledged(obj.enable);
                    }  
                    if(obj.view_name === "Failed" && obj.group_name === "mobile") {
                        setEnableManPowerFailed(obj.enable);
                    }
                    if(obj.description === "Manpower can update this status" && obj.group_name === "mobile") {
                        setEnableManPower(obj.enable);
                    }
                    if(obj.group_name === "Delivery Order") {
                        let ruleFormat = obj.rule_format;
                        let numberSeperator =  ruleFormat.includes("-") ? "-": ruleFormat.includes("/")? "/": ".";
                        setDeliveryNoSeparator(numberSeperator);
                        (numberSeperator === "-" ? setDeliveryNoSeparatorDash(true): (numberSeperator === "/")? setDeliveryNoSeparatorSlash(true): setDeliveryNoSeparatorDot(true));
                        let transformArray = ruleFormat.split(numberSeperator);
                        let FormattingInitial = transformArray[0];
                        let formattingData = transformArray[1].replace("[[", "").replace("]]", "");
                        let formattingYear = formattingData.includes("Y") ? true : false;
                        let formattingMonth = formattingData.includes("b") ? true : false; 
                        let formattingDay = formattingData.includes("d") ? true : false;
                        setDeliveryFormattingYear(formattingYear);
                        setDeliveryFormattingMonth(formattingMonth);
                        setDeliveryFormattingDay(formattingDay);
                        setDeliveryFormattingInitial(FormattingInitial);

                    }
                    // if(obj.id === 1042 ){
                        
                    // }
                    // if(obj.id)
                })
                setCurrentApplicationData(res);
            })
        })  
        Api.read('application_unit_measurements', {}, accessToken)
        .then(result => {
            // console.log(result)
            apiUtil.parseResult(result, data => {
                const res = (data.result && data.result.length > 0) ? data.result : []
                setCurrentMeasurementData(res);
            })
        }) 

    },[])
    const callUpdateApi = ( row, callback = null) => {
        setIsLoading(true);
        Api.create('batch_update/application_company_settings', row, accessToken)
        .then((result) => {
  
            apiUtil.parseResult(result, (data) => {
                setIsLoading(false);
                if(data.successfully_update.length > 0) {
                    data.successfully_update.filter(resultData => {
                        if(resultData.group_name === "Country") {
                            localStorage.setItem('Country', country);
                            localStorage.setItem('TimeZoneList', JSON.stringify(timeZoneList));
                            localStorage.setItem('TimeZone', timeZone);
                        }

                        if(resultData.rule === "is_customer_required"){
                            localStorage.setItem("isCustomerRequired", resultData.enable);
                            setIsCustomerRequired(resultData.enable);
                        }
                    });

                    // setIsCustomerRequired(enableCustomer);
                }
                if(callback) {
                    callback();
                }
            }, (error, type) => {
                console.log("error", error)
                setIsLoading(false);
                if(callback) {
                    callback();
                }
            });
        }).catch(
            error => console.log(error)
        )
    }
    
    

    const callUpdateMeasurements = (row, callback = null) => {
        setIsLoading(true);
        if(row.measurements_data.length > 0) {
            Api.create('batch_update/application_unit_measurements', row, accessToken)
            .then((result) => {
                apiUtil.parseResult(result, data => {
                    setIsLoading(false);
                    const res = (data.successfully_update && data.successfully_update.length > 0) ? data.successfully_update : []
                    if(res.length > 0) {
                        res.forEach( item => {
                            if(item.module && item.module !== "" && item.is_active){
                              localStorage.setItem(item.measurement_type, item.measurement);
                            } else {
                                console.log("not active");
                            }
                          })
                    }
                  
                })
            }).catch(
                error => console.log(error)
            )
        } else {
            setIsLoading(false);
        }
        
    } 
    const callUpdatewithPutApplicationCompanySettings = (row, callback = null) => {
        setIsLoading(true);

        if(Object.keys(row).length > 0) {
            Api.update('application_company_settings/' + row.id, row, accessToken)
            .then((result) => {
                setIsLoading(false);
            }).catch(
                error => console.log(error)
            )
        } else {
            setIsLoading(false);
        }
       
    }    
 
    const callEmailTemplateApi = useCallback(() => {
        setIsLoading(true);

        let emptyItems = [
            {
                isNew: true,
                name: 'In Progress',
                template: {
                    template_name: "Job-In-Progress",
                    template: "",
                    template_type: "",
                    available_parameters: "",
                    email_type: "",
                    email_subject: "",
                    dynamic_content: ""
                }
            },
            {
                isNew: true,
                name: 'Completed',
                template: {
                    template_name: "Job-Completed",
                    template: "",
                    template_type: "",
                    available_parameters: "",
                    email_type: "",
                    email_subject: "",
                    dynamic_content: ""
                }
            }
        ];

        Api.read('email_templates', null, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                if(data && data.result){
                    if(data.result && data.result.length > 0){
                        let items = [];

                        let inProgressItem = data.result.filter(x => x.template_name.trim().toLowerCase() == 'job-in-progress-tracking-en');
                        if(inProgressItem && inProgressItem.length > 0){
                            items.push({
                                name: 'In Progress',
                                template: inProgressItem[0]
                            });
                        }

                        let inProgressNoTrackingItem = data.result.filter(x =>  x.template_name.trim().toLowerCase() == 'job-in-progress-no-tracking-en');
                        if(inProgressNoTrackingItem && inProgressNoTrackingItem.length > 0){
                            items.push({
                                name: 'In Progress - no tracking',
                                template: inProgressNoTrackingItem[0]
                            });
                        }


                        let completedItem = data.result.filter(x => x.template_name.trim().toLowerCase() == 'job-failed-en');
                        if(completedItem && completedItem.length > 0){
                            items.push({
                                name: 'Failed',
                                template: completedItem[0]
                            });
                        }
                        
                        let failedItem = data.result.filter(x => x.template_name.trim().toLowerCase() == 'job-completed-en');
                        if(failedItem && failedItem.length > 0){
                            items.push({
                                name: 'Completed',
                                template: failedItem[0]
                            });
                        }

                        setEmailTemplates(items);
                        setIsLoading(false);
                    } else {
                        setEmailTemplates(emptyItems);
                        setIsLoading(false);
                    }
                } else {
                    setEmailTemplates(emptyItems);
                    setIsLoading(false);
                }
            }, (error, type) => {
                setIsLoading(false);
            });
        });
    },[])

    const callUpdateEmailTemplateApi = (state, params, callback = null) => {
        return new Promise(resolve => { 
            setIsLoading(true);
            
            if(params){
                Api.update('email_templates/' + params.id, params, accessToken)
                    .then((result) => {
                    apiUtil.parseResult(result, (data) => {
                        setIsLoading(false);
                        resolve({
                            status: true,
                            type: state,
                        });
                    }, (error, type) => {
                        setIsLoading(false);
                        resolve({
                            status: false,
                            type: state,
                        });
                    });
                });
            } else {
                setIsLoading(false);
                resolve({
                    status: false,
                    type: state,
                });
            }
        });
    }
    const callCreateEmailTemplateApi = (state, params, callback = null) => {
        return new Promise(resolve => { 
            setIsLoading(true);
            
            if(params){
                Api.create('email_templates', params, accessToken)
                    .then((result) => {
                    apiUtil.parseResult(result, (data) => {
                        setIsLoading(false);
                        resolve({
                            status: true,
                            type: state,
                        });
                    }, (error, type) => {
                        setIsLoading(false);
                        resolve({
                            status: false,
                            type: state,
                        });
                    });
                });
            } else {
                setIsLoading(false);
                resolve({
                    status: false,
                    type: state,
                });
            }
        });
    }

    const callSMSTemplateApi = useCallback(() => {
        setIsLoading(true);

        let emptyItems = [
            {
                isNew: true,
                name: 'In Progress',
                template: {
                    template_name: "Job-In-Progress",
                    template: "",
                    template_type: "",
                    available_parameters: "",
                    display_name: ""
                }
            },
            {
                isNew: true,
                name: 'Completed',
                template: {
                    template_name: "Job-Completed",
                    template: "",
                    template_type: "",
                    available_parameters: "",
                    display_name: ""
                }
            }
        ];

        ldsApi.read('sms_templates', null, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                if(data && data.length > 0){
                    // let items = [];

                    // let inProgressItem = data.result.filter(x => (x.template_name.trim().toLowerCase().includes("progress") && (x.email_subject.trim().toLowerCase().includes("progress") || x.email_type.trim().toLowerCase().includes("progress"))));
                    // if(inProgressItem && inProgressItem.length > 0){
                    //     items.push({
                    //         name: 'In Progress',
                    //         template: inProgressItem[0]
                    //     });
                    // }


                    // let completedItem = data.result.filter(x => (x.template_name.trim().toLowerCase().includes("completed") && (x.email_subject.trim().toLowerCase().includes("completed") || x.email_type.trim().toLowerCase().includes("completed"))));
                    // if(completedItem && completedItem.length > 0){
                    //     items.push({
                    //         name: 'Completed',
                    //         template: completedItem[0]
                    //     });
                    // }

                    let items = data.map((item, i) => {
                        let name = item.template_name.replaceAll('_', ' ').trim().toLowerCase();

                        return {
                            name: name.charAt(0).toUpperCase() + name.slice(1),
                            template: item
                        }
                    });

                    setSmsTemplates(items);
                    setIsLoading(false);
                } else {
                    setSmsTemplates(emptyItems);
                    setIsLoading(false);
                }
            }, (error, type) => {
                setIsLoading(false);
            });
        });
    },[])

    const callUpdateSMSTemplateApi = (state, params, callback = null) => {
        return new Promise(resolve => { 
            setIsLoading(true);
            
            if(params){
                ldsApi.update('sms_templates/' + params.id, params, accessToken)
                    .then((result) => {
                    apiUtil.parseResult(result, (data) => {
                        setIsLoading(false);
                        resolve({
                            status: true,
                            type: state,
                        });
                    }, (error, type) => {
                        setIsLoading(false);
                        resolve({
                            status: false,
                            type: state,
                        });
                    });
                });
            } else {
                setIsLoading(false);
                resolve({
                    status: false,
                    type: state,
                });
            }
        });
    }

    const callCreateSMSTemplateApi = (state, params, callback = null) => {
        return new Promise(resolve => { 
            setIsLoading(true);
            
            if(params){
                ldsApi.create('sms_templates', params, accessToken)
                    .then((result) => {
                    apiUtil.parseResult(result, (data) => {
                        setIsLoading(false);
                        resolve({
                            status: true,
                            type: state,
                        });
                    }, (error, type) => {
                        setIsLoading(false);
                        resolve({
                            status: false,
                            type: state,
                        });
                    });
                });
            } else {
                setIsLoading(false);
                resolve({
                    status: false,
                    type: state,
                });
            }
        });
    }

    useEffect(() => {
        callReadSettingsApi();
        callEmailTemplateApi();
        callSMSTemplateApi();
        return () => {
            
        }
    }, [callReadSettingsApi, callEmailTemplateApi, callSMSTemplateApi])

    const handleCountryChange = (value, item) => {

        let cloneCountryList =   countryList.map(object => ({ ...object }));
        let filterCountry = cloneCountryList.filter((oldCountry) => {
            if(value === oldCountry.value) {
                oldCountry.value = oldCountry.offset;
                oldCountry.label = "GMT "+oldCountry.offset + "("+ oldCountry.identifier+")";;
                return oldCountry;
            }
        });

        setTimeZoneList(filterCountry);
        setTimeZone(filterCountry[0]);
        setCountry(value);
        setCountryCode(item.code);
        setCountryIdentifier(item.identifier);

    }
    const CustomStickyPageHeader = () => {
     return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <IconButton 
                    onClick={() => {
                        history.push(pathName);
                    }}
                >
                    <CloseIcon />
                </IconButton>
          </Grid>
        </Box>}
        {(!isOnBoarding) && <Box clone>
          <Grid item xs={'auto'}>
                <Divider orientation={'vertical'} />
          </Grid>
        </Box>}
        <Box clone>
          <Grid item xs={true}>
            <CustomLabel
                className={'no-margin'}
                label={(!isOnBoarding) ? 'User Settings' : 'Welcome to LDS. Let\'s set up your preferences before we start'}
                weight={'bold'}
                size={'md'}
            />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={'auto'}>
            <CustomButton 
              type={'submit'}
              color={'secondary'}
              isLoading={isLoading}
            >
              <Box pl={2} pr={2}>{isOnBoarding ? 'Begin LDS' : 'Save Changes'}</Box>
            </CustomButton>
          </Grid>
        </Box>
      </Grid>
    </Box>
    }
    

    const settingsGeneral = () => {
        return <Box><Grid container p={1} >
                <CustomLabel
                    label={'General'}
                    weight={'bold'}
                    size={'md'}
                />
                <Box clone>    
                    <Grid item xs={12}>
                        <CustomSelectOption
                            label={'Country'}
                            value={country}
                            items={allCountry}

                            onChange={handleCountryChange}
                    
                        />
                        <CustomSelectOption
                            label={'Timezone'}
                            value={timeZone}
                            items={timeZoneList}
                            onChange={ (value) => {
                                setTimeZone(value)
                            }}
                        />
                    </Grid>
                </Box>
        
            </Grid>
            <Divider light/>
            <Grid container >
                <CustomLabel
                    label={'Twilio SMS'}
                    weight={'bold'}
                    size={'md'}
                />
                <Box clone>
                <Grid container >

                    <Grid item xs={10}>
                        <span>Enable Tiwilo</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch  
                        checked={twilioSmsEnabled}  
                        onChange={ () => 
                            setTwilioSmsEnabled(!twilioSmsEnabled)
                        }
                        label="Enable Twilio"/>
                    </Grid>
{/*                     
                        <CustomInput
                            label={'Enable Tiwilo'}
                            placeholder={'Enter Twilio Accont Key'}
                            className="input-label--horizontal"
                            value={twilioSmsEnabled}
                            onChange={ e => {
                                setTwilioSmsEnabled(e.target.value)
                                // setFirstNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        /> */}
                 
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'From Number'}
                            placeholder={'Enter From Number'}
                            className="input-label--horizontal"
                            value={fromNumber}
                            onChange={ e => {
                                setFromNumber(e.target.value)
                                // setFirstNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Account SSID'}
                            placeholder={'Enter Twilio Accont SSID'}
                            className="input-label--horizontal"
                            value={twilioSmsKey}
                            onChange={ e => {
                                setTwilioSmsKey(e.target.value)
                                // setFirstNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12}>
                        <CustomInput
                            label={'Auth Token'}
                            placeholder={'Enter Twilio Auth Token'}
                            className="input-label--horizontal"
                            value={twilioSmsSecret}
                            // error={lastNameValidated}
                            required={true}
                            onChange={e => {
                                setTwilioSmsSecret(e.target.value)
                                // setLastNameValidated((e.target.value !== '') ? false : true) 
                            }}
                        />
                    </Grid>
                </Box>
            </Grid>
        </Box>;
    }
    const formattingPreference = () => {
        return <Box><Grid container p={1} >
        <CustomLabel
            label={'Formatting Preference'}
            weight={'bold'}
            size={'md'}
        />
         <Box clone>
                <Grid container>
                    <Grid item xs={4}>
                        <Box mt={1}>Delivery Order Formatting</Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box mt={1}>{deliveryFormattingInitial} {deliveryNoSeparator} {(deliveryFormattingYear)? "2017": ""} {(deliveryFormattingMonth)? "JAN": ""}{(deliveryFormattingDay)? "01": ""}{deliveryNoSeparator}0001 </Box>
                    </Grid>
                    <Grid item xs={1}>
                       <CustomInput
                        className= "custom-inline"
                        placeholder={'DO'}
                        value={deliveryFormattingInitial}
                        style= {{width:"40px", padding: "2px"}}
                        width = {"50px"}
                        // error={lastNameValidated}
                        // required={true}
                        // autoFocus={true}
                        onChange={e => {
                            setDeliveryFormattingInitial(e.target.value)
                            // setLastNameValidated((e.target.value !== '') ? false : true) 
                        }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                    <div className ="custom-input-parent">
                       
                        <div className="custominputgroup custom-inline"
                            style={{border: "1px solid #777777a6", 
                            borderRight: "0",borderRadius: "10px 0 0 10px"}}>
                            <input id="option1" name="year"  
                            checked={deliveryFormattingYear}
                            onChange={() => setDeliveryFormattingYear(!deliveryFormattingYear)}
                            type="checkbox"/>
                            <label htmlFor="option1"
                            style={{borderRadius: "10px 0 0 10px", color: "#000"}}>Year</label>
                        </div>
                        <div className="custominputgroup custom-inline"
                            style={{border: "1px solid #777777a6"}}>
                            <input id="option3" name="month" 
                            checked= {deliveryFormattingMonth}
                             onChange={ () => 
                                setDeliveryFormattingMonth(!deliveryFormattingMonth)
                            } 
                            type="checkbox"/>
                            <label htmlFor="option3" style={{color: "#000"}}>Month</label>
                        </div> 
                        
                        <div className="custominputgroup custom-inline"
                            style={{border: "1px solid #777777a6",
                            borderLeft: "0", 
                            borderRadius: "0 10px 10px 0"}}>
                            <input id="option2" name="day"
                            checked={deliveryFormattingDay}
                            onChange={ () => 
                                setDeliveryFormattingDay(!deliveryFormattingDay)
                            } 
                            type="checkbox"/>
                            <label htmlFor="option2"
                            style={{borderRadius: "0 10px 10px 0", color: "#000"}}>Day</label>
                        </div>
                    </div>
                        
                    </Grid>
                </Grid>
            </Box>
            <Box clone>
                <Grid container>
                    <Grid item xs={4}>
                        <Box mt={1}>Delivery Order Number seperator</Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box mt={1}>{deliveryFormattingInitial} {deliveryNoSeparator} {(deliveryFormattingYear)? "2017": ""} {(deliveryFormattingMonth)? "JAN": ""}{(deliveryFormattingDay)? "01": ""}{deliveryNoSeparator}0001 </Box>
                    </Grid>
                 
                    <Grid item xs={4}>
                   
                    <div className ="custom-input-parent">
                       
                        <div className="custominputgroup custom-inline"
                            style={{border: "1px solid #777777a6", 
                            borderRight: "0",borderRadius: "10px 0 0 10px"}}>
                            <input id="option5" name="radio" type="radio"
                            checked={deliveryNoSeparatorDash}
                            onChange={ () => {
                                setDeliveryNoSeparator("-");
                                setDeliveryNoSeparatorDash(true);
                                setDeliveryNoSeparatorSlash(false);
                                setDeliveryNoSeparatorDot(false);
                                }
                            } 
                            />
                            <label htmlFor="option5"
                            style={{borderRadius: "10px 0 0 10px", color: "#000"}}>-</label>
                        </div>
                        <div className="custominputgroup custom-inline"
                            style={{border: "1px solid #777777a6"}}>
                            <input id="option6" name="radio" type="radio"
                            checked={deliveryNoSeparatorSlash}
                            onChange={ () => {
                                setDeliveryNoSeparator("/")
                                setDeliveryNoSeparatorDash(false);
                                setDeliveryNoSeparatorSlash(true);
                                setDeliveryNoSeparatorDot(false);
                            }
                            } 
                            />
                            <label htmlFor="option6" style={{color: "#000"}}>/</label>
                        </div>
                        <div className="custominputgroup custom-inline"
                            style={{border: "1px solid #777777a6",
                            borderLeft: "0", 
                            borderRadius: "0 10px 10px 0"}}>
                            <input id="option7" name="radio" type="radio"
                            checked={deliveryNoSeparatorDot}
                             onChange={ () => {
                                setDeliveryNoSeparator(".")
                                setDeliveryNoSeparatorDash(false);
                                setDeliveryNoSeparatorSlash(false);
                                setDeliveryNoSeparatorDot(true); 
                            }
                            } 
                            />
                            <label htmlFor="option7"
                            style={{borderRadius: "0 10px 10px 0", color: "#000"}}>.</label>
                        </div>
                    </div>
                        
                    </Grid>
                </Grid>
            </Box>
             
   
        </Grid>
        <Divider light/>
        <Grid container >
            <CustomLabel
                label={'Scale Preference'}
                weight={'bold'}
                size={'md'}
            />
            <Box clone>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <span>Length</span>
                </Grid>
                <Grid item xs={4}>
                    <span>100 {scalePrefLength}</span>
                </Grid>
                <Grid item xs={4}>
                    <CustomSelectOption
                        value={scalePrefLength}
                        items={[{ id : 1877, label: "in", value: "Inches (in)"}, { id : 1898, label: "m", value: "Metre (m)"}, { id : 1887, label: "cm", value: "centimetre (cm)"}]}
                        onChange={ (value, item) => {
                            setScalePrefLength(value)
                        }}
                    />
                    </Grid>
                    </Grid>
            </Box>
            <Box clone>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <span>Volume</span>
                </Grid>
                <Grid item xs={4}>
                    <span>100 {scalePrefVol}</span>
                </Grid>
                <Grid item xs={4}>
                    <CustomSelectOption
                        // label={'Length'}
                        value={scalePrefVol}
                        items={[{ id : 1877, label: "m3", value: "cubic metre (m3)"}, { id : 1898, label: "cm³", value: "cubic centimeter (cm³)"}, { id : 1887, label: "in³", value: "cubic inch (in³)"}]}
                        onChange={ (value, item) => {
                            setScalePrefVol(value)
                        }}
                    />
                    </Grid>
                    </Grid>
            
            </Box>
            <Box clone>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <span>Distance</span>
                    </Grid>
                    <Grid item xs={4}>
                        <span>100 {scalePrefDistance}</span>
                    </Grid>
                    <Grid item xs={4}>
                    
                        <RadioGroup aria-label="distance" name="distance" style={{display: 'block'}} value={scalePrefDistance} 
                        onChange={ (event) => {
                            setScalePrefDistance(event.target.value)
                        }}
                        >
                            <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="kilometre (km)" control={<Radio />} label="km" />
                            <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="Mile (mi)" control={<Radio />} label="mi" />
                        
                        </RadioGroup>
                        
                    </Grid>
                </Grid>
            </Box>
            <Box clone>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <span>Speed</span>
                    </Grid>
                    <Grid item xs={4}>
                        <span>100 {scalePrefSpeed}</span>
                    </Grid>
                    <Grid item xs={4}>
                    
                        <RadioGroup aria-label="speed" name="speed" style={{display: 'block'}} value={scalePrefSpeed} 
                        onChange={ (event) => {
                            setScalePrefSpeed(event.target.value)
                        }}
                        >
                            <FormControlLabel style={{ display: 'inline-block',  width: "99px" }}  value="kilometre per hour (kph)" control={<Radio />} label="kph" />
                            <FormControlLabel style={{ display: 'inline-block',  width: "99px"}}  value="Miles per hour (mph)" control={<Radio />} label="mph" />
                        
                        </RadioGroup>
                        
                    </Grid>
                </Grid>
            </Box>
            <Box clone>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <span>Weight</span>
                    </Grid>
                    <Grid item xs={4}>
                        <span>100 {scalePrefWeight}</span>
                    </Grid>
                    <Grid item xs={4}>
                    
                        <RadioGroup aria-label="weight" name="weight" style={{display: 'block'}} value={scalePrefWeight} 
                        onChange={ (event) => {
                            setScalePrefWeight(event.target.value)
                        }}
                        >
                            <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="kilogram (kg)" control={<Radio />} label="kg" />
                            <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="pound (lb)" control={<Radio />} label="lb" />
                        
                        </RadioGroup>
                        
                    </Grid>
                </Grid>
            </Box>
            <Box clone>
            <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <span>DateFormat</span>
                    </Grid>
                    <Grid item xs={4}>
                        <span>{moment().format(scalePrefDateFormat?scalePrefDateFormat.replace("dd", "DD"): "")}</span>
                    </Grid>
                    <Grid item xs={4}>
                    

                    <CustomSelectOption
                        className=""
                        style={{opacity: "unset"}}
                        value={scalePrefDateFormat}
                        items={[{ id: 1866, label:"yyyy-MM-DD", exampleValue: "yyyy-MM-DD", value: "yyyy-MM-dd" }, { id: 1867, label:"DD/MM/yyyy", exampleValue: "DD/MM/yyyy", value: "dd/MM/yyyy" }, { id: 1867, label:"MM/DD/yyyy",  exampleValue: "MM/DD/yyyy",value: "MM/dd/yyyy" }, { id: 1867, label:"DD MMMM yyyy", exampleValue: "DD MMMM yyyy", value: "dd MMMM yyyy" }]}
                        // error={lastNameValidated}
                        // required={true}
                        // autoFocus={true}
                        onChange={ (value, item) => {
                            setScalePrefDateFormat(value)
                            setScalePrefDateExampleFormat(value)
                        }}
                    />
                </Grid>
            </Grid>
            </Box>
            <Box clone>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <span>Time Format</span>
                    </Grid>
                    <Grid item xs={4}>
                        <span>11:19 {(scalePrefTimeFormat === "12-Hour" ? "AM" : "") }</span>
                    </Grid>
                    <Grid item xs={4}>
                    
                        <RadioGroup aria-label="weight" name="weight" style={{display: 'block'}} value={scalePrefTimeFormat} 
                        onChange={ (event) => {
                            setScalePrefTimeFormat(event.target.value)
                        }}
                        >
                            <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="12-Hour" control={<Radio />} label="12-Hour" />
                            <FormControlLabel style={{ display: 'inline-block', width: "99px" }}  value="24-Hour" control={<Radio />} label="24-Hour" />
                        
                        </RadioGroup>
                        
                    </Grid>
                </Grid>
            </Box>
           
        </Grid>
    </Box>
    }
    const optionPreference = () => {
        return <Box><Grid container p={1} >
        <CustomLabel
            label={'Option'}
            weight={'bold'}
            size={'md'}
        />
        <Box clone style={{marginBottom:"20px"}}>
            <Grid item xs={12}>
                <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Enable Customers</span>
                    <Grid item><span style={{color: "#b3b3b3",fontSize: "13px"}}>This allows you to keep a list of companies you associated with and use their details when you add a job</span></Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch  
                        checked={enableCustomer}  
                        onChange={ () => 
                            setEnableCustomer(!enableCustomer)
                        }
                        label="Enable Customer"/>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        <Divider light/>

        <Box clone style={{marginBottom:"40px"}}>
            <Grid item xs={12}>
                <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Enable manpower</span>
                    <Grid item><span style={{color: "#b3b3b3",fontSize: "13px"}}>Enable this option if you have assistance following your drivers. </span></Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch
                        checked={enableManPower}  
                        onChange={ () => 
                            setEnableManPower(!enableManPower)
                        }
                        label="Enable Manpower"/>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        </Grid>
        <Divider light/>
        <Box clone style={{marginTop:"40px"}}>
            <Grid item xs={12}>
                <span>Manpower job step status change (from mobile app) </span>
                <span>We noticed that you required manpower to assist the driver. Would you want to grant them the privilege to update the job status like the driver does ? </span>
            </Grid>
        </Box>
        <Box clone style={{marginTop:"40px"}}>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Acknowledged</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableManPowerAcknowledged}  
                         onChange={ (event) => {
                             setEnableManPowerAcknowledged(!enableManPowerAcknowledged)
                         }}
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>In Progress</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableManPowerInProgress}  
                         onChange={ () => 
                             setEnableManPowerInProgress(!enableManPowerInProgress)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Completed</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableManPowerCompleted}  
                         onChange={ () => 
                             setEnableManPowerCompleted(!enableManPowerCompleted)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
        <Divider light/>
        <Box clone>
            <Grid component="label" container alignItems="center" spacing={3}>
                    <Grid item xs={10}><span style={{fontSize: "1rem", fontWeight: 400,lineHeight: 1.5,letterSpacing: "0.00938em",color: "#101820"}}>Failed</span>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomSwitch 
                         checked={enableManPowerFailed}  
                         onChange={ () => 
                             setEnableManPowerFailed(!enableManPowerFailed)
                         }
                         />
                    </Grid>
            </Grid>
        </Box>
    </Box>
    }
    const emailTemplate = () => {
        return <Box>
            <Grid container>
                <Box clone>
                    <Grid item xs={12}>
                        {
                        (emailTemplates && emailTemplates.length > 0)
                        ?
                        emailTemplates.map((item, i) => {
                            return <Box key={i} pb={4}>
                                <Grid container>
                                    <Box clone pb={1}>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={item.name}
                                                weight={'bold'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                                label={'Subject'}
                                                placeholder={'Subject'}
                                                value={item.template.email_subject}
                                                onChange={ e => {
                                                    item.template.email_subject = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            {/* <CustomInput
                                                label={'Content'}
                                                placeholder={'Content'}
                                                multiline={true}
                                                rows={10}
                                                value={item.template.dynamic_content}
                                                onChange={ e => {
                                                    item.template.dynamic_content = e.target.value;
                                                }}
                                            /> */}
                                            
                                            <RSunEditor
                                                defaultValue={item.template.dynamic_content}
                                                value={item.template.dynamic_content}
                                                placeholder={"Content"}
                                                onChange={(value) => {
                                                    item.template.dynamic_content = value;
                                                }}
                                            />

                                            {/* <RTextEditor
                                                editorState={item.template.dynamic_content}
                                                placeholder={"Content"}
                                                onChange={(value) => {
                                                    item.template.dynamic_content = value;
                                                }}
                                            /> */}
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={'Available Parameters'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={item.template.available_parameters}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        })
                        :
                        <>No Email Templates!</>
                        }
                    </Grid>
                </Box>
            </Grid>
        </Box>
    }
    const smsTemplate = () => {
        return <Box>
            <Grid container>
                <Box clone>
                    <Grid item xs={12}>
                        {
                        (smsTemplates && smsTemplates.length > 0)
                        ?
                        smsTemplates.map((item, i) => {
                            return <Box key={i} pb={4}>
                                <Grid container>
                                    <Box clone pb={1}>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={item.name}
                                                weight={'bold'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    {/* <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                                label={'Subject'}
                                                placeholder={'Subject'}
                                                value={item.template.display_name}
                                                onChange={ e => {
                                                    item.template.display_name = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Box> */}
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomInput
                                                label={'Content'}
                                                placeholder={'Content'}
                                                multiline={true}
                                                rows={10}
                                                value={item.template.template}
                                                onChange={ e => {
                                                    item.template.template = e.target.value;
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={'Available Parameters'}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            <CustomLabel
                                                label={item.template.available_parameters}
                                                size={'md'}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        })
                        :
                        <>No SMS Templates!</>
                        }
                    </Grid>
                </Box>
            </Grid>
        </Box>
    }


    const redirectIsOnboarding = () => {
        if(isOnBoarding){
            apiUtil.toastOnBoarding('Successfully Updated', 'check_circle');
            
            setTimeout(() => {
                history.push('/onboarding');
            }, 500);
        } else {
            apiUtil.toast('Successfully Updated', 'check_circle');
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        
        if(value === 3){ // Save Email Template - emailTemplates
            const promises = [];

            if(emailTemplates && emailTemplates.length > 0){
                emailTemplates.forEach((item, i) => {
                    if(item && item.template){
                        if(item.isNew === true){
                            promises.push(callCreateEmailTemplateApi(i, item.template));
                        } else {
                            promises.push(callUpdateEmailTemplateApi(i, item.template));
                        }
                    }
                });
            }

            if(promises && promises.length > 0){
                Promise.all(promises).then((results) => {
                    if(results && results.length > 0){
                        let typeInProgress = results.filter(x => x.type === 0);
                        if(typeInProgress && typeInProgress.length > 0){
                            let status = typeInProgress[0].status;

                            if(status){
                                apiUtil.toast('Successfully updated In Progress Email template', 'check_circle');
                            } else {
                                apiUtil.toast('An error occurred while updating the In Progress Email template', 'check_circle', 'error');
                            }
                        }

                        let typeCompleted = results.filter(x => x.type === 1);
                        if(typeCompleted && typeCompleted.length > 0){
                            let status = typeCompleted[0].status;

                            if(status){
                                apiUtil.toast('Successfully updated Completed Email template', 'check_circle');
                            } else {
                                apiUtil.toast('An error occurred while updating the Completed Email template', 'check_circle', 'error');
                            }
                        }

                        redirectIsOnboarding();
                    } else {
                        apiUtil.toast('An error has occurred', 'check_circle', 'error');
                    }
                }).catch((e) => {
                    apiUtil.toast('An error has occurred', 'check_circle', 'error');
                });
            }
        } else if(value === 4){ // Save SMS Template
            const promises = [];

            if(smsTemplates && smsTemplates.length > 0){
                smsTemplates.forEach((item, i) => {
                    if(item && item.template){
                        if(item.isNew === true){
                            promises.push(callCreateSMSTemplateApi(i, item.template));
                        } else {
                            promises.push(callUpdateSMSTemplateApi(i, item.template));
                        }
                    }
                });
            }

            if(promises && promises.length > 0){
                Promise.all(promises).then((results) => {
                    if(results && results.length > 0){
                        // let typeInProgress = results.filter(x => x.type === 0);
                        // if(typeInProgress && typeInProgress.length > 0){
                        //     let status = typeInProgress[0].status;

                        //     if(status){
                        //         apiUtil.toast('Successfully updated In Progress SMS template', 'check_circle');
                        //     } else {
                        //         apiUtil.toast('An error occurred while updating the In Progress SMS template', 'check_circle', 'error');
                        //     }
                        // }

                        // let typeCompleted = results.filter(x => x.type === 1);
                        // if(typeCompleted && typeCompleted.length > 0){
                        //     let status = typeCompleted[0].status;

                        //     if(status){
                        //         apiUtil.toast('Successfully updated Completed SMS template', 'check_circle');
                        //     } else {
                        //         apiUtil.toast('An error occurred while updating the Completed SMS template', 'check_circle', 'error');
                        //     }
                        // }

                        results.forEach((item, i) => {
                            if(item && item.status){
                                apiUtil.toast('Successfully updated SMS template', 'check_circle');
                            } else {
                                apiUtil.toast('An error occurred while updating the SMS template', 'check_circle', 'error');
                            }
                        });
                    } else {
                        apiUtil.toast('An error has occurred', 'check_circle', 'error');
                    }
                }).catch((e) => {
                    apiUtil.toast('An error has occurred', 'check_circle', 'error');
                });
            }
        } else {
            var data = [];

            currentApplicationData.map((res)=>{
                switch(res.rule)
                {
                    case "manpower_label":
                            data.push({
                                id: res.id?res.id:1042,
                                view_name: manPowerLabel
                            });
                        break;
                    case "driver_label":
                            data.push({
                                id: res.id?res.id:1041,
                                view_name: driverLabel
                            });
                        break;
                    case "sms_credentials":
                    case "twilio_sms_credentials":
                        data.push({
                            id: res.id,
                            enable: twilioSmsEnabled,
                            json_data: JSON.stringify({ sms_provider: 'twilio', account_key : twilioSmsKey, from_number : fromNumber, account_secret: twilioSmsSecret })
                        });
                    break;
                }

            });

            if (data.length == 0){
                data = [{
                            id: 1042,
                            view_name: manPowerLabel
                        }, {
                            id: 1041,
                            view_name: driverLabel
                        }, {
                            id: 3800,
                            enable: twilioSmsEnabled,
                            json_data: JSON.stringify({ sms_provider: 'twilio', account_key : twilioSmsKey, from_number : fromNumber, account_secret: twilioSmsSecret })
                        }];
            }
            
            let increment_by = deliveryFormattingDay? "increment_by_day": deliveryFormattingMonth ? "increment_by_month": "increment_by_year";
            let format = "";
            format = format.concat(deliveryFormattingYear? "%Y": "", deliveryFormattingMonth? "%b": "", deliveryFormattingDay? "%d": "")  ;//"OOo-[[%Y%b%d]]-[[increment_by_day]]"
            let rule_format = deliveryFormattingInitial + deliveryNoSeparator + "[["+format+"]]" + deliveryNoSeparator + "[["+ increment_by +"]]";
            let available_parameters = format +","+ increment_by; 
            // const deliveryOrderFormattingData = {"id":1048,"rule":"do_number_format","enable":true,"description":"%Y - eg: 2017, %b - eg: Jan, %B - eg: January, %d - eg: 31, 01","application_company_id":117,"group_name":"Delivery Order","current_status_general_id":null,"to_status_general_id":null,"is_man_power_worker":null,"is_scan_required":null,"view_name":"Reference/DO Number Format","available_parameters":available_parameters,"rule_format": rule_format,"current_step_status_id":null,"to_step_status_id":null,"job_step_type_id":null,"json_data":null};
            let fieldNames = ["Delivery Order"];
            let deliveryOrderFormattingData= {};
            let getRequireParameters = currentApplicationData.map((data) => {
                fieldNames.forEach((fieldName) => {
                    if(data.group_name === fieldName && rule_format !== data.rule_format) {
                        Object.assign(deliveryOrderFormattingData,{
                            "application_company_id": data.application_company_id,
                            "rule_format": rule_format,
                            "available_parameters": available_parameters,
                            "group_name": fieldName,
                            "id": data.id   
                        });  

                    }
                })
        
            })
            callUpdatewithPutApplicationCompanySettings(deliveryOrderFormattingData, () => {
                
                apiUtil.toast('Successfully Updated Formatting Preference', 'check_circle');
            })
        

            let scaleFieldNames = ["Volume", "Length", "Weight", "Distance", "Speed", "Date Format", "Time Format"];
            let measurementData= [];

            const getMeasurementFormat = (data) => {
                measurementData.push({
                    "application_company_id": data.application_company_id,
                    "id": data.id,
                    "is_active": true, 
                    "module": "all"
                });
            }

            let getRequireParametersForMeasurement = currentMeasurementData.map((data) => {
                scaleFieldNames.forEach((option) => {
                    if(data.measurement_type === option) {
                        switch (option) {
                            case "Volume":
                                if(data.measurement === scalePrefVol && preScalePrefVol !== scalePrefVol) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Length":
                                if(data.measurement === scalePrefLength && preScalePrefLength !== scalePrefLength) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Weight":
                                if(data.measurement === scalePrefWeight && preScalePrefWeight !== scalePrefWeight) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Distance":
                                if(data.measurement === scalePrefDistance && preScalePrefDistance !== scalePrefDistance) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Speed":
                                if(data.measurement === scalePrefSpeed && preScalePrefSpeed !== scalePrefSpeed) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Date Format":
                                if(data.measurement === scalePrefDateFormat && preScalePrefDateFormat !==scalePrefDateFormat) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            case "Time Format":
                                if(data.measurement === scalePrefTimeFormat && preScalePrefTimeFormat !==scalePrefTimeFormat) {
                                    getMeasurementFormat(data);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                })
            })

            let measurementObj = {measurements_data:measurementData};
            callUpdateMeasurements( measurementObj, () => {
                // history.push('/resources/transport-operator');
                apiUtil.toast('Successfully Updated', 'check_circle');
            });
            let options = ["Customer", "mobile", "Country"];
            let isEnable;
            let optionsData= [];
            const getOptionDataFormat = (data, isEnable) => {
                optionsData.push({
                "application_company_id": data.application_company_id,
                "enable": isEnable,
                "group_name": data.group_name,
                "id": data.id  
                })
            }

            let getRequireParametersForOption = currentApplicationData.map((data) => {
                options.forEach((option) => {
                    if(data.group_name === option) {
                        switch (option) {
                            case "Customer":
                                getOptionDataFormat(data, enableCustomer);
                                break; 
                            case "Country":
                                let jsonParse = data && data.json_data ? JSON.parse(data.json_data) : null;
                                let oldCountry = jsonParse !== null ? jsonParse.name : '';
                                if(country !== oldCountry) {
                                    let newJson = {name: country, code: countryCode, identifier: countryIdentifier, offset: timeZone }
                                    optionsData.push({
                                        "application_company_id": data.application_company_id,
                                        "enable": isEnable,
                                        "group_name": data.group_name,
                                        "id": data.id ,
                                        "json_data": JSON.stringify(newJson),  
                                        })
                                }
                                break;
                            case "mobile":
                                if(data.description === "Manpower can update this status") {
                                    if(enableManPower !== data.enable) {
                                        getOptionDataFormat(data, enableManPower);
                                    }
                                } else if(data.view_name === "Acknowledged" && data.enable !== enableManPowerAcknowledged) {
                                    getOptionDataFormat(data, enableManPowerAcknowledged);
                                } else if(data.view_name === "Completed" && data.enable !== enableManPowerCompleted) {
                                    getOptionDataFormat(data, enableManPowerCompleted);
                                } else if (data.view_name === "In Progress" && data.enable !== enableManPowerInProgress) {
                                    getOptionDataFormat(data, enableManPowerInProgress);
                                } else if (data.view_name === "Failed" && data.enable !== enableManPowerFailed) {
                                    getOptionDataFormat(data, enableManPowerFailed);
                                }
                                break;
                            default:
                                break;
                        }
                        
                    }
                })
        
            })
            let mergeData = optionsData.concat(data);
            let optionDataFormat = {application_settings_data : mergeData};
            callUpdateApi( optionDataFormat, () => {
                // history.push('/resources/transport-operator');
                redirectIsOnboarding();
            })
        }
    }
    return (
    <Box className="settings--form" >
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <StickyPageHeader isSmall={true}>
                <CustomStickyPageHeader />
            </StickyPageHeader>
            <Container maxWidth="lg">
                <Grid container>

                    <Grid item xs={3}>
                        <Box clone marginTop={3}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Settinsg Menus"
                        >
                            <Tab label="General Preference" {...a11yProps(0)} />
                            <Tab label="Formatting Preference" {...a11yProps(1)} />
                            <Tab label="Options" {...a11yProps(2)} />
                            <Tab label="Email Template" {...a11yProps(3)} />
                            <Tab label="SMS Template" {...a11yProps(3)} />
                        </Tabs>
                        </Box>
                    </Grid>

                    <Grid item xs={9}>
                        <Card>
                        
                        <TabPanel value={value} index={0}>
                           {settingsGeneral()}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                           {formattingPreference()}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {optionPreference()}
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            {emailTemplate()}
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            {smsTemplate()}
                        </TabPanel>
                        </Card>
                    </Grid>

                </Grid>

            </Container>
        </form>
    </Box>
    )
}

